import { config } from "./randomised-runner-config";

export const PLAYER_STATES = {
    RUNNING: 'running',
    JUMPING: 'jumping',
    FALLING: 'falling',
    HIT: 'hit',
}

export class GameModel {
    constructor(currentWorld = 1) {
        this.minDifficulty = config.START_MIN_DIFFICULTY;
        this.maxDifficulty = config.START_MAX_DIFFICULTY;
        this.currentWorld = currentWorld;
        this.nextDifficultyIncreaseDistance = config.DIFFICULTY_INCREASE_DISTANCE;
        
        this.itemsCollected = 0;
        this.boostProgress = 0;
        this.boostProgressDisplay = 0;
        this.maxWorldXReached = 0;
        this.worldX = 0;
        this.playerX = 0;
        this.playerY = 0;
        this.playerMaxVelocityX = config.RUNNER.startRunSpeed;
        this.playerVelocityX = 0;
        this.playerVelocityY = 0;
        this.doubleJumped = false;
        this.isBumped = false;
        this.hasReachedEnd = false;

        this.playerState = PLAYER_STATES.RUNNING;

        this.bonusCountdown = 0;
    }
}
