import PropTypes from 'prop-types';
import React from 'react'
import ReactFullScreenViewUnderCanvas from '../koko-framework/ui/react-views/ReactFullScreenViewUnderCanvas';
import "./VideoFeature.scss";

import { TwitchContext, TwitchProvider} from '../contexts/TwitchContext';
import { GAME_CONTROLLER } from '../randomised-runner/controller/randomised-runner-game-controller';
import {isMobile} from 'react-device-detect';
import { config } from '../randomised-runner/model/randomised-runner-config';
import { au } from '../koko-framework/shorthand';

const youTubeMain = {
  player: null,
}
export default class VideoFeatureView extends ReactFullScreenViewUnderCanvas {
    static contextType = TwitchContext
    static propTypes = {
        id: PropTypes.string.isRequired,
      };
      constructor(props) {
        super(props);
        this.state = {clipPlaying:false, endClipPlaying:false, shrinkVideo:false};
      }
    tag = document.createElement('script');
    firstScriptTag = document.getElementsByTagName('script')[0];
    // make sure you call the superclass in didMount and willUnmount
    mount = false;
    componentDidMount() {
        // I would recommend doing your stuff before calling super.componentDidMount,
        // as this may cause a callback...
      if(!this.mount)
      {
      console.log('MOUNT VIDEO')
        this.mount = true
        let firstScriptTag = document.getElementsByTagName('script')[0];
        if (!window.Twitch) { // If not, load the script asynchronously
          const tag1 = document.createElement('script');
          tag1.src = 'https://embed.twitch.tv/embed/v1.js';
    
          // onYouTubeIframeAPIReady will load the video after the script is loaded
    
  
          firstScriptTag.parentNode.insertBefore(tag1, firstScriptTag);
          
        } 

        if (!config.USE_LOCAL_VIDEO) {
          if (!window.YT) { // If not, load the script asynchronously
              const tag = document.createElement('script');
              tag.src = 'https://www.youtube.com/iframe_api';
        
              // onYouTubeIframeAPIReady will load the video after the script is loaded
              window.onYouTubeIframeAPIReady = this.loadVideo;
        
              firstScriptTag = document.getElementsByTagName('script')[0];
              firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        
            } else { // If script is already there, load the video directly
              this.loadVideo();
            }
        }
        super.componentDidMount();
      }
    }

    componentWillUnmount() {
        // the super version of this removes a resize listener so, make sure you call this!
        super.componentWillUnmount();
    }

    getVidDuration() {
        const vid = this.player || youTubeMain.player;
        if (vid && vid.getDuration) {
          // console.log(vid.getDuration())
          return vid.getDuration();
        }
        return 0;
    }

    getVidSeekTime() {
        const vid = this.player || youTubeMain.player; // document.getElementById("featureVideo");
        if (vid ) {
          // console.log(vid);
          // console.log(vid.getCurrentTime())
          if(vid.playerInfo.currentTime>3 && this.state.clipPlaying) 
          {
            this.setState({shrinkVideo:true}) 
          }
          if(vid.playerInfo.currentTime>7 && this.state.clipPlaying) 
          {
            this.setState({clipPlaying:false})
          }

          if(vid.playerInfo.currentTime >156 && !this.state.endClipPlaying)
          {
            this.setState({endClipPlaying:true, shrinkVideo:false})

          }
          if(vid.playerInfo.currentTime >159 && this.state.endClipPlaying)
          {
            this.setState({shrinkVideo:true}) 

          }
          return vid.playerInfo.currentTime; // vid.currentTime;
        }
        // console.log('no vid player (this.player) found');
        return 0;
    }

    stopTwitchClip = () => {
  
    }

    vidEnded = () => {
        GAME_CONTROLLER.gameComplete();
    }
    loadTwitchClip = () => {
      this.twitchPlayer = new window.Twitch.Embed("twitch-clip", {
        width: 1920,
        height: 1080,
        clip: "CooperativeThirstyLocustSoonerLater-9a7p62zwqqAPmE3f&parent=localhost",
        layout: "video",
        autoplay: false
      });

      this.twitchPlayer.addEventListener(window.Twitch.Embed.VIDEO_READY, () => {
        //var player = this.twitchPlayer.getPlayer();
       // player.play();
      });
    }


    loadVideo = () => {
        const { id } = this.props;
    
        // the Player object is created uniquely based on the id in props
        this.player = new window.YT.Player(`youtube-player-${id}`, {
          videoId: localStorage.getItem('videoID'),
          events: {
            onReady: this.onPlayerReady,
            onStateChange: this.onPlayerStateChange
          },
          playerVars: {
            loop:0,
            playsInline:1,
            mute:isMobile || au.mute
          }
        });

        if (this.player) {
          youTubeMain.player = this.player;
        }

        console.log('player', this.player, youTubeMain);
      };
    
      onPlayerStateChange = event => {
        if(event.data == 0) {
            this.vidEnded();
        } 
        if(event.data ==1) {
         // console.log('VID PLAYING', this.player, youTubeMain.player)
          const vid1 = this.player || youTubeMain.player
          
          if(vid1.playerInfo.currentTime < 1) this.setState({clipPlaying:true})
      } 
      }

      onPlayerReady = event => {
        //this.loadTwitchClip();
     
        event.target.playVideo();
      };





//<iframe frameborder="0" src="https://www.youtube.com/embed/pwuCabmvYEY?si=iqoxuyW0cOs112Xj?&controls=0&&showinfo=0&autoplay=1&playsinline=1" ></iframe>
    
/*
<iframe
    src="https://clips.twitch.tv/embed?clip=CooperativeThirstyLocustSoonerLater-9a7p62zwqqAPmE3f&parent=localhost&autoplay=true&mute=0"
    height="1920px"
    width="1080px"
    autoplay='true'
    allowfullscreen>
</iframe>
*/

    /* There a alot of spacer empty growing row divs here! */
    renderContent() {
        const { id } = this.props;
        const isMuted = isMobile ? '{ "muted": true }' : '';
        return (
  
        
            <div className={"video-container"}>
                {!config.USE_LOCAL_VIDEO && 
                  <div id={`youtube-player-${id}`} className={'video'} />
                }
                {config.USE_LOCAL_VIDEO &&
                  <video src={localStorage.getItem('twitchClip1')} autoplay='true' data-setup={isMuted} playsInline='true' />
                }
    
                <div className={"colourOverlay"}>
                    {/* <img src={this.context.state.twitchData.profilePic} /> {this.context.state.twitchData.username} */}
                </div>
                <div className={'clips-container'+(this.state.shrinkVideo ? ' shrink-video' :'')}>
                
                {(this.state.clipPlaying ) && 
                <video src={localStorage.getItem('twitchClip1')} autoPlay={true} muted={true} data-setup={isMuted} playsInline={true}
                id="twitch_clip"></video>
                }
                 {this.state.endClipPlaying && 
                <video src={localStorage.getItem('twitchClip2')} autoPlay={true} muted={true}  data-setup={isMuted} playsInline={true}
                id="twitch_clip1"></video>
                }

                 </div>
         
            </div>
        );
    }
}