// import main from "../main";
// import {isMobile} from 'react-device-detect';

export const DEBUG = true;
export const FRAME_DELTA_LIMIT = 3;
export const FRAME_TIME = 16.666; // milliseconds

export const BACKGROUND_STATE = {
    LOGIN:'login',
    LOADING:'loading',
    MAIN_MENU:'main_menu',
    GAME:'game',
}

export const LEADERBOARD_SETTINGS = {
    REQUEST_SCORE_HISTROY:false,
    REQUEST_SCORE_POSITION:true,
    LEADERBOARD_TIMEFRAME:86400,
    NUMBER_OF_SCORES:50
}

export const LANGUAGE_SETTINGS = {
    lang:'en'
}
export const LANGUAGE_CHANGED_EVENT = "langChanged";
export const verifyLang = (lang) => {
    switch(lang)
    {
        case 'en':
        case 'fr':
        case 'de':
        case 'it':
        case 'mx':
        case 'es':
        case 'br':
        case 'jp':
        case 'tw':
        case 'au':
        case 'nz':
            LANGUAGE_SETTINGS.lang = lang;
        break;
        default:
            LANGUAGE_SETTINGS.lang = 'en';
    }
    console.log('LANG SET', LANGUAGE_SETTINGS.lang);

    /* if (isMobile) {
        main.loadLocalisedVidAudio(LANGUAGE_SETTINGS.lang);
    } */
}