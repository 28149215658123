/**
 * React View Controller class
 * @author Carl Trelfa
 * 
 * This system allows us to transition menu screens to be html based, which is better for accessability reasons.
 * 
 * Usage:
 * 
 * You can see example usage in Bootstap.js, but here is a basic over-view
 * 
 * In you top-level react view, you need to create an instance of ReactViewController,
 * in this case we are using an exported const called REACT_VIEW_CONTROLLER for easy access
 * throughout the project, meaning we can easily hide and show react views from anywhere.
 * 
 * Call REACT_VIEW_CONTROLLER.init([TopLevelViewInstance]);
 * Add view ids with addViewId([String]);
 * 
 * Your top level view will gain a state var [viewid]_active (for example we have an Id called "basicview" and
 * our new state var is called "basicview_active"). You don't need to add these to your state, 
 * the ReactViewController will handle that.
 * 
 * Add the jsx for you view to your top-level view's render function, with {this.state.[viewid]_active && <jsx>} and your views
 * will now be added and removed as needed.
 * 
 * The views you hide and show with this Controller should extend ReactFullScreenView, your views will automatically
 * transition in / out and scale to fit the underlying pixi canvas.
 * 
 * When creating a view using this class, put your render code in the function called "renderContent", NOT "render"!
 * This will handle the scaling and fading in and out of the view. Also bear in mind that we are utilising 
 * componentDidMount and componentWillUnmount, so if you over-ride these you will need to call the super.component... in there.
 * 
 * Take look at the example BasicReactView to see how this is done. I'm using flex for this so I can grow / shrink parts
 * automatically, but you could use absolute positioning if you wanted.
 * 
 * Caveat: Extending ReactFullScreenView will block all interactions with the pixi canvas, so use it for menus screens,
 * could be handy for huds as well, but probably not by extending that class.
 */
export default class ReactViewController {
    rootView = null;
    views = null;

    init(rootView) {
        this.rootView = rootView;
        this.views = {};
    }

    // mount callback is useful for positionable views, as you need to call showView before you can position it,
    // so add yourself a callback here!
    addViewId(viewId, mountCallback = null) {
        if (this.views) {
            let viewState = {
                mounted: false,
                viewInstance: null,
                mountCallback: mountCallback,
            }
            this.views[viewId] = viewState;
        }
    }

    addMountCallback(viewId, mountCallback) {
        if (this.views && this.views[viewId]) {
            this.views[viewId].mountCallback = mountCallback;
        }
    }

    showView(viewId) {
        if (this.rootView && this.views[viewId]) {
            let stateUpdate = {};
            stateUpdate[viewId + '_active'] = true;
            this.rootView.setState(stateUpdate);
        }
    }

    hideView(viewId) {
        if (this.rootView && this.views[viewId] && this.views[viewId].mounted && this.views[viewId].viewInstance) {
            this.views[viewId].viewInstance.transitionOut();
        }
    }

    areViewsActive(viewsArray) {
        for (let i = 0; i < viewsArray.length; i++) {
            if (this.isViewActive(viewsArray[i])) {
                return true;
            }
        }
        return false;
    }

    isViewActive(viewId) {
        if (this.views && this.views[viewId]) {
            return this.views[viewId].mounted;
        }
        return false;
    }

    viewMounted(viewId, instance) {
        if (this.rootView && this.views[viewId]) {
            this.views[viewId].mounted = true;
            this.views[viewId].viewInstance = instance;
        }
        if (this.views[viewId].mountCallback) {
            this.views[viewId].mountCallback(viewId, instance);
        }
    }

    viewHidden(viewId) {
        if (this.rootView && this.views[viewId]) {
            this.views[viewId].mounted = false;
            this.views[viewId].viewInstance = null;
            let stateUpdate = {};
            stateUpdate[viewId + '_active'] = false;
            this.rootView.setState(stateUpdate);
        }
    }

    hideAllViews() {
        if (this.rootView && this.views) {
            for (let p in this.views) {
                this.hideView(p);
            }
        }
    }

    hideAllBut(exceptionArray) {
        if (this.rootView && this.views) {
            for (let p in this.views) {
                if (exceptionArray.indexOf(p) === -1) {
                    this.hideView(p);
                }
            }
        }
    }

    showAllViews() {
        if (this.rootView && this.views) {
            for (let p in this.views) {
                this.showView(p);
            }
        }
    }

    showAllBut(exceptionArray) {
        if (this.rootView && this.views) {
            for (let p in this.views) {
                if (exceptionArray.indexOf(p) === -1) {
                    this.showView(p);
                }
            }
        }
    }
}