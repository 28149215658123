import koko from "../../koko-framework/koko";
import { config } from "../model/randomised-runner-config";
import * as ComfyJS from 'comfy.js';

export const getGameAssetsBaseUrl = (assetName) => {
    return 'https://d17sm93bt61fgk.cloudfront.net/game_assets/'
   }
export class GameView extends koko.view {
    assets = [
        { id: 'runner_assets_0', src: getGameAssetsBaseUrl()+'randomised_runner-0.json' },
        { id: 'runner_assets_1', src: getGameAssetsBaseUrl()+'randomised_runner-1.json' },
        { id: 'runner_assets_2', src: getGameAssetsBaseUrl()+'randomised_runner-2.json' },
        { id: 'runner_assets_3', src: getGameAssetsBaseUrl()+'randomised_runner-3.json' },
        { id: 'runner_assets_4', src: getGameAssetsBaseUrl()+'randomised_runner-4.json' },
        { id: 'runner_assets_5', src: getGameAssetsBaseUrl()+'randomised_runner-5.json' },
        { id: 'runner_assets_6', src: getGameAssetsBaseUrl()+'randomised_runner-6.json' },
        { id: 'runner_assets_7', src: getGameAssetsBaseUrl()+'randomised_runner-7.json' },
        { id: 'runner_assets_8', src: getGameAssetsBaseUrl()+'randomised_runner-8.json' },
        { id: 'runner_assets_9', src: getGameAssetsBaseUrl()+'randomised_runner-9.json' },
        { id: 'runner_assets_10', src: getGameAssetsBaseUrl()+'randomised_runner-10.json' },
        { id: 'runner_assets_11', src: getGameAssetsBaseUrl()+'randomised_runner-11.json' },
        { id: 'runner_assets_12', src: getGameAssetsBaseUrl()+'randomised_runner-12.json' },
        { id: 'runner_assets_13', src: getGameAssetsBaseUrl()+'randomised_runner-13.json' },
        { id: 'runner_assets_14', src: getGameAssetsBaseUrl()+'randomised_runner-14.json' },
        { id: 'runner_assets_15', src: getGameAssetsBaseUrl()+'randomised_runner-15.json' },
        { id: 'runner_assets_16', src: getGameAssetsBaseUrl()+'randomised_runner-16.json' },
        { id: 'runner_assets_17', src: getGameAssetsBaseUrl()+'randomised_runner-17.json' },
        { id: 'runner_assets_18', src: getGameAssetsBaseUrl()+'randomised_runner-18.json' },
        { id: 'runner_assets_19', src: getGameAssetsBaseUrl()+'randomised_runner-19.json' },
        { id: 'runner_assets_20', src: getGameAssetsBaseUrl()+'randomised_runner-20.json' },
        { id: 'goat_star', src: getGameAssetsBaseUrl()+'shooting_star_goat.png' },
    ];

    buildView() {
        this.children = koko.display.buildLayout([
            {name: 'layer_behind_parallax', type: 'container'},
            {name: 'layer_behind', type: 'container'},
            {name: 'layer_platforms', type: 'container'},
            {name: 'permanent_ground', type: 'container'},
            {name: 'layer_player', type: 'container'},
            {name: 'layer_front', type: 'container'},
            {name: 'layer_front_parallax', type: 'container'},
        ], this.container);

        this.container.scale.x = this.container.scale.y = config.DRAW_SCALE;

        this.resize();




    }
}
