import React from 'react';
import ReactFullScreenView from '../koko-framework/ui/react-views/ReactFullScreenView';
import ReactFullScreenViewUnderCanvas from '../koko-framework/ui/react-views/ReactFullScreenViewUnderCanvas';

import './GeneralMenu.scss';
import './GameOver.scss';

import { assets } from '../assets/assets';
import { config } from '../randomised-runner/model/randomised-runner-config';
import { GAME_CONTROLLER } from '../randomised-runner/controller/randomised-runner-game-controller';
import axios from "../../api/axios";
import { submitScoreRoute, getLeaderboardRoute, getScorePosition } from "../../api/routes";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { v } from '../koko-framework/shorthand';
import { LEADERBOARD_SETTINGS } from '../model/config';

import gsap from 'gsap';
import textLocaliser from '../koko-framework/ui/TextLocaliser';
import { REACT_VIEW_CONTROLLER } from '../Bootstrap';
export default class GameOverPlayButtonView extends ReactFullScreenView {
    componentDidMount(props) {
        super.componentDidMount(props);
    }

    backToMainMenu = () => {
        v.hideView('gameoverView');
        v.hideView('gameoverViewPlayButton');
        v.hideView('confettiView');
        v.showViews(['mainMenu', 'gameHUD', 'backgroundView']);
    }

    nextScreen = () => {
        if (REACT_VIEW_CONTROLLER.isViewActive('gameoverView')) {
            v.get('gameoverView').nextScreen();
        } else {
            this.backToMainMenu();
        }
    }

    renderContent() {
        return (
            <>
                
                        <div className='go-button-container'>
                            <p className='go-small-message'  dangerouslySetInnerHTML={{ __html: textLocaliser.getText('game_over_title_2')}} >
                                
                            </p>
                            <div className='grow'></div>
                            <div onClick={this.nextScreen} className='menu-button'>
                                {/* <FontAwesomeIcon icon={faPlay} size="3x" /> */}
                                <img src={assets.ArrowButtonIcon} alt='Next' />
                            </div>
                            {/* <div className='grow'></div> */}
                        </div>

            </>
        )
    }
}
