import { useEffect, useContext } from "react";

import "./RotateDevice.scss";
import { assets } from "../game/assets/assets";
import { firstLoadAssets } from "../game/assets/first-load-assets";
import textLocaliser from "../game/koko-framework/ui/TextLocaliser";
import { TwitchContext } from '../game/contexts/TwitchContext';
const RotateDevice = () => {  
    const twitchContext = useContext(TwitchContext);
    return (
        <>
        {twitchContext.state.isLoggedIn &&
            <div className="rotate-device">
                <div className="grow"></div>
                    
                        <img className="rd-tw-logo" src={firstLoadAssets.imgMainMenuTwitchLogo} />
                        <div className='rd-playthrough-title'><p>{textLocaliser.getText('logo_tag_line')}</p></div>

                        <img className="rd-icon" src={firstLoadAssets.RotateDeviceImg} />
                        
                        <p className="rd-message-1">{textLocaliser.getText('rotate_device_message_1')}</p>
                        <p className="rd-message-2">{textLocaliser.getText('rotate_device_message_2')}</p>

                <div className="grow"></div>
            </div>
        }
        </>
    );
}

export default RotateDevice;
