import MainSiteContainer from "./containers/MainSiteContainer/MainSiteContainer";
import "./App.scss";
import { useEffect } from "react";
import textLocaliser from "./game/koko-framework/ui/TextLocaliser";
import { texts } from "./game/randomised-runner/model/randomised-runner-text";

function App() {
  // We could use a json file for this!
  textLocaliser.setTexts(texts);

  return (
      <div className="App">
        <MainSiteContainer />
      </div>
  );
}

export default App;
