import React from 'react';
import ReactFullScreenView from '../koko-framework/ui/react-views/ReactFullScreenView';
import ReactFullScreenViewUnderCanvas from '../koko-framework/ui/react-views/ReactFullScreenViewUnderCanvas';

import './GeneralMenu.scss';
import './GameOver.scss';

import { assets } from '../assets/assets';
import { config } from '../randomised-runner/model/randomised-runner-config';
import { GAME_CONTROLLER } from '../randomised-runner/controller/randomised-runner-game-controller';
import axios from "../../api/axios";
import { submitScoreRoute, getLeaderboardRoute, getScorePosition } from "../../api/routes";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import { v } from '../koko-framework/shorthand';
import { LEADERBOARD_SETTINGS } from '../model/config';

import gsap from 'gsap';
import textLocaliser from '../koko-framework/ui/TextLocaliser';
import { CREDITS } from '../randomised-runner/model/randomised-runner-text';
import koko from '../koko-framework/koko';
import { GAME_MODE } from '../contexts/TwitchContext';
export default class GameOverView extends ReactFullScreenViewUnderCanvas {
    componentDidMount(props) {
        super.componentDidMount(props);
        if (GAME_CONTROLLER.model) {
            this.submitScore();
        }

        this.setState({dinoDance: false, displayedScore: 0, showingCredits: false});
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        this.textTween1 && this.textTween1.kill();
        this.textTween2 && this.textTween2.kill();
        this.jumpTween && this.jumpTween.kill();
        this.scoreTween && this.scoreTween.kill();
        this.creditsTween1 && this.creditsTween1.kill();
        this.creditsTween2 && this.creditsTween2.kill();
    }

    transitionIn = () => {
        if (!this.state.transitioning) {
            this.setState({visible: true, transitioning: true});
            setTimeout(() => {this.setState({transitioning: false}); this.textIn();}, 400);

            koko.audio.stop('menu_music');
            koko.audio.play('menu_music', 1, true, 0, false);
        }
    }

    

    backToMainMenu = () => {
        v.hideView('gameoverView');
        v.hideView('confettiView');
        v.showViews(['mainMenu', 'gameHUD', 'backgroundView']);
    }

    showLeaderboard = () => {
        v.hideView('gameoverView');
        v.hideView('menuStarsBgView');
        v.hideView('gameoverViewPlayButton');
        v.hideView('confettiView');
        v.showViews(['gameover_leaderboard', 'menuBgPixi', 'gameover_gameHUD']);
    }

    showCredits = () => {
        this.textOut();
        v.get('confettiView').reduce();
    }

    nextScreen = () => {
        if (!this.state.showingCredits) {
            this.showCredits();
        } else {
            this.showLeaderboard();
        }
    }

    isSubmitting = false;
    LEADERBOARD_TIMEFRAME = 86400;
    //604800 = 1 Week
    //86400 = 24 Hours
    //3600 = 1 Hour
    REQUEST_SCORE_HISTROY = false;
    REQUEST_SCORE_POSITION = true;
    submitScore = () =>
    {

        if(!this.isSubmitting)
        {
            this.isSubmitting = true;
            let usersScore = Math.ceil(Math.random() * 20000);
            let isStreamer = Math.random() > .5 ? true : false;
        
            let submitData = {
                userToken:localStorage.getItem('token'),
                name:localStorage.getItem('username'),
                score: Math.floor(GAME_CONTROLLER.model.itemsCollected/* GAME_CONTROLLER.model.maxWorldXReached / config.PIXELS_PER_METER */),
                isStreamer:GAME_MODE.isStreamer,
                requestHistory:LEADERBOARD_SETTINGS.REQUEST_SCORE_HISTROY,
                requestPosition:LEADERBOARD_SETTINGS.REQUEST_SCORE_POSITION,
                timePeriod:LEADERBOARD_SETTINGS.LEADERBOARD_TIMEFRAME,
                profilePic:localStorage.getItem('profilePic'),
                withCredentials:true           
            }
            axios
            .post(submitScoreRoute, submitData)
            .then(function (response) {
                console.log(response.data)
            })
            .catch(function (error) {
               // alert('Something Went Wrong')
            });
        }
    }

    textIn = () => {
        const text1 = document.getElementById('text-1');
        // const text2 = document.getElementById('text-2');
        this.textTween1 = gsap.to(text1, {opacity: 1, duration: 0.5, ease: 'power1.out', onComplete: () => {this.dinoRunOn()}});
        // this.textTween2 = gsap.to(text2, {marginLeft: 0, duration: 3, ease: 'power1.out'});
    }

    textOut = () => {
        const text1 = document.getElementById('text-1');
        // const text2 = document.getElementById('text-2');
        this.textTween1 = gsap.to(text1, {opacity: 0, duration: 0.5, ease: 'power1.out'});
        // this.textTween2 = gsap.to(text2, {marginLeft: 0, duration: 3, ease: 'power1.out'});

        const scoreContainer = document.getElementById('score-container');
        this.scoreTween = gsap.to(scoreContainer, {marginTop: -100, opacity: 0, duration: 0.5, ease: 'power1.out', onComplete: () => {this.creditsIn()}});
    }

    dinoRunOn = () => {
        const dino = document.getElementById('menu_dino');
        this.jumpTween = gsap.to(dino, {marginLeft: 0, duration: 3, ease: 'none', onComplete: () => {dino.style.marginBottom = 0; this.dinoJump()}});

        const scoreContainer = document.getElementById('score-container');
        this.scoreTween = gsap.to(scoreContainer, {marginTop: 0, opacity: 1, duration: 0.5, ease: 'power1.out'});
        this.tickUpTimeout = setTimeout(this.tickUpScore, 400);
    }

    dinoJump = () => {
        /* const dino = document.getElementById('menu_dino');
        this.jumpTween = gsap.to(dino, {marginBottom: 150, duration: 0.75, repeat: 1, yoyo: true, ease: "power2.out", onComplete: () => {dino.style.marginBottom = 0; this.dinoDance()}});
        setTimeout(this.tickUpScore, 750); */
        this.dinoDance();
    }

    dinoDance = () => {
        v.showView('confettiView');
        v.showView('gameoverViewPlayButton');
        this.setState({dinoDance: true});
    }

    tickUpScore = () => {
        const scoreToReach = GAME_CONTROLLER.model ? GAME_CONTROLLER.model.itemsCollected : 100;
        if (this.state.displayedScore < scoreToReach) {
            const tickUpSpeed = scoreToReach / 255;
            let displayedScore = this.state.displayedScore + tickUpSpeed;
            if (displayedScore > scoreToReach) {
                displayedScore = scoreToReach;
            }
            this.setState({displayedScore: displayedScore});
            setTimeout(this.tickUpScore, 10);
        }
    }

    creditsIn = () => {
        clearTimeout(this.tickUpTimeout);
        this.setState({showingCredits: true});
        setTimeout(this.creditsInNow, 100);
    }

    creditsInNow = () => {
        const credits1 = document.getElementById('credits-1');
        this.creditsTween1 = gsap.to(credits1, {opacity: 1, duration: 0.5, ease: 'power1.out'});
        const credits2 = document.getElementById('credits-2');
        this.creditsTween2 = gsap.fromTo(credits2, {marginTop: 900}, {marginTop:-credits2.clientHeight, duration: credits2.clientHeight / 100 * 0.6, repeat: -1, ease: 'none'});
    }

    renderContent() {
        return (
            <>
                <div className='menu-bg'>
                    {this.state.showingCredits &&
                        <>
                            <p className='go-congrats' id="credits-1" dangerouslySetInnerHTML={{__html: textLocaliser.getText('game_over_credits_title')}}></p>
                            <div className='go-credits-list' id="credits-2">
                                {CREDITS.map((credit, index) => {
                                    return <p key={index}>{credit}</p>
                                })}
                            </div>
                        </>
                    }

                    <div className='ground-full'></div>

                    {!this.state.showingCredits &&
                        <p className='go-congrats' id="text-1" dangerouslySetInnerHTML={{__html: textLocaliser.getText('game_over_title')}}></p>
                    }

                    <div className='go-dino-holder'>
                        <img id='menu_dino' alt="" className='go-dino-run' src={this.state.dinoDance ? assets.DinoDance : assets.DinoRun} />
                    </div>

                    {!this.state.showingCredits &&
                        <div className='go-result-container' id="score-container">
                            <div className='goats'>
                                <img className='gh-icon' src={assets.GoatGoHud} alt='' />
                                <div className='collected-holder'>
                                    <p>{Math.floor(this.state.displayedScore)}</p>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </>
        )
    }
}
