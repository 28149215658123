import axiosDefault from "axios";

const isProduction = process.env.NODE_ENV === "production";

// Exported the baseURL so we can reference it elsewhere, but we might want to think about moving this...

const defaultOptions = {
  baseURL: 'http://localhost:5000',
  withCredentials:false,

};

const axios = axiosDefault.create(defaultOptions);
axios.defaults.withCredentials = false;

/*
axios.interceptors.response.use(
  (response) => {
    // console.log('Interceptor response: ', response);
    return response;
  },
  (error) => {
    // Reload on 401 errors, as long as it's not refreshToken that failed!
    // Should cause us to get new tokens...
    // console.log('Interceptor error: ', error);
    return Promise.reject(error);
  }
)*/

export default axios;
