/** @author Carl Trelfa
 * 
 */

import app from '../app'
import * as PIXI from 'pixi.js'

const CONFETTI_TINT_COLOURS =  [
    0x9146FF,
    /* 0x4f15ad,
    0xfa1ed2, */
    /* 0x2a00a2, */
    0xbca4ff,
    0xffffff,
]

const CONFETTI_COUNT = 120;

export class ConfettiController  {
    mainContainer = null;
    confettiContainer = null;
    confettiPieces = null;

    inited = false;

    removedCount = 0;
    countToRemove = 60;

    totalConfetti = CONFETTI_COUNT;
    dieOffRandomiser = 0.4;

    init(container, countToRemove = 60, total = CONFETTI_COUNT, dieOffRandomiser = 0.4) {
        this.mainContainer = container;
        this.countToRemove = countToRemove;
        this.totalConfetti = total;
        this.confettiContainer = new PIXI.Container(); // new PIXI.ParticleContainer();
        container.addChild(this.confettiContainer);
        this.confettiPieces = [];
        for (var i = 0; i < this.totalConfetti; i++) {
            var onePiece = new ConfettiPieceController();
            onePiece.init(this.countToRemove);
            this.confettiContainer.addChild(onePiece.sprite);
            this.confettiPieces.push(onePiece);
        }
        this.inited = true;

        console.log('Confetti controller: ', this);
    }

    update($delta) {
        if (!this.inited) {
            return;
        }
        // update stuff
        // delta is currently worked for 30fps, even though we are really running at 60fps
        for (var i = 0; i < this.totalConfetti; i++) {
            let r = this.confettiPieces[i].update($delta, this.removedCount, this.dieOffRandomiser);
            if (r) {
                this.removedCount++;
                // console.log('removed count: ', this.removedCount);
            }
        }
    }

    reduceAmountTo(count = 20) {
        this.countToRemove = this.totalConfetti - count;
        for (var i = 0; i < this.totalConfetti; i++) {
            this.confettiPieces[i].countToRemove = this.countToRemove;
        }
    }

    scroll(amount) {
        if (!this.inited) {
            return;
        }
        for (var i = 0; i < this.totalConfetti; i++) {
            this.confettiPieces[i].scroll(amount);
        }
    }

    cull() {
        while (this.removedCount < this.countToRemove) {
            let oneConfetti = this.confettiPieces[Math.floor(Math.random() * this.confettiPieces.length)];
            if (oneConfetti.sprite.parent === this.confettiContainer) {
                this.removedCount++;
                this.confettiContainer.removeChild(oneConfetti.sprite);
            }
        }
    }

    reDrop() {
        for (let i = 0; i < this.confettiPieces.length; i++) {
            let oneConfetti = this.confettiPieces[i];
            if (oneConfetti.sprite.parent !== this.confettiContainer) {
                oneConfetti.reDrop();
                this.confettiContainer.addChild(oneConfetti.sprite);
            }
        }
        this.removedCount = 0;
    }

    destroy() {
        for (var i = 0; i < this.totalConfetti; i++) {
            if (this.confettiPieces[i].sprite.parent) {
                this.confettiPieces[i].sprite.parent.removeChild(this.confettiPieces[i].sprite);
            }
            this.confettiPieces[i].sprite.destroy();
        }
        this.confettiPieces = null;
        this.confettiContainer.parent.removeChild(this.confettiContainer);
        this.confettiContainer.destroy();
        this.confettiContainer = null;

        this.inited = false;
    }
}

const SMALLEST_FLAKE_SCALE = 0.8;
const FLAKE_SCALE_VARIATION = 0.4;

class ConfettiPieceController {
    removed = false;

    // the particle x, y and direction
    px = 0;
    py = 0;
    dx = 0;
    dy = 0;

    // a bit of a wave
    wx = 0;
    wy = 0;
    wxDir = 0;
    wxVel = 0;
    wxAcc = 0;
    wxMax = 0;
    wxMaxVel = 0;
    wyDir = 0;
    wyVel = 0;
    wyMax = 0;
    wyMaxVel = 0;
    wyAcc = 0;

    rSpeed = 0;

    sprite = null;
    spriteId = 0;

    countToRemove = 80;

    init(countToRemove = 80) {
        var s = app.guiSize;
        this.px = Math.random() * s.width;
        this.py = - Math.random() * s.height;

        this.spriteId = (Math.floor(Math.random() * 4) + 1);
        this.sprite = PIXI.Sprite.from('conf-stars-' + (/*this.spriteId < 10 ? ('0' + this.spriteId) :*/ this.spriteId) + '.png');
        this.sprite.tint = CONFETTI_TINT_COLOURS[Math.ceil(Math.random() * CONFETTI_TINT_COLOURS.length)-1];
        this.sprite.x = this.px + this.wx;
        this.sprite.y = this.py + this.wy;

        this.countToRemove = countToRemove;

        this.randomiseMovement();
    }

    reDrop() {
        var s = app.guiSize;
        this.py = - Math.random() * s.height;
        this.randomiseMovement();
    }

    randomiseMovement() {
        this.dx = Math.random() * 3 - 1.5;
        this.dy = Math.random() * 6 + 3;
        this.wxMax = Math.random() * 12 + 8;
        this.wyMax = Math.random() * 12 + 8;
        this.wxMaxVel = Math.random() * 2 + 1;
        this.wyMaxVel = Math.random() * 2 + 1;
        this.wxVel = Math.random() * this.wxMaxVel;
        this.wyVel = Math.random() * this.wyMaxVel;
        this.wxAcc = Math.random() * 0.3 + 0.1;
        this.wyAcc = Math.random() * 0.3 + 0.1;
        this.wxDir = Math.random() < 0.5 ? -1 : 1;
        this.wyDir = Math.random() < 0.5 ? -1 : 1;

        this.rSpeed = Math.random() * 0.05 - 0.025;

        this.spriteId = (Math.floor(Math.random() * 4) + 1);
        this.sprite.texture = PIXI.Texture.from('conf-stars-' + (/*this.spriteId < 10 ? ('0' + this.spriteId) :*/ this.spriteId) + '.png');
        this.sprite.tint = CONFETTI_TINT_COLOURS[Math.ceil(Math.random() * CONFETTI_TINT_COLOURS.length)-1];

        this.sprite.scale.x = this.sprite.scale.y = SMALLEST_FLAKE_SCALE + Math.random() * FLAKE_SCALE_VARIATION;
        this.sprite.rotation = Math.random() * Math.PI * 2;
        // this.sprite.alpha = 0.6 + Math.random() * 0.4;

        /* var s = app.guiSize;
        this.px = Math.random() * s.width; */
    }

    update = ($delta, removedCount, dieOffRandomiser) => {
        let removed = false;

        this.px += this.dx * $delta;
        this.py += this.dy * $delta;

        this.wx += this.wxVel * $delta;
        if ( (this.wx > this.wxMax && this.wxDir > 0) || (this.wx < -this.wxMax && this.wxDir < 0) ) {
            this.wxDir = -this.wxDir;
        }
        this.wxVel += this.wxAcc * this.wxDir * $delta;
        if (Math.abs(this.wxVel) > this.wxMaxVel) {
            this.wxVel = this.wxMaxVel * this.wxDir;
        }
        this.wy += this.wyVel * $delta;
        if ( (this.wy > this.wyMax && this.wyDir > 0) || (this.wy < -this.wyMax && this.wyDir < 0) ) {
            this.wyDir = -this.wyDir;
        }
        this.wyVel += this.wyAcc * this.wyDir * $delta;
        if (Math.abs(this.wyVel) > this.wyMaxVel) {
            this.wyVel = this.wyMaxVel * this.wyDir;
        }

        removed = this.wrap(removedCount, dieOffRandomiser);
        this.sprite.x = this.px + this.wx;
        this.sprite.y = this.py + this.wy;
        
        /* if ( (this.spriteId > 5 && this.spriteId < 106) || (this.spriteId > 111) ) {
            this.sprite.rotation += this.rSpeed * $delta;
        } */
        
        this.removed = removed;
        return removed;
    }

    scroll(amount) {
        var scrollScale = 1 + (this.sprite.scale.x - SMALLEST_FLAKE_SCALE);
        this.px += amount * scrollScale;
    }

    wrap = (removedCount, dieOffRandomser) => {
        let reRandomise = false;
        let removed = false;
        
        if (this.px < -100) {
            this.px += app.guiSize.width + 50;
            reRandomise = true;
        }
        if (this.px > app.guiSize.width + 100) {
            this.px -= app.guiSize.width + 50;
            reRandomise = true;
        }
        
        /*
        if (this.py < 0) {
            this.py += app.guiSize.height;
            reRandomise = true;
        }
        */
        if (this.py > app.guiSize.height + 100) {
            this.py -= app.guiSize.height + 150;
            reRandomise = true;
            if (removedCount < this.countToRemove && Math.random() > dieOffRandomser) {
                // this.sprite.visible = false;
                if (this.sprite.parent) {
                    this.sprite.parent.removeChild(this.sprite);
                    // console.log('confetti removed');
                    removed = true;
                }
            }
        }
        if (reRandomise) {
            this.randomiseMovement();
        }

        return removed;
    }
}