import React, { useState, setState } from "react";
import { useOAuth2 } from "@tasoskakour/react-use-oauth2";
import axios from "../../api/axios";
import koko from '../koko-framework/koko';
import { getTokenRoute } from "../../api/routes";
const TwitchContext = React.createContext([[], () => {}])

let initialState = {initialising: true, isLoggedIn:false, twitchData:{}, username:'', profileImg:'', isStreamer:false}
export const GAME_MODE = {isStreamer: false, isGOAT:false, bestScore:0, position:0}
const TwitchProvider = (props) => {
  
  const [state, setState] = useState(initialState)

  const { data, loading, error, getAuth, logout } = useOAuth2({
    authorizeUrl: "https://id.twitch.tv/oauth2/authorize",  
    clientId: "mz86rxbogm6fg8qynw9jihsxilwnpj",             
    redirectUri: `${document.location.origin}/callback`,   
    scope: 'chat:read user:read:email openid',     
    
    extraQueryParameters:{
        force_verify:false,
        claims: JSON.stringify({
            "id_token":{ email:null},
          userinfo: {
            email_verified:null,
            picture:null,
            preferred_username:null
        }})
    },

    responseType: "token id_token",
    exchangeCodeForTokenServerURL:  `${document.location.origin}`,
    exchangeCodeForTokenMethod: "GET",
    onSuccess: (payload) => {

        //The payload data inludes the following from Twitch:
        //access_token - Used to get useful user info from the OAuth2 userinfo end point, we will also use this for the clips endpoint
        //id_token - Contains useful user info

        setState( {...state, initialising: false, isLoggedIn:true, twitchData:payload});
        localStorage.setItem("token", payload.access_token);
       
        //Get the user info to set the context values and local storage for the username and profile pic
        //UserInfo end point is also used in the backend leaderboard so the we are ptoecting the end pint from fraudulent entries as Twitch auths the access token

        axios.request({
            method: "GET",
            url: `https://id.twitch.tv/oauth2/userinfo`,
           
          }).then(response => {
            //User data has been successfully retrieved. Save the username and user profile pic to local storage and also the context state
            localStorage.setItem("username", response.data.preferred_username);
            localStorage.setItem("profilePic", response.data.picture);

            setState( {...state, username: response.data.preferred_username, profilePic:response.data.picture, initialising: false, isLoggedIn:true, twitchData:payload});
            
           // axios
           // .post(getTokenRoute, {name: response.data.preferred_username, picture:response.data.picture, withCredentials:true})
          // .then(function (response) {
               localStorage.setItem("userToken", response.data.token);
                axios.request({
                  method: "GET",
                  url: `https://api.twitch.tv/helix/users`,
                  headers: {
                    'Client-ID':'mz86rxbogm6fg8qynw9jihsxilwnpj',
                    'Accept': 'application/vnd.twitchtv.v5+json',
                  }
                })
                .then(response => {
                  axios.request({
                    method: "GET",
                    url: `https://api.twitch.tv/helix/clips`,
                    params:{broadcaster_id:response.data.data[0].id},
                    headers: {
                      'Client-ID':'mz86rxbogm6fg8qynw9jihsxilwnpj',
                      'Accept': 'application/vnd.twitchtv.v5+json',
                    }
                  })
                  .then(response => {
                      if(response.data.data.length > 0)
                      {
                        let randomVid1 = Math.floor(Math.random()*response.data.data.length);
                        let randomVid2 = Math.floor(Math.random()*response.data.data.length);
                        let randomClip1 = response.data.data[randomVid1].thumbnail_url.split('preview')[0].slice(0,-1)+'.mp4';
                        let randomClip2 = response.data.data[randomVid2].thumbnail_url.split('preview')[0].slice(0,-1)+'.mp4';
                        localStorage.setItem('twitchClip1', randomClip1)
                        localStorage.setItem('twitchClip2', randomClip2)
                      }
                    })
                })
            })
            .catch(function (error) {
               // alert('Something Went Wrong')
            });


          
            
            /*axios.request({
              method: "GET",
              url: `https://api.twitch.tv/helix/clips`,
              params: { broadcaster: response.data.preferred_username },
      
            })
            .then(response => {
              console.log('BROADCASTER CLIPS', response)
            })*/
         
        // });
        },

    onError: (error_) => console.log("Error", error_)
  });

  return (
    <TwitchContext.Provider value={{state, setState,
      login: (isStreamer) => {
        //localStorage.setItem('isStreamer', isStreamer)
        GAME_MODE.isStreamer = isStreamer;
          //Login to Tiwtch using OAuth
          getAuth();
        },
        logout: () => {
          //Logout of Twitch
          koko.views.hideView('mainMenu');
          logout();
          setState( {...state, username: '', profilePic:'', initialising: false, isStreamer:false, isLoggedIn:false, twitchData:{}});
      }
      }}>
      {props.children}
    </TwitchContext.Provider>
  )
}

export { TwitchContext, TwitchProvider }