import gsap from "gsap";
import app from "../../koko-framework/app";
import { au, v } from "../../koko-framework/shorthand";
import { FRAME_DELTA_LIMIT, FRAME_TIME, LANGUAGE_SETTINGS } from "../../model/config";
import { config } from "../model/randomised-runner-config";
import { GameModel, PLAYER_STATES } from "../model/randomised-runner-game-model";
import { MapController } from "./randomised-runner-map-controller";
import { PlayerController } from "./randomised-runner-player-controller";
import koko from "../../koko-framework/koko";
import * as PIXI from 'pixi.js';
import objectPooler from "../../koko-framework/objectPooler";
import ComfyJS from "comfy.js";
import {isMobile} from 'react-device-detect';
export const EVENTS = {
    COLLECT_ITEM: 'collect_item',
    BOOST_GAME: 'boost_game',
    COLLIDED: 'collided',
    DIE: 'die',
    WORLD_END: 'world_end',
}

class GameController {
    running = false;

    vidStarted = false;
    vidBuffering = false;
    lastVidSeekTime = 0;
    lastVidSeekTimeCheck = 0;

    flashingVfx = [];

    startGame() {
        this.model = new GameModel();
        v.showView('gameView');
        this.mapController = new MapController(v.get('gameView'));
        this.mapController.init(this.model);
        this.mapController.addStartChunk(1600 / config.DRAW_SCALE);

        let playerStartPos = this.mapController.findStart();
        playerStartPos.x = -200;
        this.mapController.scroll(playerStartPos.x + config.TILE_WIDTH / 2, 1);
        this.mapController.update(FRAME_TIME, 1);
        // console.log('Start Pos: ', playerStartPos);

        this.playerController = new PlayerController(v.get('gameView'));
        this.playerController.init(playerStartPos.x, playerStartPos.y, this.model);
        this.playerController.player.alpha = 0;

        this.mapController.addRandomRightChunks();
        this.mapController.hideShowSprites();

        // Some object can be hit more than once (eg. bouncers), but only once we have cleared them
        this.collisionsToReset = [];
        this.flashingVfx = [];

        this.createTwitchConnection();
        this.startUpdate();
    }

    boostPrevented = false;
    createTwitchConnection = () =>{
        //ADD THE CUSTOM GAME COMMANDS HERE FOR TWITCH CHAT
        //ComfyJS.onCommand = ( user, command, message, flags, extra) =>{
            document.addEventListener('goatemotey', () => {
                if(!this.boostPrevented)
                {
               
                    document.dispatchEvent(new CustomEvent(EVENTS.BOOST_GAME));
                    this.model.boostProgress += .1;
                    this.boostPrevented = true;

                

                    if(this.model.boostProgress >= 1)
                    {
                        this.startBonus();
                        this.model.boostProgress = 0
                        this.boostPrevented = true;
                        gsap.to( this.model, {boostProgressDisplay:0, duration:8, onComplete:()=>{this.boostPrevented = false}})
                        //this.model.boostProgress = 0;
                    }
                    else
                    {
                        gsap.to( this.model, {boostProgressDisplay:this.model.boostProgress , duration:1, onComplete:()=>{this.boostPrevented = false}})
                    }

                    v.get('ingameHUD').update();


                    //RUN REVIVE CODE BELOW HERE

                }
            })
        
    }

    reStartGame() {
        // this.mapController.addStartChunk(this.model.worldX - config.TILE_WIDTH);
        let playerStartPos = this.mapController.findClosestStart(this.model.playerX);
        if (!playerStartPos) {
            playerStartPos = this.mapController.findStart();
        }
        console.log('ReStart Pos: ', playerStartPos);
        this.playerController.init(playerStartPos.x, playerStartPos.y, this.model);
        this.mapController.addRandomRightChunks();
        this.mapController.hideShowSprites();
        this.startUpdate();
        // gsap.to(v.get('gameView').container, {alpha: 1, duration: 0.5});

        v.hideView('reviveView');
    }

    died() {
        koko.audio.play(config.SOUNDS.DEATH);
        this.stopUpdate();
        /* gsap.to(v.get('gameView').container, {alpha: 0, duration: 0.5, onComplete: () => {
            this.mapController.destroyMap();
            this.playerController.destroyPlayer();
            document.dispatchEvent(new CustomEvent(EVENTS.DIE));
            // setTimeout(() => {this.reStartGame()}, 1000);
        }}); */
        this.playerController.destroyPlayer();
        
        // Reset run speed when we die!
        this.model.playerMaxVelocityX = config.RUNNER.startRunSpeed;
        // console.log('Reset run speed to: ', this.model.playerMaxVelocityX);

        document.dispatchEvent(new CustomEvent(EVENTS.DIE));
        // setTimeout(() => {this.reStartGame()}, 1000);

        v.showView('reviveView');
    }

    landed(platform, playerFalling) {
        // console.log('Landed on platform: ', platform);
        if (platform && platform.config.switch_asset_on_landing && !platform.asset_switched) {
            platform.asset_switched = true;
            const assetList = JSON.parse(platform.config.asset_list);
            if (assetList.length) {
                const asset = assetList[Math.floor(Math.random() * assetList.length)];
                platform.defaultSprite.texture = PIXI.Texture.from(asset);
            }
        }
    }

    checkForDueAssetSwapsOnPassing() {
        this.mapController.checkForDueAssetSwapsOnPassing();
    }

    gameComplete() {
        if (this.running) {
            this.stopUpdate();
            if (isMobile) {
                au.stop('vid_' + LANGUAGE_SETTINGS.lang);
            }
            gsap.to(v.get('gameView').container, {alpha: 0, duration: 0.5, onComplete: () => {
                this.mapController.destroyMap();
                this.playerController.destroyPlayer();
                v.hideView('gameView');
            }});
            v.hideView('ingameHUD');
            v.hideView('reviveView');
            v.hideView('videoFeature');
            v.showView('gameoverView');
        }
    }

    checkForVidBuffering = () => {
        const vidSeekTime = v.get('videoFeature').getVidSeekTime();
        const vidSeekTimeCheck = Date.now();

        if (vidSeekTime > 0) {
            this.vidStarted = true;
            if (this.playerController.player.alpha === 0) {
                gsap.to(this.playerController.player, { alpha: 1, duration: 0.5 });
            }
            if (isMobile) {
                au.play('vid_' + LANGUAGE_SETTINGS.lang);
            }
        }
        if (vidSeekTime === this.lastVidSeekTime && vidSeekTimeCheck - this.lastVidSeekTimeCheck > 1000) {
            this.vidBuffering = true;
            this.lastVidSeekTimeCheck = vidSeekTimeCheck;
        } else {
            if (vidSeekTime !== this.lastVidSeekTime) {
                this.vidBuffering = false;
                this.lastVidSeekTimeCheck = vidSeekTimeCheck;
            }
        }

        if (this.vidBuffering || !this.vidStarted) {
            v.showView('bufferingMess');
        } else {
            v.hideView('bufferingBg');
            v.hideView('bufferingMess');
        }

        this.lastVidSeekTime = vidSeekTime;
    }

    update(deltaMult, deltaTime) {
        this.checkForVidBuffering();

        deltaMult /= config.DEBUG_SLOMO_FACTOR;

        if (!this.vidStarted || (this.vidBuffering && this.mapController.view.container.x < 0)) {
            // Has the effect of pausing the game!
            deltaMult = 0;
        }
        
        this.playerController.update(deltaMult, deltaTime);
        if (this.playerController.player) {
            let xBefore = this.model.worldX;
            if (!this.model.hasReachedEnd) {
                if (!this.mapController.focus(this.playerController.player)) {
                    if (this.model.worldX > 0) {
                        this.mapController.focusSmooth(this.playerController.player, deltaMult);
                    }
                }
            } else {
                this.mapController.checkIfEnteredNewWorld(0, 0);
            }
            let movementDist = this.model.worldX - xBefore;
            this.mapController.updateParallaxLayers(deltaTime, deltaMult, movementDist);

            if (this.model.worldX > this.model.maxWorldXReached) {
                this.model.maxWorldXReached = this.model.worldX;
            }
            v.get('ingameHUD').update();
        }

        if (this.model.bonusCountdown > 0) {
            this.model.bonusCountdown -= (deltaMult * 16.666) / 1000;
            if (this.model.bonusCountdown <= 0) {
                this.mapController.removeOffRightChunks();
                this.mapController.addRandomRightChunks();
            }
        }

        this.mapController.update(deltaTime, deltaMult);

        if (this.playerController.player) {
            const potentialCollisions = this.findPotentialCollisions();
            if (potentialCollisions.length > 0) {
                this.processCollisions(potentialCollisions);
            }

            // Reset any collisions that are no longer colliding, 
            // more of a failsafe really - we are highly unlikely to hit an object more than once
            if (this.collisionsToReset.length > 0) {
                for (let i = this.collisionsToReset.length - 1; i >= 0; i--) {
                    const collisionObject = this.collisionsToReset[i];
                    if (Math.abs(collisionObject.x - this.playerController.player.x) > (config.RUNNER.collisionWidth / 2 + collisionObject.width)
                        || this.playerController.player.y < collisionObject.y - collisionObject.height
                        || this.playerController.player.y - this.playerController.player.height > collisionObject.y) {
                            collisionObject.collided = false;
                            this.collisionsToReset.splice(i, 1);   
                        }
                }
            }
        }

        this.checkForDueAssetSwapsOnPassing();
        this.flashVfx();
    }

    startUpdate() {
        app.removeUpdateListener('rrg_updater');
           
		app.addUpdateListener('rrg_updater', (time, delta) => { 
            let deltaRatio = 1;
            deltaRatio = delta / FRAME_TIME;
            if (deltaRatio > FRAME_DELTA_LIMIT) {
                deltaRatio = FRAME_DELTA_LIMIT;
            }

            this.update(deltaRatio, delta);
        });

        this.running = true;
    }

    stopUpdate() { 
        app.removeUpdateListener('rrg_updater');
        while (app.updateListeners['rrg_updater']) {
            app.removeUpdateListener('rrg_updater');
        }

        this.running = false;
    }

    findPotentialLandingPlatforms() {
        let minX = this.model.playerX - config.RUNNER.collisionWidth / 2;
        let maxX = this.model.playerX + config.RUNNER.collisionWidth / 2;
        // console.log('Finding potential landing platforms: ', minX, maxX);
        return this.mapController.findPotentialLandingPlatforms(minX, maxX);
    }

    findPotentialCollisions(type = {exclude: 'platform'}) {
        let minX = this.model.playerX - config.RUNNER.collisionWidth / 2;
        let maxX = this.model.playerX + config.RUNNER.collisionWidth / 2;
        let minY = this.model.playerY - this.playerController.player.height + config.RUNNER.collisionTopOffset;
        let maxY = this.model.playerY - config.RUNNER.collisionBottomOffset;
        // console.log('Finding potential collisions: ', minX, maxX, minY, maxY);
        return this.mapController.findPotentialCollisions(minX, maxX, minY, maxY, type);
    }


    startBonus = () =>{
        this.model.bonusCountdown = config.GOAT_BONUS_TIME_SECS;
        this.mapController.removeOffRightChunks();
        this.mapController.removeOffRightTiles();
        this.mapController.addSpecificRightChunks('bonus', true);
    }

    processCollisions(potentialCollisions) {
        // console.log('Potential Collisions: ', potentialCollisions);
        for (let i = 0; i < potentialCollisions.length; i++) {
            const collisionObject = potentialCollisions[i];
            // console.log('Processing collision: ', collisionObject);
            collisionObject.collided = true;
            if (collisionObject.config.collectable) {
                collisionObject.collected = true;
                gsap.to(collisionObject, { alpha: 0, y: collisionObject.y - 100, duration: 0.35 });
                if (collisionObject.associatedEffect) {
                    gsap.to(collisionObject.associatedEffect, { alpha: 0, y: collisionObject.associatedEffect.y - 100, duration: 0.35 });
                }
                
                if (collisionObject.config.object_id === 'main_collectable') {
                    const val = collisionObject.config.value || 1;
                    this.model.itemsCollected += val;
                    if (config.VFX['VAL_' + val]) {
                        this.addVfx(config.VFX['VAL_' + val], collisionObject.x + collisionObject.width / 2, collisionObject.y - collisionObject.height - 20, collisionObject.parent, 'VAL_' + val, 1.5, false, true, {x: 0, y: -config.VFX['VAL_' + val].yMoveDistance || -200}, 1);
                    }
                    v.get('ingameHUD').update();
                } else
                if (collisionObject.config.object_id === 'start_bonus') {
                    this.startBonus();
                }

                if (config.SOUNDS['COLLECT_' + collisionObject.config.object_id]) {
                    koko.audio.play(config.SOUNDS['COLLECT_' + collisionObject.config.object_id], 1, false, 0, true);
                } else {
                    koko.audio.play(config.SOUNDS.COLLECT, 1, false, 0, true);
                }

                if (config.VFX['COLLECT_' + collisionObject.config.object_id]) {
                    this.addItemTileVfx(collisionObject, config.VFX['COLLECT_' + collisionObject.config.object_id], 'COLLECT_' + collisionObject.config.object_id);
                }

                document.dispatchEvent(new CustomEvent(EVENTS.COLLECT_ITEM, { detail: { item: collisionObject.config.object_id } }));
            } else
            if (collisionObject.config.bouncer) {
                this.playerController.bounceJump();
                this.collisionsToReset.push(collisionObject);

                if (config.VFX['BOUNCE_PLATFORM']) {
                    this.addItemTileVfx(collisionObject, config.VFX['BOUNCE_PLATFORM'], 'BOUNCE_PLATFORM');
                }
            } else {
                // Hackey last second fix so we can't collide with twitchcon flag
                if (collisionObject.config.object_id !== 'twitchcon_flag') {
                    if (config.VFX['OBSTACLE_' + collisionObject.config.object_id]) {
                        this.addItemTileVfx(collisionObject, config.VFX['OBSTACLE_' + collisionObject.config.object_id], 'OBSTACLE_' + collisionObject.config.object_id);
                    }

                    if (config.SOUNDS['COLLIDE_' + collisionObject.config.object_id]) {
                        koko.audio.play(config.SOUNDS['COLLIDE_' + collisionObject.config.object_id], 1, false, 0, true);
                    } else {
                        koko.audio.play(config.SOUNDS.COLLIDE_GENERIC, 1, false, 0, true);
                    }

                    gsap.to(collisionObject, { alpha: 0, duration: 0.35 });
                    this.playerController.hitObject();

                    document.dispatchEvent(new CustomEvent(EVENTS.COLLIDED, { detail: { item: collisionObject.config.object_id } }));
                }
                
            }
        }
    }

    addItemTileVfx(itemTile, vfxConfig, configId) {
        if (itemTile.parent) {
            // console.log('Adding VFX: ', itemTile, vfxConfig, configId);
            const tileBounds = itemTile.cachedBounds;
            let vfxAnim = objectPooler.getObjectFromPool(configId);
            if (!vfxAnim) {
                vfxAnim = koko.display.createAnim(vfxConfig.animPrefix, vfxConfig.animFrames, typeof vfxConfig.leadingZeros === 'number' ? vfxConfig.leadingZeros : 1, vfxConfig.startFrame || 0, 1);
                vfxAnim.anchor.x = vfxAnim.anchor.y = 0.5;
                vfxAnim.loop = false;
                vfxAnim.animationSpeed = vfxConfig.animSpeed || 0.5;
                vfxAnim.scale.x = vfxAnim.scale.y = vfxConfig.scale || 1;
            }
            vfxAnim.onComplete = () => {
                vfxAnim.parent.removeChild(vfxAnim);
                objectPooler.addObjectToPool(configId, vfxAnim);
            }
            const offsetX = typeof vfxConfig.offsetX === 'number' ? vfxConfig.offsetX : 0;
            const offsetY = typeof vfxConfig.offsetY === 'number' ? vfxConfig.offsetY : 0;
            // console.log('VFX Bounds: ', tileBounds.x, tileBounds.y, tileBounds.width, tileBounds.height, itemTile.x, itemTile.y);
            vfxAnim.x = itemTile.x + tileBounds.x * itemTile.scale.x + tileBounds.width / 2 * itemTile.scale.x + offsetX;
            vfxAnim.y = itemTile.y + tileBounds.y * itemTile.scale.y + tileBounds.height / 2 * itemTile.scale.y + offsetY;
            vfxAnim.visible = true;
            vfxAnim.alpha = 1;
            vfxAnim.gotoAndPlay(0);
            // console.log('Adding VFX: ', vfxAnim);
            itemTile.parent.addChild(vfxAnim);
        }
    }

    addVfx(vfxConfig, x, y, parent, configId, scaleMultiplier = 1, removeOnAnimComplete = true, moveEffect = false, moveVector = {x: 0, y: 0}, moveTime = 0.5, withGravity = false, flash = false) {
        if (parent) {
            let vfxAnim = objectPooler.getObjectFromPool(configId);
            if (!vfxAnim) {
                vfxAnim = koko.display.createAnim(vfxConfig.animPrefix, vfxConfig.animFrames, typeof vfxConfig.leadingZeros === 'number' ? vfxConfig.leadingZeros : 1, vfxConfig.startFrame || 0, 1);
                vfxAnim.anchor.x = vfxAnim.anchor.y = 0.5;
                vfxAnim.loop = false;
                vfxAnim.animationSpeed = vfxConfig.animSpeed || 0.5;
            }
            const scale = vfxConfig.minScale && vfxConfig.maxScale ? Math.random() * (vfxConfig.maxScale - vfxConfig.minScale) + vfxConfig.minScale : vfxConfig.scale || 1;
            vfxAnim.scale.x = vfxAnim.scale.y = scale * scaleMultiplier;
            if (removeOnAnimComplete) {
                vfxAnim.onComplete = () => {
                    vfxAnim.parent.removeChild(vfxAnim);
                    objectPooler.addObjectToPool(configId, vfxAnim);
                }
            }
            const xOffset = vfxConfig.minXOffset && vfxConfig.maxXOffset ? Math.random() * (vfxConfig.maxXOffset - vfxConfig.minXOffset) + vfxConfig.minXOffset : 0;
            const yOffset = vfxConfig.minYOffset && vfxConfig.maxYOffset ? Math.random() * (vfxConfig.maxYOffset - vfxConfig.minYOffset) + vfxConfig.minYOffset : 0;
            vfxAnim.x = x + xOffset;
            vfxAnim.y = y + yOffset;
            vfxAnim.visible = true;
            vfxAnim.alpha = 1;
            vfxAnim.gotoAndPlay(0);
            parent.addChild(vfxAnim);

            if (moveEffect) {
                if (!withGravity) {
                    gsap.to(vfxAnim, { x: vfxAnim.x + moveVector.x, y: vfxAnim.y + moveVector.y, duration: moveTime });
                    gsap.to(vfxAnim, { alpha: 0, duration: moveTime / 2, delay: moveTime / 2, onComplete: () => {if (!removeOnAnimComplete) {vfxAnim.parent.removeChild(vfxAnim); objectPooler.addObjectToPool(configId, vfxAnim);}} });
                } else {
                    gsap.to(vfxAnim, { x: vfxAnim.x + moveVector.x, duration: moveTime });
                    gsap.to(vfxAnim, { y: vfxAnim.y + moveVector.y, duration: moveTime / 2, ease: 'power2.out' });
                    gsap.to(vfxAnim, {y: vfxAnim.y - moveVector.y, duration: moveTime / 2, ease: 'power2.in', delay: moveTime / 2 });
                    gsap.to(vfxAnim, { alpha: 0, duration: moveTime / 4, delay: moveTime * 0.75, onComplete: () => {if (!removeOnAnimComplete) {vfxAnim.parent.removeChild(vfxAnim); objectPooler.addObjectToPool(configId, vfxAnim);}} });
                }
            }
            if (flash) {
                this.flashingVfx.push(vfxAnim);
            }
        }
    }

    flashVfx = () => {
        for (let i = this.flashingVfx.length - 1; i >= 0; i--) {
            const vfx = this.flashingVfx[i];
            if (vfx.parent) {
                vfx.visible = !vfx.visible;
            } else {
                this.flashingVfx.splice(i, 1);
            }
        }
    }
}

export const GAME_CONTROLLER = new GameController();
