import BaseReactView from './BaseReactView';

/**
 * @author Carl Trelfa
 * Your basic full screen react view, will position and scale to fit the underlying pixi based views.
 * The most basic view will extend this and over-ride the renderContent function.
 * Note: this view will always scale based on the visible view area - it will not crop like
 * the pixi views so design with this in mind. I could make a cropping version, where you could use
 * absolute positioning and viewPort.left / top etc, but it seems uneccessary as you can just use flexbox for layouts.
 */
export default class ReactFullScreenView extends BaseReactView {
    render() {
        let fadeInClass = this.state.visible ? ' fade-in' : ''
        return (
            <div className={'view-holder over-canvas' + fadeInClass} style={{transformOrigin: '0 0', transform:'scale(' + this.state.scale + ')', left: this.state.zeroLeft, top: this.state.zeroTop, width: this.state.width, height: this.state.height}}>
                {this.renderContent()}
                {this.state.transitioning &&
                    <div className='click-blocker over-canvas'></div>
                }
            </div>
        );
    }

    // you'll need to over-ride this for your layout
    renderContent() {
        return (
            <div>Your view layout goes here</div>
        );
    }
}