import React from 'react'
import ReactFullScreenViewUnderCanvas from '../koko-framework/ui/react-views/ReactFullScreenViewUnderCanvas';
import "./BackgroundView.scss";

import { TwitchContext, TwitchProvider} from '../contexts/TwitchContext';
import { BACKGROUND_STATE } from '../model/config';

import textLocaliser from '../koko-framework/ui/TextLocaliser';
import { assets, getAssetSrcUrl } from '../assets/assets';
//Import images

import koko from '../koko-framework/koko'
import YTBackgroundView from './YTBackgroundView';
export default class BackgroundView extends ReactFullScreenViewUnderCanvas {
    static contextType = TwitchContext
    // make sure you call the superclass in didMount and willUnmount

    componentDidMount() {
        // I would recommend doing your stuff before calling super.componentDidMount,
        // as this may cause a callback...
        super.componentDidMount();
    }

    componentWillUnmount() {
        // the super version of this removes a resize listener so, make sure you call this!
        super.componentWillUnmount();
    }

    /* There a alot of spacer empty growing row divs here! */
    renderContent() {   
        //if(this.props.backgroundState == BACKGROUND_STATE.LOGIN ) koko.views.showView('YTBackground1');
        return (
            <>
                {this.props.backgroundState == BACKGROUND_STATE.LOGIN &&   
                <>  
                 
                </>
                }

                {this.props.backgroundState == BACKGROUND_STATE.LOADING &&     
                <>
                    <div className="loadingBackground">
                         
                    </div>

                    <div className='twitchLogo'>
                        <img src={assets.TwitchLogo} />
                        <div className='playthroughTitle'><p>{textLocaliser.getText('logo_tag_line')}</p></div>
                    </div>

                    <div className='loading-message'>
                        {textLocaliser.getText('loading')}
                    </div>
                </>
                }

                {this.props.backgroundState == BACKGROUND_STATE.MAIN_MENU &&     
                    <div className='menu-bg'></div>
                }
            
            </>
        );
    }
}