
import * as ComfyJS from 'comfy.js';

const tmi = require('tmi.js');

let connectingToTwitch = false;
let client = null;

const onMessageHandler = (target, context, msg, self) => {
    if (self) { return; } // Ignore messages from the bot
    // Remove whitespace from chat message
    const trimmedMessage = msg.trim();
   console.log('HANDLE TWITCH MESSAGE')
    if(trimmedMessage.toLowerCase().includes('goatemotey'))
    {
        document.dispatchEvent(new CustomEvent('goatemotey'));
    }
}
const onConnectedHandler = (connection) => {
    console.log("\x1b[32m[KokoAPI][ChatConnection] Connected to Twitch chat", "\x1b[0m")
   
}
const onDisconnectedHandler = () => {
    console.log("\x1b[32m[KokoAPI][ChatConnection] Twitch chat has been disconnected", "\x1b[0m")  
}

export const InitTwitchConnect = (twitchChannel) =>
{
    if(!connectingToTwitch)
    {
        
        client = new tmi.Client({ connection: {reconnect: true, secure: true},channels: [ localStorage.getItem('username')]});
        client.on('message', onMessageHandler);
        client.on('connected', onConnectedHandler);
        client.on('disconnected', onDisconnectedHandler);
        client.connect();
        connectingToTwitch = true;
        console.log('CONNECTING TO TWITCH', client)

        /*
        ComfyJS.onCommand = ( user, command, message, flags, extra) =>{
            console.log('COMMAND FIRED:', command);
            console.log('Extra Data', extra);
        }

        const twitchAccount = localStorage.getItem('username');
        const oauthPass = null; //Not needed at this stage but this would be the user access token if needed. Depends if the game needs to post to chat.
        ComfyJS.Init(twitchAccount, oauthPass);
        connectingToTwitch = true;*/
    }
}