import objectPooler from "../objectPooler";
import * as PIXI from 'pixi.js';
import { ORTHAGONAL, ISOMETRIC } from './TileMapEngine'
import { a } from "../shorthand";

/** @author Carl Trelfa
 * A renderer for orthagonal tile maps (ie. flat, not isometric)
 * Used by TileMapEngine, not massively useful on it's own.
 * 
 * You'll probably find the depth sort function useful!
 * 
 * Some special properties can be added to Tiles that are supported out of the box.
 * 
 *      randomSort {boolean} - If set to true we make random small adjustments to the tiles y pos, meaning they will be randomly sorted
 *                              when depth sorting. (useful for trees if they overlap to make them look more interesting)
 * 
 *      special {boolean} - Causes the sprite to be added to a specialSprites array in the returned render data so we can find them easily.
 *                          you might need more properties specific to your game.
 * 
 *      moving {boolean} - Set this to moving, you will also need to set velocityX and velocityY, most useful for randomised scrolling maps,
 *                          these will be added to a specialSprites array in the returned render data so we can find them easily.
 *      
 *      startActive {boolean} - Moving tiles can start off activated, but usually don't (defaults to false), everything else deaults to true
 *      
 *      dynamic {boolean} - These will be added to a dynamicSprites array. The intention is the graphic will change based on the surrounding tiles
 *                          so you can make walls that join up and can be partially destroyed.
 * 
 * Other useful flags are added to tile sprites:
 * 
 *      collided / activated {booleans}, these are intended to be controlled by your game, so you can push buttons or track collisions.
 *                                          eg. a spike might become "not dangerous" after a collision, allowing you to pass through it...
 */

export class OrthRenderer {
    tileWidth = 64;
    tileHeight = 64;
    view = null;
    layerContainers = {};
    
    // When a new sprite is added, we set it's visiblity to this
    // this can help with optimisation
    newSpritesVisible = true;

    selectedLangIndex = 0;

    type = ORTHAGONAL;

    constructor(view, tileWidth, tileHeight) {
        this.tileWidth = tileWidth || this.tileWidth;
        this.tileHeight = tileHeight || this.tileHeight;
        this.view = view;
    }

    renderMapChunk(mapChunk, tileSets, chunkX, chunkY) {
        // console.log('render chunk: ', mapChunk);
        var plotStartX = chunkX + this.tileWidth / 2;
        var plotStartY = chunkY + this.tileHeight / 2;
        var minTx = 100000000;
        var minTy = 100000000;
        var maxTx = -100000000;
        var maxTy = -100000000;

        let activeChunkData = {
            mapChunk: mapChunk,
            layerBounds: [],
            sprites: [],
            renderedSprites: [],
            spriteGrid: [],
            specialSprites: [],
            dynamicSprites: [],
            switcherSprites: [],
            chunkX: chunkX,
            chunkY: chunkY,
            chunkPlotStartX: plotStartX,
            chunkPlotStartY: plotStartY,
        }
        // We can specifiy the render_layer for each tile in our tileset, this will overide the current layer, 
        // meaning our designers don't have to worry about getting everything on the correct layer.
        // Render layer can either be a number (the index in our layers array) or an id string (recommended)
        for (var l = 0; l < mapChunk.layers.length; l++) {
            var l_minTx = 100000000;
            var l_minTy = 100000000;
            var l_maxTx = -100000000;
            var l_maxTy = -100000000;
            activeChunkData.spriteGrid.push([]);
            for (var gx = 0; gx < mapChunk.layers[l].length; gx++) {
                activeChunkData.spriteGrid[l].push([]);
                // chunkSpritesLayersGrid[l][gx].push([]);
                for (var gy = 0; gy < mapChunk.layers[l][gx].length; gy++) {
                    activeChunkData.spriteGrid[l][gx].push(null);
                    if (mapChunk.layers[l][gx][gy] != null) {
                        let plotPixelPos = this.calculateTilePixelPos(activeChunkData, gx, gy);
                        var tx = plotPixelPos.x;
                        var ty = plotPixelPos.y;
                        
                        let tileSetId = mapChunk.layers[l][gx][gy].tileSet;
                        let tileId = mapChunk.layers[l][gx][gy].tile;
                        let tileSprite = this.setupTileSprite(activeChunkData, l, gx, gy, Math.round(tx), Math.round(ty), tileSets, tileSetId, tileId);
                        if (tileSprite === null) {
                            continue;
                        }

                        this.addTileToChunk(activeChunkData, tileSprite);
                        var tConfig = tileSets[tileSetId].getTile(tileId);
                        if (tConfig.add_effect && this.addTileEffectCallback) {
                            this.addTileEffectCallback(tileSprite);
                        }

                        // work out total bounds
                        tx += tileSprite.width;
                        ty -= tileSprite.height;
                        
                        if (tx > maxTx) {
                            maxTx = tx;
                        }
                        if (ty > maxTy) {
                            maxTy = ty;
                        }
                        if (tx < minTx) {
                            minTx = tx;
                        }
                        if (ty < minTy) {
                            minTy = ty;
                        }
                        // work out layer bounds
                        if (tx > l_maxTx) {
                            l_maxTx = tx;
                        }
                        if (ty > l_maxTy) {
                            l_maxTy = ty;
                        }
                        if (tx < l_minTx) {
                            l_minTx = tx;
                        }
                        if (ty < l_minTy) {
                            l_minTy = ty;
                        }
                    }
                }
            }
            l_minTx -= this.tileWidth / 2;
            l_minTy -= this.tileHeight / 2;
            l_maxTx += this.tileWidth / 2;
            l_maxTy += this.tileHeight / 2;
            activeChunkData.layerBounds.push(new PIXI.Rectangle(minTx, minTy, maxTx - minTx, maxTy - minTy));
        }
        minTx -= this.tileWidth / 2;
        minTy -= this.tileHeight / 2;
        maxTx += this.tileWidth / 2;
        maxTy += this.tileHeight / 2;
        // console.log('rendered chunk: ', mapChunk);
        activeChunkData.bounds = new PIXI.Rectangle(minTx, minTy, maxTx - minTx, maxTy - minTy);
        return activeChunkData;
    }

    calculateTilePixelPos(activeChunkData, gridX, gridY) {
        let tx = activeChunkData.chunkPlotStartX + gridX * this.tileWidth;
        let ty = activeChunkData.chunkPlotStartY + gridY * this.tileHeight;
        return {x: tx, y: ty};
    }

    getPixelCenterTopOfGridSquare(gridX, gridY) {
        let tx = gridX * this.tileWidth + this.tileWidth / 2;
        let ty = gridY * this.tileHeight;
        return {x: tx, y: ty};
    }

    getPixelCenterOfGridSquare(gridX, gridY) {
        let tx = gridX * this.tileWidth + this.tileWidth / 2;
        let ty = gridY * this.tileHeight + this.tileHeight / 2;
        return {x: tx, y: ty};
    }

    setupTileSprite(activeMapChunkData, layer, gridX, gridY, baseTileX, baseTileY, tileSets, tileSetId, tileId) {
        let mapChunk = activeMapChunkData.mapChunk;

        var tConfig = tileSets[tileSetId].getTile(tileId);
        if (typeof tConfig === 'undefined') {
            // console.log('NO TILE!: ', layer, gridX, gridY, mapChunk.layers[layer][gridX][gridY], tileSets, tileSetId, tileId);
            return null;
        }

        tileSets[tileSetId].selectedLangIndex = this.selectedLangIndex;
        var tileSprite = tileSets[tileSetId].getTileSprite(tileId, this.waterDistortPoints);
        if (tConfig.randomOffsetX) {
            tileSprite.x = baseTileX - tConfig.randomOffsetX + Math.floor(Math.random() * (tConfig.randomOffsetX * 2)) + tConfig.offset.x;
        } else {
            tileSprite.x = baseTileX + tConfig.offset.x;
        }
        if (tConfig.randomOffsetY) {
            tileSprite.y = baseTileY - tConfig.randomOffsetY + Math.floor(Math.random() * (tConfig.randomOffsetY * 2)) + tConfig.offset.y;
        } else {
            tileSprite.y = baseTileY + tConfig.offset.y;
        }
        if (tConfig.randomSort) {
            // we just add a small random variation to the y so our depth sort sometimes puts them in front / behind
            // makes it more interesting that tiles to the right always going in front
            tileSprite.y -= 0.5;
            tileSprite.y += Math.random();
        }
        // give each sprite a reference to it's containing mapChunk, tileset and grid ref
        if (mapChunk.layers[layer][gridX][gridY] && mapChunk.layers[layer][gridX][gridY].tileSet === tileSetId && mapChunk.layers[layer][gridX][gridY].tile === tileId) {
            // use original data if it matches!
            tileSprite.tileData = mapChunk.layers[layer][gridX][gridY];
        } else {
            tileSprite.tileData = {
                tileSet: tileSetId,
                tile: tileId,
                gridX: gridX,
                gridY: gridY,
                layer: layer,
                mapChunk: mapChunk,
            };
        }
        tileSprite.mapChunk = mapChunk;
        tileSprite.activeMapChunkData = activeMapChunkData;
        tileSprite.layer = layer;
        tileSprite.gridX = gridX;
        tileSprite.gridY = gridY;
        tileSprite.baseTileX = baseTileX;
        tileSprite.baseTileY = baseTileY;
        tileSprite.collided = false;
        tileSprite.activated = false;
        tileSprite.inView = true;
        tileSprite.config = tConfig;
        tileSprite.localConfig = null;
        // console.log('Map Type: ', this.type);
        for (let c = 0; c < mapChunk.configData.length; c++) {
            if (this.type === ORTHAGONAL) {
                if (mapChunk.configData[c].gx === gridX && mapChunk.configData[c].gy === gridY) {
                    tileSprite.localConfig = mapChunk.configData[c];
                }
            }
            if (this.type === ISOMETRIC) {
                /*
                let spriteBounds = tileSprite.getLocalBounds();
                // console.log('Looking for local config, iso style: ', tileSprite, tileSprite.x, tileSprite.y, spriteBounds, mapChunk.configData);
                if (tileSprite.x + spriteBounds.left < mapChunk.configData[c].pixelx && tileSprite.y + spriteBounds.top < mapChunk.configData[c].pixely &&
                    tileSprite.x + spriteBounds.right > mapChunk.configData[c].pixelx + 10 && tileSprite.y + spriteBounds.bottom > mapChunk.configData[c].pixely + 10
                ) {
                    tileSprite.localConfig = mapChunk.configData[c];
                    console.log('Tilesprite got localConfig: ', tileSprite);
                }
                */
                if (mapChunk.configData[c].isogx === gridX && mapChunk.configData[c].isogy === gridY) {
                    tileSprite.localConfig = mapChunk.configData[c];
                    // console.log('Tilesprite got localConfig: ', tileSprite);
                }
            }
        }
        tileSprite.visible = this.newSpritesVisible;

        // over-ride x,y offsets
        if (tConfig.canOverideOffsets) {
            if (tileSprite.localConfig && tileSprite.localConfig.offsetX) {
                tileSprite.x = baseTileX + tConfig.offset.x + tileSprite.localConfig.offsetX;
            }
            if (tileSprite.localConfig && tileSprite.localConfig.offsetY) {
                tileSprite.y = baseTileY + tConfig.offset.y + tileSprite.localConfig.offsetY;
            }
        }

        if (tConfig.special || tConfig.start || tConfig.moving || tConfig.floats || tConfig.play_sound || tileSprite.localConfig !== null) {
            tileSprite.treatAsSpecial = true;
        } else {
            tileSprite.treatAsSpecial = false;
        }
        if (tConfig.moving || tConfig.floats) {
            // Save the start pos and reset the velocity. OrthEngine will handle the movement.
            tileSprite.startX = tileSprite.x;
            tileSprite.startY = tileSprite.y;
            tileSprite.vx = 0;
            tileSprite.vy = 0;
            tileSprite.startedMoving = false;
        }

        if (!tileSprite.buffered) {
            // console.log('Add buffer to sprite');
            if (this.tileBuffers && this.tileBuffers.length > layer) {
                // console.log('Buffer = ', this.tileBuffers[layer]);
                tileSprite.buffered = true;
                tileSprite.width += this.tileBuffers[layer];
                tileSprite.height += this.tileBuffers[layer];
                tileSprite.width = Math.round(tileSprite.width);
                tileSprite.height = Math.round(tileSprite.height);
            }
        }

        // config.collision_width is shorthand for setting both offsets to the same value
        if (tConfig.collision_width && !tConfig.collision_offset_left) {
            tConfig.collision_offset_left = tConfig.collision_offset_right = (tileSprite.width - tConfig.collision_width) / 2;
            // console.log('Added collision offsets: ', tileSprite);
        }
        // config.collision_height is shorthand for setting both offsets to the same value
        if (tConfig.collision_height && !tConfig.collision_offset_top) {
            tConfig.collision_offset_top = tConfig.collision_offset_bottom = (tileSprite.height - tConfig.collision_height) / 2;
            // console.log('Added collision offsets: ', tileSprite);
        }


        return tileSprite;
    }

    addTileToChunk(activeMapChunkData, tileSprite) {
        let layer = tileSprite.layer;
        let gridX = tileSprite.gridX;
        let gridY = tileSprite.gridY;
        let renderLayer = layer;
        if (typeof tileSprite.config.render_layer === 'number') {
            renderLayer = tileSprite.config.render_layer;
        }
        if (typeof tileSprite.config.render_layer === 'string') {
            if (tileSprite.mapChunk.layerNames.length > 0) {
                renderLayer = tileSprite.mapChunk.layerNames.indexOf(tileSprite.config.render_layer);
            }
            // console.log('force render layer: ', tConfig.render_layer, renderLayer);
            if (renderLayer === -1) {
                renderLayer = layer;
            }
        }
        if (tileSprite.config.do_not_render !== true && tileSprite.config.do_not_render !== 'true') {
            const layerContainer = this.view.children['layer_' + tileSprite.mapChunk.layerNames[renderLayer]];
            if (layerContainer) {
                if (typeof this.layerContainers[tileSprite.mapChunk.layerNames[renderLayer]] === 'undefined') {
                    this.layerContainers[tileSprite.mapChunk.layerNames[renderLayer]] = layerContainer;
                }
                layerContainer.addChild(tileSprite);
                tileSprite.layerContainer = layerContainer;
                // an optimisation used when hiding off-screen sprites. We can discount all sprites set to do_not_render.
                activeMapChunkData.renderedSprites.push(tileSprite);
            }
        }
        if (typeof tileSprite.config.alpha === 'number') {
            tileSprite.alpha = tileSprite.config.alpha;
        }
        activeMapChunkData.sprites.push(tileSprite);
        activeMapChunkData.spriteGrid[layer][gridX][gridY] = tileSprite;
        if (tileSprite.treatAsSpecial) {
            activeMapChunkData.specialSprites.push(tileSprite);
        }
        if (tileSprite.config.dynamic) {
            activeMapChunkData.dynamicSprites.push(tileSprite);
        }
        if (tileSprite.config.stateSwitcher) {
            activeMapChunkData.switcherSprites.push(tileSprite);
        }
        if (tileSprite.config.moving || tileSprite.config.floats) {
            // console.clear();
            // console.log('moving sprite created: ', tConfig, tileSprite.velocity, tileSprite.y);
            // Save the start pos and reset the velocity. OrthEngine will handle the movement.
            tileSprite.startX = tileSprite.x;
            tileSprite.startY = tileSprite.y;
            tileSprite.vx = 0;
            tileSprite.vy = 0;
            tileSprite.startedMoving = false;
        }
        tileSprite.enabled = true;
    }

    // only removes the tile from the chunk, doesn't remove it from the view, allowing you to transition it out if need be
    removeTile(activeMapChunkData, tileSprite) {
        if (activeMapChunkData.sprites.indexOf(tileSprite)) {
            // this chunk contains the sprite!
            activeMapChunkData.sprites.splice(activeMapChunkData.sprites.indexOf(tileSprite), 1);
            if (activeMapChunkData.renderedSprites.indexOf(tileSprite) >= 0) {
                activeMapChunkData.renderedSprites.splice(activeMapChunkData.renderedSprites.indexOf(tileSprite), 1);
            }
            if (activeMapChunkData.specialSprites.indexOf(tileSprite)) {
                activeMapChunkData.specialSprites.splice(activeMapChunkData.specialSprites.indexOf(tileSprite), 1);
            }
            if (activeMapChunkData.dynamicSprites.indexOf(tileSprite)) {
                activeMapChunkData.dynamicSprites.splice(activeMapChunkData.dynamicSprites.indexOf(tileSprite), 1);
            }
            if (activeMapChunkData.switcherSprites.indexOf(tileSprite)) {
                activeMapChunkData.switcherSprites.splice(activeMapChunkData.switcherSprites.indexOf(tileSprite), 1);
            }
            activeMapChunkData.spriteGrid[tileSprite.layer][tileSprite.gridX][tileSprite.gridY] = null;
            return true;
        }
        return false;
    }

    /**
     * Depth sort a layer.
     * @param {string} layer - name of the layer in our layerContainers array
     */
     depthSortLayer(layer) {
        this.layerContainers[layer].children.sort( (a, b) =>  (a.depthSortY ? a.depthSortY : (a.trimBottom ? a.y - a.trimBottom : a.y)) - (b.depthSortY ? b.depthSortY : (b.trimBottom ? b.y - b.trimBottom : b.y)) );
    }

    clear() {
        for (let prop in this.layerContainers) {
            var removedChildren = this.layerContainers[prop].removeChildren();
            for (var j = 0; j < removedChildren.length; j++) {
                if (typeof objectPooler !== 'undefined') {
                    objectPooler.addObjectToPool(removedChildren[j].spriteID, removedChildren[j]);
                } else {
                    removedChildren[j].destroy();
                }
            }
        }
    }

    removeChunkSprites(chunkSprites) {
        // console.log('removing chunk sprites (with pooling)');
        for (var i = 0; i < chunkSprites.length; i++) {
            if (chunkSprites[i]) {
                if (chunkSprites[i].parent) {
                    chunkSprites[i].parent.removeChild(chunkSprites[i]);
                }
                if (chunkSprites[i].associatedEffect) {
                    const associatedEffect = chunkSprites[i].associatedEffect;
                    if (associatedEffect.tweens) {
                        for (let j = 0; j < associatedEffect.tweens.length; j++) {
                            associatedEffect.tweens[j].kill();
                        }
                    }
                    if (associatedEffect.parent) {
                        associatedEffect.parent.removeChild(associatedEffect);
                        associatedEffect.destroy();
                    }
                    chunkSprites[i].associatedEffect = null;
                }
                if (typeof objectPooler !== 'undefined') {
                    objectPooler.addObjectToPool(chunkSprites[i].spriteID, chunkSprites[i]);
                } else {
                    chunkSprites[i].destroy();
                }
            }
        }
    }

    // Some stuff for distortion effects (used for water)
    waterDistortCount = null;
    waterDistortTileSize = 64;
    waterDistortPointsCount = 16;
    waterDistortPoints = null;
    waterDistortInited = false;
    waterDistortSpeed = 0.075;
    waterDistortXdepth = 2;
    waterDistortXFrequency = 0.02;
    waterDistortYdepth = 2;
    waterDistortYFrequency = 0.03;

    initWaterDistort(distortTileSize = 64, distortPointsCount = 16, distortSpeed = 0.075, distortXDepth = 2, distortXFrequency = 0.08, distortYDepth = 2, distortYFrequency = 0.12) {
        this.waterDistortCount = 0;
        this.waterDistortTileSize = distortTileSize;
        this.waterDistortPointsCount = distortPointsCount;

        this.waterDistortPoints = [];
        let pointDistance = this.waterDistortTileSize / this.waterDistortPointsCount;
        for (let i = 0; i < this.waterDistortPointsCount; i++) {
            this.waterDistortPoints.push(new PIXI.Point(i * pointDistance, 0));
        }

        this.waterDistortSpeed = distortSpeed;
        this.waterDistortXdepth = distortXDepth;
        this.waterDistortXFrequency = distortXFrequency;
        this.waterDistortYdepth = distortYDepth;
        this.waterDistortYFrequency = distortYFrequency;

        this.updateWaterDistortion(1);
        this.waterDistortInited = true;
    }

    updateWaterDistortion($deltaMult) {
        this.waterDistortCount += this.waterDistortSpeed * $deltaMult;
        let pointDistance = this.waterDistortTileSize / this.waterDistortPointsCount;
        // make the snake
        for (var i = 0; i < this.waterDistortPoints.length; i++) {
            this.waterDistortPoints[i].y = Math.sin((i * this.waterDistortYFrequency) + this.waterDistortCount) * this.waterDistortYdepth;
            this.waterDistortPoints[i].x = i * pointDistance + Math.cos((i * this.waterDistortYFrequency) + this.waterDistortCount) * this.waterDistortYdepth;
        }
    }
}