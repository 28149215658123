/**
 * Any assets added here will be automatically preloaded by the preloader.
 */


export const getAssetBaseUrl = (assetName) => {
 return 'https://d17sm93bt61fgk.cloudfront.net/menu_assets/'
}
export const assets = {
    // Bundled assets
    // ImageOverlay: ImageOverlay,
    GoatHead: getAssetBaseUrl()+'goat_head.png',
    GoatHud: getAssetBaseUrl()+'goatHUD1.png',
    MenuHud3DDino: getAssetBaseUrl()+'menu_hud_3d_dino.png',
    ReviveHeartPurple: getAssetBaseUrl()+'big_bleed_heart_purple.png',
    ReviveHeartGrey: getAssetBaseUrl()+'big_bleed_heart_grey.png',
    ClickMouseRevive: getAssetBaseUrl()+'click_mouse_revive_icon.png',
    GenMenuBg: getAssetBaseUrl()+'gen_menu_bg.png',
    MenuBgGround: getAssetBaseUrl()+'main_menu_ground.png',
    MenuDino: getAssetBaseUrl()+'menu_dino.png',
    HelpPanel1: getAssetBaseUrl()+'help_panel_1.png',
    HelpPanel2: getAssetBaseUrl()+'help_panel_2.png',
    HelpPanel3: getAssetBaseUrl()+'help_panel_3.png',
    HelpPanel4: getAssetBaseUrl()+'help_panel_4.png',
    Speedlines1: getAssetBaseUrl()+'speedlines_1.png',
    Speedlines2: getAssetBaseUrl()+'speedlines_2.png',
    Speedlines3: getAssetBaseUrl()+'speedlines_3.png',
    Speedlines4: getAssetBaseUrl()+'speedlines_4.png',
    ShootingStar1: getAssetBaseUrl()+'shooting_star_1.png',
    ShootingStarGOAT: getAssetBaseUrl()+'shooting_star_goat.png',
    DinoRun: getAssetBaseUrl()+'dino_run.gif',
    FireBasket: getAssetBaseUrl()+'FireBasket.png',
    SpikeBall: getAssetBaseUrl()+'SpikeBall.png',
    TntBarrel: getAssetBaseUrl()+'TntBarrel.png',
    TutorialIcon: getAssetBaseUrl()+'tutorial-icon.svg',
    ProgressDino: getAssetBaseUrl()+'progress_dino.png',
    DinoDance: getAssetBaseUrl()+'dino_dance.gif',
    GoatGoHud: getAssetBaseUrl()+'GoatGoHud.png',

    ShareIcon: getAssetBaseUrl()+'share-icon.svg',
    InfoIcon: getAssetBaseUrl()+'info-icon.svg',
    SoundOff: getAssetBaseUrl()+ 'sound-off.svg',
    SoundOn: getAssetBaseUrl()+'sound-on.svg',
    FullScreen: getAssetBaseUrl()+'full-screen.svg',

    ArrowButtonIcon: getAssetBaseUrl()+'arrow-button-icon.png',
    GameOverGround: getAssetBaseUrl()+'game_over_ground.png',

    _3DThumbsUp: getAssetBaseUrl()+'3d_thumbs-up.png',
    SolidShareIcon: getAssetBaseUrl()+'solid-share-icon.png',
    RotateLeftIcon: getAssetBaseUrl()+'rotate-left-icon.png',
    HomeIcon: getAssetBaseUrl()+'home-icon.svg',
    TwitchLogo:getAssetBaseUrl()+'main_menu_twitch_logo.png',
    Collectable1:getAssetBaseUrl()+'Collectable1.png',

    VideoGradient:getAssetBaseUrl()+'video-gradient.jpg',
  
    // You can add assets from the public folder / external assets as url strings here and the preloader will preload them, along with these bundled ones.
    // BgPattern: process.env.REACT_APP_MENU_ASSET_LOC+'/background_pattern.jpg',
}
/*export const assets = {
    // Bundled assets
    // ImageOverlay: ImageOverlay,
    GoatHead: GoatHead,
    GoatHud: GoatHud,
    MenuHud3DDino: MenuHud3DDino,
    ReviveHeartPurple: ReviveHeartPurple,
    ReviveHeartGrey: ReviveHeartGrey,
    ClickMouseRevive: ClickMouseRevive,
    GenMenuBg: GenMenuBg,
    MenuBgGround: MenuBgGround,
    MenuDino: MenuDino,
    HelpPanel1: HelpPanel1,
    HelpPanel2: HelpPanel2,
    HelpPanel3: HelpPanel3,
    HelpPanel4: HelpPanel4,
    Speedlines1: Speedlines1,
    Speedlines2: Speedlines2,
    Speedlines3: Speedlines3,
    Speedlines4: Speedlines4,
    ShootingStar1: ShootingStar1,
    ShootingStarGOAT: ShootingStarGOAT,
    DinoRun: DinoRun,
    FireBasket: FireBasket,
    SpikeBall: SpikeBall,
    TntBarrel: TntBarrel,
    Collectable1: Collectable1,
    TutorialIcon: TutorialIcon,
    ProgressDino: ProgressDino,
    DinoDance: DinoDance,
    GoatGoHud: GoatGoHud,

    ShareIcon: ShareIcon,
    InfoIcon: InfoIcon,
    SoundOff: SoundOff,
    SoundOn: SoundOn,
    FullScreen: FullScreen,

    ArrowButtonIcon: ArrowButtonIcon,
    GameOverGround: GameOverGround,

    _3DThumbsUp: _3DThumbsUp,
    SolidShareIcon: SolidShareIcon,
    RotateLeftIcon: RotateLeftIcon,
    HomeIcon: HomeIcon,

    // You can add assets from the public folder / external assets as url strings here and the preloader will preload them, along with these bundled ones.
    // BgPattern: process.env.REACT_APP_MENU_ASSET_LOC+'/background_pattern.jpg',
}*/
export const getAssetsListForPreloading = () => {
    const assetList = [];
    for (let p in assets) {
        assetList.push(assets[p]);
    }
    return assetList;
}