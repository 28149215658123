import React from "react";
import ReactFullScreenView from "../koko-framework/ui/react-views/ReactFullScreenView";
import "./MainMenuStarsBgView.scss";

import gsap from "gsap";

export default class MainMenuStarsBgView extends ReactFullScreenView {
    state = {
        speedLinesFrame: 1,
    }

    // make sure you call the superclass in didMount and willUnmount
    componentDidMount() {
        // I would recommend doing your stuff before calling super.componentDidMount,
        // as this may cause a callback...
        super.componentDidMount();

        this.speedLinesInterval = setInterval(() => {
            this.setState({
                speedLinesFrame: this.state.speedLinesFrame + 1 <= 4 ? this.state.speedLinesFrame + 1 : 1
            });
        }, 100);
    }
    
    componentWillUnmount() {
        // the super version of this removes a resize listener so, make sure you call this!
        super.componentWillUnmount();

        clearInterval(this.speedLinesInterval);
    }
    
    /* There a alot of spacer empty growing row divs here! */
    renderContent() {
        return (
            <>
                {/* <div className={`speed-lines speed-lines-f${this.state.speedLinesFrame}`}></div>
                <div className="stars-bg">
                    
                </div> */}
                <div className='ground-full-main-menu'></div>
            </>
        );
    }
}
