import koko from "../../koko-framework/koko";
import * as PIXI from 'pixi.js'
import { config } from "../model/randomised-runner-config";
import { PLAYER_STATES } from "../model/randomised-runner-game-model";
import { GAME_CONTROLLER } from "./randomised-runner-game-controller";
import { c, v } from "../../koko-framework/shorthand";
import gsap from "gsap";

export class PlayerController {
    constructor(view) {
        this.view = view;
    }

    extraPlayerSprites = [];
    lastJumpTime = 0;
    minJumpTimeGap = 10;

    // Having trouble with mouse events firing as well as touch when in chrome dev mode.
    // I can't think of a time when this could happen on a real device, but to counter it...
    touchEnded = true;
    touchJump = (e) => {
        // console.log('Touch Jump: ', e);
        this.jump();
        this.touchEnded = false;

        // Remove mouse events for a short time to prevent double jump
        document.body.removeEventListener('mousedown', this.mouseJump);
    }
    // Also on real devices it looks like touchstart events can fire multiple times,
    // so we only accept a new touchstart after all touches have ended.
    touchEndHandler = (e) => {
        if (e.touches.length === 0) {
            // console.log('Touch Jump End: ', e);
            this.touchEnded = true;

            // Re-add mouse events after a short timeOut
            this.mouseEventAddTimeout = setTimeout(() => {
                document.body.addEventListener('mousedown', this.mouseJump);
            }, 500);
        }
    }

    keyPressListener = (e) => {
        if (e.keyCode === 32) {
            // console.log('Space Jump: ', e);
            this.jump();
        }
    }

    mouseJump = (e) => {
        // console.log('Mouse Jump: ', e);
        this.jump();
    }

    init(startX, startY, model) {
        this.model = model;
        console.log('Init player: ', this.model, startX, startY);
        this.player = this.view.children.layer_player.addChild(new PIXI.Container());
        this.model.playerX = startX;
        this.model.playerY = startY;
        this.model.playerVelocityX = 0;
        this.model.playerVelocityY = 0;
        this.player.x = startX;
        this.player.y = startY;
        this.player.scale.x = this.player.scale.y = config.DRAW_SCALE * config.RUNNER.viewScale;

        this.playerAnims = {};
        for (let state in PLAYER_STATES) {
            if (config.RUNNER.view[PLAYER_STATES[state]].animateRunner) {
                this.playerAnims[PLAYER_STATES[state]] = koko.display.createAnim(config.RUNNER.view[PLAYER_STATES[state]].animationName, config.RUNNER.view[PLAYER_STATES[state]].animationFrameCount, typeof config.RUNNER.view[PLAYER_STATES[state]].leadingZeros === 'number' ? config.RUNNER.view[PLAYER_STATES[state]].leadingZeros : 1);
                this.playerAnims[PLAYER_STATES[state]].animationSpeed = config.RUNNER.view[PLAYER_STATES[state]].animationSpeed;
                this.playerAnims[PLAYER_STATES[state]].loop = config.RUNNER.view[PLAYER_STATES[state]].animationLoop;
            } else {
                this.playerAnims[PLAYER_STATES[state]] = PIXI.Sprite.from(config.RUNNER.view[PLAYER_STATES[state]].stillRunnerFrame);
            }
            this.playerAnims[PLAYER_STATES[state]].anchor = {x: 0.5, y: 1};
        }

        this.view.children.layer_player.addChild(this.player);
        this.currentViewState = 'none';

        this.beret = PIXI.Sprite.from('beret.png');
        this.beret.anchor = {x: 0.5, y: 0.5};
        this.beret.visible = false;
        this.player.addChild(this.beret);
        this.extraPlayerSprites.push(this.beret);

        gsap.to(this.beret.anchor, {y: 0.55, duration: 0.5, repeat: -2, yoyo: true, ease: 'back.out(1.7)'});

        this.beretPuff = koko.display.createAnim('beret_puff/beret_', 14, 0, 1, 1);
        this.beretPuff.animationSpeed = 0.5;
        this.beretPuff.loop = false;
        this.beretPuff.onComplete = () => {
            this.beretPuff.visible = false;
        };
        this.beretPuff.anchor = {x: 0.5, y: 0.5};
        this.beretPuff.scale.x = this.beretPuff.scale.y = 1.5;
        this.beretPuff.visible = false;
        this.player.addChild(this.beretPuff);
        this.extraPlayerSprites.push(this.beretPuff);

        this.controlsBubble = PIXI.Sprite.from('ControlsBubble.png');
        this.controlsBubble.anchor = {x: 0.5, y: 1};
        this.controlsBubble.scale.x = this.controlsBubble.scale.y = 1.75;
        this.player.addChild(this.controlsBubble);
        this.extraPlayerSprites.push(this.controlsBubble);

        this.update(0);
        
        const potentialLandingPlatforms = GAME_CONTROLLER.findPotentialLandingPlatforms();
        // console.log('Potential Landing Platforms: ', potentialLandingPlatforms);
        for (let i = 0; i < potentialLandingPlatforms.length; i++) {
            const platform = potentialLandingPlatforms[i];
            // console.log('Platform: ', platform, platform.cachedBounds);
            const platformY = platform.y + platform.cachedBounds.y * platform.scale.y + (platform.config.collision_offset_top || 0) + config.RUNNER.collisionBottomOffset;
            // console.log('Platform Y: ', platformY, platform.y, platform.cachedBounds.y, platform.config.collision_offset_top, this.model.playerY);
            if (Math.abs(platformY - this.model.playerY) < config.TILE_HEIGHT) {
                this.model.playerY = platformY;
                this.player.y = platformY;
                break;
            }
        }

        document.body.addEventListener('touchstart', this.touchJump);
        document.body.addEventListener('touchend', this.touchEndHandler);
        document.body.addEventListener('mousedown', this.mouseJump);
        document.body.addEventListener('keydown', this.keyPressListener);
    }

    showBeret = () => {
        this.beret.visible = true;
        this.beret.scale.x = this.beret.scale.y = 0;
        gsap.to(this.beret.scale, {x: 1, y: 1, duration: 0.5, ease: 'back.out(1.7)'});
        this.beretPuff.alpha = 0;
        gsap.to(this.beretPuff, {alpha: 1, duration: 0.5, ease: 'power2.out'});
        this.beretPuff.visible = true;
        this.beretPuff.gotoAndPlay(0);
    }

    hideBeret = () => {
        if (this.beret.visible) {
            gsap.to(this.beretPuff, {alpha: 0, duration: 0.5, ease: 'power2.in'});
            gsap.to(this.beret.scale, {x: 0, y: 0, duration: 0.5, ease: 'back.in(1.7)', onComplete: () => {
                this.beret.visible = false;
            }});
        }
    }

    destroyPlayer() {
        clearTimeout(this.mouseEventAddTimeout);
        document.body.removeEventListener('mousedown', this.mouseJump);
        document.body.removeEventListener('touchstart', this.touchJump);
        document.body.removeEventListener('touchend', this.touchEndHandler);
        document.body.removeEventListener('keydown', this.keyPressListener);
        gsap.killTweensOf(this.beret.anchor);
        for (let i = 0; i < this.extraPlayerSprites.length; i++) {
            this.player.removeChild(this.extraPlayerSprites[i]);
            this.extraPlayerSprites[i].destroy();
        }
        this.extraPlayerSprites = [];
        this.player.destroy();
        this.player = null;
    }

    update(deltaMult, deltaTime) {
        // console.log('Update player: ', this.model, deltaMult, this.model.playerState);
        if (this.currentViewState !== this.model.playerState) {
            if (this.player.children.length > 0 && this.extraPlayerSprites.indexOf(this.player.children[0]) === -1) {
                this.player.removeChildAt(0);
            }
            this.player.addChildAt(this.playerAnims[this.model.playerState], 0);
            if (config.RUNNER.view[this.model.playerState].animateRunner) {
                this.playerAnims[this.model.playerState].gotoAndPlay(0);
            }

            this.beret.x = 20;
            this.beret.y = -this.player.children[0].height; // * 0.95;
            this.beretPuff.x = 20;
            this.beretPuff.y = -this.player.children[0].height; // * 0.95;
            this.controlsBubble.x = 0;
            this.controlsBubble.y = -this.player.children[0].height - 75;

            this.currentViewState = this.model.playerState;
        }

        if (this.model.playerState === PLAYER_STATES.RUNNING) {
            this.model.playerVelocityX += config.RUNNER.accelleration * deltaMult;
            if (this.model.playerVelocityX > this.model.playerMaxVelocityX) {
                this.model.playerVelocityX = this.model.playerMaxVelocityX;
            }
        }

        const permaGroundY = config.VIEW_HEIGHT / config.DRAW_SCALE - (config.PERMANENT_GROUND_BOTTOM_OFFSET * config.TILE_HEIGHT) + config.PERMANENT_GROUND_PLATFORM_OFFET_TOP + config.RUNNER.collisionBottomOffset;
        if (!config.USE_PERMANENT_GROUND || !config.SLOW_DOWN_ON_PERMANENT_GROUND || this.model.playerY < permaGroundY) {
            this.model.playerMaxVelocityX += config.RUNNER.runSpeedIncreasePerSecond / 60 * deltaMult;
            if (this.model.playerMaxVelocityX > config.RUNNER.maxRunSpeed) {
                this.model.playerMaxVelocityX = config.RUNNER.maxRunSpeed;
            }
        } else {
            if (!this.model.hasReachedEnd && config.USE_PERMANENT_GROUND && config.SLOW_DOWN_ON_PERMANENT_GROUND && this.model.playerY >= permaGroundY) {
                this.model.playerMaxVelocityX -= config.PERMANENT_GROUND_SLOW_DOWN_SPEED * deltaMult;
                if (this.model.playerMaxVelocityX < config.RUNNER.startRunSpeed) {
                    this.model.playerMaxVelocityX = config.RUNNER.startRunSpeed;
                }
            }
        }

        this.model.playerX += this.model.playerVelocityX * deltaMult;
        this.player.x = this.model.playerX;

        if (this.model.playerState === PLAYER_STATES.JUMPING || this.model.playerState === PLAYER_STATES.HIT) {
            if (!this.model.isBumped && this.model.playerVelocityY <= -config.RUNNER.addJump1StarsMinJumpSpeed) {
                this.addJumpStars();
            }
            this.model.playerVelocityY += config.RUNNER.gravity * deltaMult;
            if (this.model.playerVelocityY > config.RUNNER.maxFallSpeed) {
                this.model.playerVelocityY = config.RUNNER.maxFallSpeed;
            }
            this.model.playerY += this.model.playerVelocityY * deltaMult;
            this.player.y = this.model.playerY;

            if (this.model.playerVelocityY >= 0) {
                this.model.playerState = PLAYER_STATES.FALLING;
            }
        } else
        if (this.model.playerState === PLAYER_STATES.FALLING) {
            this.model.playerVelocityY += config.RUNNER.gravity * deltaMult;
            if (this.model.playerVelocityY > config.RUNNER.maxFallSpeed) {
                this.model.playerVelocityY = config.RUNNER.maxFallSpeed;
            }
            const prevY = this.model.playerY;
            this.model.playerY += this.model.playerVelocityY * deltaMult;
            this.player.y = this.model.playerY;

            const potentialLandingPlatforms = GAME_CONTROLLER.findPotentialLandingPlatforms();
            for (let i = 0; i < potentialLandingPlatforms.length; i++) {
                const platform = potentialLandingPlatforms[i];
                const platformY = platform.y + platform.cachedBounds.y * platform.scale.y + (platform.config.collision_offset_top || 0) + config.RUNNER.collisionBottomOffset;
                if (prevY <= platformY && this.model.playerY >= platformY) {
                    this.model.playerY = platformY;
                    this.player.y = platformY;
                    this.model.playerState = PLAYER_STATES.RUNNING;
                    this.model.playerVelocityY = 0;
                    this.model.doubleJumped = false;
                    this.model.isBumped = false;
                    GAME_CONTROLLER.landed(platform, true);
                    break;
                }
            }

            if (config.USE_PERMANENT_GROUND && this.model.playerState === PLAYER_STATES.FALLING) {
                // Still falling - have we landed on our permanent ground?
                const groundY = config.VIEW_HEIGHT / config.DRAW_SCALE - (config.PERMANENT_GROUND_BOTTOM_OFFSET * config.TILE_HEIGHT) + config.PERMANENT_GROUND_PLATFORM_OFFET_TOP + config.RUNNER.collisionBottomOffset;
                if (this.player.y >= groundY) {
                    this.model.playerY = groundY;
                    this.player.y = groundY;
                    this.model.playerState = PLAYER_STATES.RUNNING;
                    this.model.playerVelocityY = 0;
                    this.model.doubleJumped = false;
                    this.model.isBumped = false;
                    GAME_CONTROLLER.landed(null, true);
                }
            }

            if (this.model.playerY > config.VIEW_HEIGHT / config.DRAW_SCALE + config.TILE_HEIGHT * 2) {
                GAME_CONTROLLER.died();
            }
        } else
        if (this.model.playerState === PLAYER_STATES.RUNNING) {
            const potentialLandingPlatforms = GAME_CONTROLLER.findPotentialLandingPlatforms();
            let landed = false;
            for (let i = 0; i < potentialLandingPlatforms.length; i++) {
                const platform = potentialLandingPlatforms[i];
                const platformY = platform.y + platform.cachedBounds.y + (platform.config.collision_offset_top || 0) + config.RUNNER.collisionBottomOffset;
                if (this.model.playerY === platformY || (this.model.playerY < platformY && this.model.playerY + this.model.playerVelocityY >= platformY)) {
                    landed = true;
                    GAME_CONTROLLER.landed(platform, false);
                }
            }
            if (config.USE_PERMANENT_GROUND && !landed) {
                // Have we landed on our permanent ground?
                const groundY = config.VIEW_HEIGHT / config.DRAW_SCALE - (config.PERMANENT_GROUND_BOTTOM_OFFSET * config.TILE_HEIGHT) + config.PERMANENT_GROUND_PLATFORM_OFFET_TOP + config.RUNNER.collisionBottomOffset;
                if (this.player.y >= groundY) {
                    landed = true;
                    GAME_CONTROLLER.landed(null, false);
                }
            }
            if (!landed) {
                // console.log('Fall off the edge!');
                this.model.playerState = PLAYER_STATES.FALLING;
                // GAME_CONTROLLER.stopUpdate();
            }
        }

        if (this.model.bonusCountdown > 0) {
            this.addBonusStars();
        }
    }

    jump = () => {
        if (!this.touchEnded || !GAME_CONTROLLER.vidStarted || GAME_CONTROLLER.vidBuffering) {
            return;
        }
        // console.log('Jump!');
        if (this.model.hasReachedEnd || (this.model.playerState === PLAYER_STATES.JUMPING && this.model.doubleJumped) || (this.model.playerState === PLAYER_STATES.FALLING && this.model.doubleJumped)) {
            return;
        }
        if ((this.model.playerState === PLAYER_STATES.JUMPING || this.model.playerState === PLAYER_STATES.FALLING) && this.model.doubleJumped === false) {
            this.model.doubleJumped = true;
            this.model.playerVelocityY = -config.RUNNER.doubleJumpSpeed;

            koko.audio.play(config.SOUNDS.DOUBLE_JUMP, 1, false, 0, true);

            // console.log('Double Jump!');
        } else {
            this.model.playerVelocityY = -config.RUNNER.jumpSpeed;

            koko.audio.play(config.SOUNDS.JUMP, 1, false, 0, true);
        }

        if (this.controlsBubble.visible && this.controlsBubble.alpha === 1) {
            gsap.to(this.controlsBubble, {alpha: 0, duration: 0.5, ease: 'power2.in', onComplete: () => {this.controlsBubble.visible = false;}});
        }
        this.model.playerState = PLAYER_STATES.JUMPING;

        this.lastJumpTime = Date.now();
    }

    bounceJump = () => {
        this.model.playerVelocityY = -config.RUNNER.bouncerJumpSpeed;
        koko.audio.play(config.SOUNDS.BOUNCE_JUMP, 1, false, 0, true);
        this.model.playerState = PLAYER_STATES.JUMPING;
    }

    hitObject = () => {
        this.model.doubleJumped = true;
        this.model.isBumped = true;
        this.model.playerVelocityY = -config.RUNNER.hitBumpUpSpeed;
        this.model.playerVelocityX = -config.RUNNER.hitBumpLeftSpeed;
        this.model.playerState = PLAYER_STATES.HIT;

        gsap.to(this.player, {alpha: 0, duration: 0.1, ease: 'power2.inOut', repeat: 7, yoyo:true});

        let goatsLost = Math.floor(this.model.itemsCollected * config.GOATS_PERC_LOAST_WHEN_HIT); // this.model.itemsCollected >= config.GOATS_LOST_WHEN_HIT ? config.GOATS_LOST_WHEN_HIT : this.model.itemsCollected;
        if (goatsLost < 3) goatsLost = 3;
        // if (goatsLost > config.MAX_GOATS_LOST_WHEN_HIT) goatsLost = config.MAX_GOATS_LOST_WHEN_HIT;
        if (goatsLost > this.model.itemsCollected) goatsLost = this.model.itemsCollected;
        this.model.itemsCollected -= goatsLost;
        this.showLostGoats(goatsLost < config.MAX_GOATS_SHOWN_WHEN_HIT ? goatsLost : config.MAX_GOATS_SHOWN_WHEN_HIT);
        v.get('ingameHUD').update();

        koko.audio.play(config.SOUNDS.COLLIDE, 1, false, 0, true);
    }

    addJumpStars = () => {
        const starsToAdd = this.model.playerVelocityY <= -config.RUNNER.addJump3StarsMinJumpSpeed ? 3 : this.model.playerVelocityY <= -config.RUNNER.addJump2StarsMinJumpSpeed ? 2 : 1;
        for (let i = 0; i < starsToAdd; i++) {
            GAME_CONTROLLER.addVfx(config.VFX.JUMP_EFFECT, this.model.playerX, this.model.playerY, this.player.parent, 'JUMP_EFFECT_STAR', starsToAdd === 3 ? 1 : starsToAdd === 2 ? 0.85 : 0.55);
        }
    }

    addBonusStars = () => {
        const starsToAdd = this.model.bonusCountdown >= 3 ? 2 : this.model.bonusCountdown > 1 ? 1 : 1;
        for (let i = 0; i < starsToAdd; i++) {
            GAME_CONTROLLER.addVfx(config.VFX.BONUS_EFFECT, this.model.playerX + 30, this.model.playerY - Math.random() * this.player.height, this.player.parent, 'BONUS_EFFECT_STAR', this.model.bonusCountdown >= 3 ? 0.75 : this.model.bonusCountdown > 1 ? 0.6 : 0.4);
        }
    }

    showLostGoats = (count) => {
        /* let dist = Math.random() * 10;
        let dir = Math.random() >= 0.5 ? 1 : -1;
        for (let i = 0; i < count; i++) {
            let vec = {x: dist * dir, y: -Math.random() * 30 - 60};
            GAME_CONTROLLER.addVfx(config.VFX.GOAT_EFFECT, this.model.playerX + vec.x, (this.model.playerY - this.player.height / 2) + vec.y, this.player.parent, 'GOAT_EFFECT_STAR', 1, false, true, vec, 1 + Math.random(), true);
            dist += 20 + Math.random() * 10;
            dir *= -1;
        } */

        let ang = Math.random() * Math.PI;
        let angIncrease = count > 1 ? Math.PI * 2 / count : 0;
        for (let i = 0; i < count; i++) {
            let dist = 300; // 220 + Math.floor(Math.random() * 80);
            let vec = {x: Math.cos(ang) * dist, y: Math.sin(ang) * dist};
            GAME_CONTROLLER.addVfx(config.VFX.GOAT_EFFECT, this.model.playerX + vec.x, (this.model.playerY - this.player.height / 2) + vec.y, this.player.parent, 'GOAT_EFFECT_STAR', 1, false, true, vec, 1.5, false, true);
            ang += angIncrease;
        }
    }
}