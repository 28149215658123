// Defaults to en (if it can'tr find the l;anguage specified).
// You can include <br /> but you will need to do dangerously_set_inner_html in React.
// Otherwise you can set your own token and handle it in your own way.
//
// Also note everything is double quoted to make it easier to switch between JSON and JS.
export const LANGS = [
    {
        id: 'en',
        label: 'EN'
    },
    {
        id: 'fr',
        label: 'FR'
    },
    {
        id: 'de',
        label: 'DE'
    },
    {
        id: 'it',
        label: 'IT'
    },
    {
        id: 'mx',
        label: 'MX'
    },
    {
        id: 'es',
        label: 'ES'
    },
    {
        id: 'br',
        label: 'BR'
    },
    {
        id: 'jp',
        label: 'JP'
    },
    {
        id: 'tw',
        label: 'TW'
    },
    {
        id: 'au',
        label: 'ANZ'
    },
];

export const GetLangIndex = (lang) => {
    for (let i = 0; i < LANGS.length; i++) {
        if (LANGS[i].id === lang) {
            return i;
        }
    }
    return 0;
}

export const texts = {
    "play_as_individual_title": {
        "en": "Play as an individual and beat your high score!",
        "fr": "Jouez en solo et battez votre meilleur score !",
        "de": "Spiele als Einzelperson und verbessere deinen Highscore!",
        "it": "Gioca individualmente e batti il tuo punteggio più alto!",
        "mx": "¡Juega como usuario y supera tu récord!",
        "es": "¡Juega como usuario y bate tu propio récord!",
        "br": "Jogue sozinho e supere seu record",
        "jp": "シングルプレイで、自己最高記録を目指そう！",
        "tw": "以個別玩家的身份遊玩並挑戰自己的最高分紀錄！",
    },
    "play_as_individual_button": {
        "en": "Play as individual",
        "fr": "Jouer en solo",
        "de": "Spiele als Einzelperson",
        "it": "Gioca individualmente",
        "mx": "Jugar como usuario",
        "es": "Jugar como usuario",
        "br": "Jogar sozinho",
        "jp": "シングルプレイ",
        "tw": "以個別玩家的身份遊玩",
    },
    "play_as_streamer_title": {
        "en": "Stream your game and let chat get involved!",
        "fr": "Streamez votre partie et faites participer le chat !",
        "de": "Streame dein Spiel und beziehe deinen Kanal mit ein!",
        "it": "Trasmetti in streaming la tua partita e coinvolgi la chat!",
        "mx": "¡Haz un stream de tu partida y activa el chat!",
        "es": "¡Streamea tu partida y que ruja el chat!",
        "br": "Transmita seu jogo e deixe o chat participar!",
        "jp": "ゲームを配信して、チャットユーザーにも参加してもらおう！",
        "tw": "開實況遊玩，讓聊天室也來參一腳！",
    },
    "play_as_streamer_button": {
        "en": "Play as streamer",
        "fr": "Jouer en tant que streamer·euse",
        "de": "Spiele als Streamer",
        "it": "Gioca come streamer",
        "mx": "Jugar como streamer",
        "es": "Jugar como streamer",
        "br": "Jogar como streamer",
        "jp": "ストリーマーとしてプレイ",
        "tw": "以實況主的身份遊玩",
    },
    "logout_button": {
        "en": "Logout",
        "fr": "Se déconnecter",
        "de": "Ausloggen",
        "it": "Disconnettersi",
        "mx": "Cerrar sesión",
        "es": "Cerrar sesión",
        "br": "Sair",
        "jp": "ログアウト",
        "tw": "登出",
    },
    "logo_tag_line": {
        "en": "Recap Game",
        "fr": "Jeu de la Rétrospective",
        "de": "Recap-Spiel",
        "it": "Gioco del Riepilogo",
        "mx": "Partida de Recap",
        "es": "Partida de resumen",
        "br": "Jogo da retrospectiva",
        "jp": "Recapゲーム",
        "tw": "回顧小遊戲",
    },
    "revive_message_individual": {
        "en": "KEEP TAPPING TO REVIVE DINO<br />AND CONTINUE YOUR GAME",
        "fr": "CONTINUEZ À APPUYER POUR REVIVRE DINO<br />ET CONTINUER VOTRE JEU",
        "de": "HALTE DINO AM LEBEN<br />UND SPIELE WEITER",
        "it": "CONTINUA A TOCCARE PER FAR RESUSCITARE DINO<br />E CONTINUA IL TUO GIOCO",
        "mx": "SIGUE TAP PARA REVIVIR A DINO<br />Y CONTINUAR TU JUEGO",
        "es": "SIGUE TAP PARA REVIVIR A DINO<br />Y CONTINUAR TU JUEGO",
        "br": "CONTINUE TAP PARA REVIVER DINO<br />E CONTINUAR SEU JOGO",
        "jp": "タップし続けてダイノを復活させ<br />ゲームを続けよう",
        "tw": "繼續點擊復活恐龍<br />繼續遊戲",
    },
    /* revive_title_streamer: {
        en: '!REVIVE'
    },
    revive_message_streamer: {
        en: 'ENTER !REVIVE IN CHAT TO REvIVE<br />DINO AND THE STREAMER YOU ARE WATCHING<br />CAN CONTINUE THEIR GAME'
    }, */
    "help_screen_1_title": {
        "en": "Race through 2023",
        "fr": "En route vers 2023",
        "de": "Rase durch 2023",
        "it": "Ripercorri il 2023",
        "mx": "Recorrido del 2023",
        "es": "Recorre 2023",
        "br": "Chegue ao fim de 2023",
        "jp": "2023年を駆け抜けよう",
        "tw": "奔跑吧，2023！",
    },
    "help_screen_1_message": {
        "en": "Help Dino jump through our year together on Twitch.",
        "fr": "Aidez Dino à traverser l’année que nous avons passée ensemble sur Twitch.",
        "de": "Hilf Dino dabei, gemeinsam auf Twitch durch unser Jahr zu springen.",
        "it": "Aiuta Dino a ripercorrere il nostro anno insieme su Twitch.",
        "mx": "Repasa con Dino el año que vivimos juntos en Twitch.",
        "es": "Recorre con Dino el año que hemos vivimos en Twitch.",
        "br": "Ajude Dino a chegar até o fim do ano na Twitch.",
        "jp": "ディノをジャンプさせて、Twitchで一緒に過ごしたこの1年をクリアしよう。",
        "tw": "快幫助 Dino 跨越我們一起在 Twitch 經歷的這一年吧。",
    },
    "help_screen_2_title": {
        "en": "Grab goats!",
        "fr": "Attrapez les chèvres !",
        "de": "Fange die Ziegen!",
        "it": "Dai la caccia alle capre!",
        "mx": "¡Reúne cabras!",
        "es": "¡Recolecta cabras!",
        "br": "Pegue as cabras!",
        "jp": "ヤギを捕まえよう！",
        "tw": "快抓住山羊！",
    },
    "help_screen_2_message": {
        "en": "Collecting goats and bonuses is the name of the game. The more goats collected the better your score!",
        "fr": "Le but du jeu est de récupérer des chèvres et des bonus. Plus vous récupérez de chèvres, meilleur sera votre score !",
        "de": "Ziegen und Boni sammeln: So geht's! Je mehr Ziegen du einsammelst, desto besser ist dein Score!",
        "it": "Acchiappare capre e bonus è lo scopo del gioco. Più capre si acchiappano, più punti si ottengono!",
        "mx": "El objetivo del juego es conseguir cabras y bonificaciones. ¡Cuantas más cabras atrapes, mayor será tu puntaje!",
        "es": "Recoge las cabras y bonificaciones que se crucen en tu camino. Cuantas más cabras atrape Dino, ¡más puntos te llevarás!",
        "br": "Pegar as cabras e os bônus é o que interessa. Quanto mais cabras forem coletadas, maior será a sua pontuação!",
        "jp": "ディノがヤギを沢山捕まえれば、高スコアをゲットできます！",
        "tw": "本遊戲的目標就是帶 Dino 收集山羊和加分道具。收集越多山羊，分數越高！",
    },
    "help_screen_3_title": {
        "en": "Look out, Dino!",
        "fr": "Attention, Dino !",
        "de": "Pass auf Dino!",
        "it": "Attenzione, Dino!",
        "mx": "¡Cuidado, Dino!",
        "es": "¡Cuidado, Dino!",
        "br": "Cuidado, Dino!",
        "jp": "気をつけて、ディノ！",
        "tw": "小心啊 Dino！",
    },
    "help_screen_3_message": {
        "en": "Hitting obstacles and hazards as you run will slow you down and lower your score -noooooo!",
        "fr": "Les obstacles et les dangers que vous rencontrez vous ralentissent et font baisser votre score !",
        "de": "Wenn du auf deinem Weg Hindernisse und Herausforderungen meistern musst, dann wirst du langsamer und dein Score sinkt – neeein!",
        "it": "Se mentre corri colpisci gli ostacoli e cadi nei trabocchetti, perderai tempo e punti... Noooooo!",
        "mx": "Los distintos obstáculos te ralentizarán y te quitarán puntaje. ¡Nooo!",
        "es": "Esquiva los obstáculos y peligros si no quieres ir más despacio y perder puntos. ¡Venga, que tú puedes!",
        "br": "Bater nos obstáculos e perigos desacelera você e baixa sua pontuação. Ah, não!",
        "jp": "走っている時に障害物や危険物に触ると、スピードが落ちてスコアも下がってしまいます！",
        "tw": "撞到障礙物和危險物件將會減慢 Dino 的奔跑速度，分數也會跟著變低⋯⋯那樣就完蛋啦！！！",
    },
    "help_screen_4_title": {
        "en": "Keep on running!",
        "fr": "N’arrêtez pas de courir !",
        "de": "Lauf weiter!",
        "it": "Continua a correre!",
        "mx": "¡No pares, sigue, sigue!",
        "es": "¡No pares, sigue, sigue!",
        "br": "Corra sem parar!",
        "jp": "走り続けよう！",
        "tw": "跑跑跑，向前跑！",
    },
    "help_screen_4_message": {
        "en": "Fall? Fear not! Run along the lower platform. But dont stay too long, no points for Dino there!",
        "fr": "Une chute ? Pas de panique ! Courez sur la plateforme inférieure. Attention à ne pas vous y attarder : Dino n’y gagne pas de points !",
        "de": "Runtergefallen? Keine Angst! Laufe auf der unteren Plattform. Aber bleib nicht zu lange, hier kann Dino keine Punkte sammeln!"
,
        "it": "E se cadi? Niente paura! Corri nella parte inferiore della zona di gioco. Ma non restarci troppo: lì non ci sono punti per Dino!",
        "mx": "¿Y si me caigo? ¡No pasa nada! Sigue corriendo por la plataforma inferior. Pero sube en cuanto puedas, ¡abajo no hay puntos para Dino!",
        "es": "Si te caes, mantén la calma y sigue por la plataforma de abajo. Pero sube rápido, ¡que ahí no le dan puntos a Dino!",
        "br": "Caiu? Sem problema! Corra na plataforma inferior. Só não fique ali por muito tempo, porque o Dino não ganha pontos lá!",
        "jp": "転んじゃうかも？心配は無用です！そんな時は画面の下部を走らせましょう。でもディノが獲得できるポイントは下部には無いので、ずっとそこで走るのはお勧めしません！",
        "tw": "你也可以叫 Dino 改跑在高度較低的平台就行了。但 Dino 是不會想屈就於這種沒難度的平台，因為啊，跑在這上面是拿不到分數的！",
    },

    "main_menu_footer_privacy": {
        "en": "Privacy Notice",
        "fr": "Avis de confidentialité",
        "de": "Datenschutzerklärung",
        "it": "Informativa sulla privacy",
        "mx": "Aviso de privacidad",
        "es": "Aviso de privacidad",
        "br": "Aviso de privacidade",
        "jp": "プライバシーに関する通知",
        "tw": "隱私權聲明",
    },
    "main_menu_footer_terms": {
        "en": "Terms of Service",
        "fr": "Conditions d'utilisation",
        "de": "Nutzungsbedingungen",
        "it": "Termini di servizio",
        "mx": "Términos de servicio",
        "es": "Términos de servicio",
        "br": "Termos de serviço",
        "jp": "利用規約",
        "tw": "服務條款",
    },
    "main_menu_footer_accessibility": {
        "en": "Accessibility Statement",
        "fr": "Déclaration d'accessibilité",
        "de": "Erklärung zur Barrierefreiheit",
        "it": "Dichiarazione di accessibilità",
        "mx": "Declaración de accesibilidad",
        "es": "Declaración de accesibilidad",
        "br": "Declaração de acessibilidade",
        "jp": "アクセシビリティに関する声明",
        "tw": "無障礙聲明",
    },
    "main_menu_copyright": {
        "en": "© 2023 Twitch Interactive, Inc. All Rights Reserved.",
        "fr": "© 2023 Twitch Interactive, Inc. Tous droits réservés.",
        "de": "© 2023 Twitch Interactive, Inc. Alle Rechte vorbehalten.",
        "it": "© 2019 Twitch Interactive, Inc. Tutti i diritti riservati.",
        "mx": "© 2023 Twitch Interactive, Inc. Todos los derechos reservados.",
        "es": "© 2023 Twitch Interactive, Inc. Todos los derechos reservados.",
        "br": "© 2023 Twitch Interactive, Inc. Todos os direitos reservados.",
        "jp": "© 2023 Twitch Interactive, Inc. All Rights Reserved.",
        "tw": "© 2023 Twitch Interactive, Inc. 著作權所有，並保留一切權利。",
    },

    "loading": {
        "en": "2023 Loading...",
        "fr": "2023 en cours de chargement...",
        "de": "2023 wird geladen ...",
        "it": "2023 in caricamento...",
        "mx": "Cargando 2023...",
        "es": "2023, cargando…",
        "br": "Carregando 2023...",
        "jp": "2023年を読み込み中...",
        "tw": "2023 年載入中⋯⋯",
    },
    "playCountTitle": {
        "en": "Global games played:",
        "fr": "Nombre total de parties :",
        "de": "Insgesamt gespielte Spiele:",
        "it": "Partite totali giocate:",
        "mx": "Partidas jugadas en todo el mundo:",
        "es": "Partidas mundiales jugadas:",
        "br": "Jogos disputados pelo mundo:",
        "jp": "世界全体でのRecapゲームプレイ回数:",
        "tw": "全球各地玩家的總遊玩次數：",
    },

    "game_over_title": {
        "en": "Way to go Dino!<br />Recap run complete.",
        "fr": "Bien joué, Dino !<br />Rétrospective terminée.",
        "de": "Super, Dino!<br />Du hast das Recap-Spiel abgeschlossen.",
        "it": "Ben fatto, Dino!<br />Hai completato il Riepilogo.",
        "mx": "¡Así se hace, Dino!<br />Partida de Recap completa.",
        "es": "¡Ole ahí, Dino!<br />Partida de resumen completada.",
        "br": "Boa, Dino!<br />Retrospectiva concluída.",
        "jp": "おめでとう！<br />Recapゲームをクリアしました",
        "tw": "太神啦，Dino！回顧小遊戲成功過關。",
    },
    "game_over_message_1": {
        "en": "Your Year,",
        "fr": "Votre année,",
        "de": "Dein Jahr,",
        "it": "Il tuo anno,",
        "mx": "Tu año,",
        "es": "Tu año,",
        "br": "Seu ano,",
        "jp": "あなたの年、",
        "tw": "你的一年，",
    },
    "game_over_message_2": {
        "en": "Well Played",
        "fr": "Bien joué",
        "de": "Gut gespielt",
        "it": "Ben giocato",
        "mx": "Bien jugado",
        "es": "Bien jugado",
        "br": "Bem jogado",
        "jp": "よくやった",
        "tw": "玩得好",
    },
    "global_leaderboard_tab_title": {
        "en": "Global",
        "fr": "Monde",
        "de": "Alle",
        "it": "Globale",
        "mx": "Global",
        "es": "Global",
        "br": "Global",
        "jp": "グローバル",
        "tw": "全球",
    },
    "global_leaderboard_tab_subtitle": {
        "en": "Top 50 / 24hrs",
        "fr": "Top 50 / 24 heures",
        "de": "Top 50/24 Std.",
        "it": "Top 50 / 24 ore",
        "mx": "Mejores 50/24 h",
        "es": "50 mejores/24 h",
        "br": "Top 50/24h",
        "jp": "トップ50 / 24時間",
        "tw": "前 50 名 / 24 小時",
    },
    "streamer_leaderboard_tab_title": {
        "en": "Streamer",
        "fr": "Streamer·euse",
        "de": "Streamer",
        "it": "Streamer",
        "mx": "Streamer",
        "es": "Streamer",
        "br": "Streamer",
        "jp": "ストリーマー",
        "tw": "實況主",
    },
    "streamer_leaderboard_tab_subtitle": {
        "en": "Top 50 / 24hrs",
        "fr": "Top 50 / 24 heures",
        "de": "Top 50/24 Std.",
        "it": "Top 50 / 24 ore",
        "mx": "Mejores 50/24 h",
        "es": "50 mejores/24 h",
        "br": "Top 50/24h",
        "jp": "トップ50 / 24時間",
        "tw": "前 50 名 / 24 小時",
    },
    "goats_leaderboard_tab_title": {
        "en": "Top Dinos",
        "fr": "Top Dinos",
        "de": "Top Dinos",
        "it": "I migliori Dino",
        "mx": "Los mejores Dino",
        "es": "Mejores Dinos",
        "br": "Melhores Dinos",
        "jp": "上位のディノ",
        "tw": "頂尖 Dino",
    },
    "goats_leaderboard_tab_subtitle": {
        "en": "Top 20 / All Time",
        "fr": "Top 20 / Tous les temps",
        "de": "Top 20/insg.",
        "it": "Top 20 / Di sempre",
        "mx": "Mejores 20/General",
        "es": "20 mejores/Siempre",
        "br": "Top 20/Geral",
        "jp": "トップ20 / 全期間",
        "tw": "前 20 名 / 歷來",
    },
    "chat_progress_message": {
        "en": "Get chat to use :GoatEmotey: to boost your score!",
        "fr": "Faites utiliser :GoatEmotey : par le chat afin d'augmenter votre score !",
        "de": "Bring die Chat-Teilnehmer:innen dazu, :GoatEmotey: zu nutzen, um deine Punktzahl in die Höhe zu treiben!",
        "it": "Spingi la chat a usare :GoatEmotey: per aumentare il tuo punteggio!",
        "mx": "¡Haz que el chat use :GoatEmotey: para aumentar tu puntaje!", 
        "es": "¡Consigue el chat para usar :GoatEmotey: y aumentar tu puntuación!",
        "br": "Peça para o chat usar :GoatEmotey: e aumente sua pontuação!",
        "jp": "チャットに「:GoatEmotey:」と入力してブーストしよう！",
        "tw": "使用：GoatEmotey來推升你的實況主",
    },
     "game_complete_message": {
        "en": "Shout about your Recap Game Run!",
        "fr": "Partagez votre score au jeu de la Rétrospective !",
        "de": "Informiere andere über deinen Recap-Game-Run!",
        "it": "Fai sapere a tutti della tua performance nel gioco del Riepilogo!",
        "mx": "Cuéntale al mundo de tu partida de Recap.", 
        "es": "¡Presume de tu partidaza de resumen!",
        "br": "Mostre como você se saiu no jogo da retrospectiva!",
        "jp": "Recapゲームについて、みんなに知らせましょう！",
        "tw": "快去跟大家炫耀你回顧小遊戲的得分結果吧！",
    },
    "game_over_title_2": {
        "en": "Click <a href='https://twitch-web.app.link/e/U75gLOnElFb' target='_blank'>here</a> to get your 2023 Twitch Recap! ",
        "fr": "Cliquez <a href='https://twitch-web.app.link/e/U75gLOnElFb' target='_blank'>ici</a> pour accéder à votre Rétrospective Twitch 2023 !",
        "de": "<a href='https://twitch-web.app.link/e/U75gLOnElFb' target='_blank'>Hier klicken</a> für deinen Twitch Recap 2023!",
        "it": "Clicca <a href='https://twitch-web.app.link/e/U75gLOnElFb' target='_blank'>qui</a> per visualizzare il tuo Riepilogo di Twitch 2023!",
        "mx": "¡Haz clic <a href='https://twitch-web.app.link/e/U75gLOnElFb' target='_blank'>aquí</a> para ver tu Twitch Recap del 2023!",
        "es": "¡Consigue tu <a href='https://twitch-web.app.link/e/U75gLOnElFb' target='_blank'>resumen de Twitch del 2023!</a> ",
        "br": "Clique  <a href='https://twitch-web.app.link/e/U75gLOnElFb' target='_blank'>aqui</a> para ver sua Retrospectiva Twitch de 2023!",
        "jp": "2023年のTwitch Recapを入手するには、<a href='https://twitch-web.app.link/e/U75gLOnElFb' target='_blank'>こちら</a>をクリック！",
        "tw": "請點擊<a href='https://twitch-web.app.link/e/U75gLOnElFb' target='_blank'>這裡</a>以取得您 2023 年度的 Twitch 回顧！",
    },
     "share_button_text": {
        "en": "Share",
        "fr": "Partager",
        "de": "Teilen",
        "it": "Condividi",
        "mx": "Compartir", 
        "es": "Compartir",
        "br": "Compart.",
        "jp": "シェア",
        "tw": "分享",
    },
    "share_game_copy": {
        "en": "I just played the 2023 Twitch Recap Game. Play now and try to beat my score! https://www.twitchrecapgame.com",
        "fr": "Je viens de jouer au jeu de la Rétrospective Twitch de 2023. Jouez maintenant et essayez de battre mon score ! https://www.twitchrecapgame.com",
        "de": "Ich habe gerade das Twitch Recap Game 2023 gespielt. Spiele es jetzt und versuche, meine Punktzahl zu schlagen! https://www.twitchrecapgame.com",
        "it": "Ho appena giocato al gioco del Riepilogo di Twitch 2023. Gioca subito e prova a superare il mio punteggio! https://www.twitchrecapgame.com",
        "mx": "Acabo de jugar al juego de Recap de Twitch de 2023. ¡Intenta superar mi récord! https://www.twitchrecapgame.com", 
        "es": "Acabo de completar el juego del resumen de Twitch 2023. ¡Prueba a jugar ahora e intenta superar mi puntuación! https://www.twitchrecapgame.com",
        "br": "Acabei de jogar o Jogo da Retrospectiva Twitch 2023. Jogue agora e tente superar minha pontuação!  https://www.twitchrecapgame.com",
        "jp": "2023 Twitch Recapゲームをプレイしたよ。今すぐプレイして、私のスコアに挑戦してみて！https://www.twitchrecapgame.com",
        "tw": "我剛玩了 2023 Twitch 回顧小遊戲。快來打破我的得分紀錄吧！https://www.twitchrecapgame.com",
        "anz": "I just played the 2023 Twitch Recap Game. Play now and try to beat my score! https://www.twitchrecapgame.com",
    },
    
    "game_over_credits_title": {
        "en": "Credits",
        "fr": "Crédits",
        "de": "Danksagungen",
        "it": "Riconoscimenti",
        "mx": "Créditos", 
        "es": "Créditos",
        "br": "Créditos",
        "jp": "クレジット",
        "tw": "製作團隊",
    },
    "rotate_device_message_1": {
        "en": "Please rotate device",
        "fr": "Faites pivoter l'appareil",
        "de": "Drehe dein Gerät",
        "it": "Ruota il dispositivo",
        "mx": "Rota el dispositivo",
        "es": "Gira el dispositivo",
        "br": "Gire o dispositivo",
        "jp": "デバイスを回転してください",
        "tw": "請旋轉裝置",
    },
    "rotate_device_message_2": {
        "en": "Ensure your device's rotation is not locked",
        "fr": "Vérifiez que la rotation de l'appareil n'est pas verrouillée",
        "de": "Vergewissere dich, dass die automatische Bildschirmdrehung nicht deaktiviert ist",
        "it": "Assicurati che la rotazione del dispositivo non sia bloccata",
        "mx": "Asegúrate de no tener la rotación de pantalla bloqueada",
        "es": "Asegúrate de que la rotación del dispositivo no esté bloqueada",
        "br": "Certifique-se de que a rotação do seu dispositivo não esteja lacrada",
        "jp": "必ずデバイスの画面の向きのロックを解除してください",
        "tw": "請確認裝置並未鎖定旋轉功能",
    },
}

export const CREDITS = [
    "ExtraEmily",
    "CDawgVA",
    "Tubbo",
    "Ninjayla",
    "AriGameplays",
    "Queen_Giorgia",
    "HasanAbi",
    "littlesiha",
    "Smirky",
    "savannahlow",
    "Cardboard_Cowboy",
    "IlloJuan",
    "fanfan",
    "aria_addams",
    "emme",
    "KaiCenat",
    "curtoss",
    "ToonGaming",
    "ZeratoR",
    "MadameDiablo",
    "lilsimsie",
    "itsonlyVEGA",
    "BiqtchPuddin",
    "PalmBee",
    "MissMikkaa",
    "puioff",
    "zefrine",
    "nacho_dayo",
    "NotYourParentsGnS",
    "Khaykashi",
    "TheHungerService",
    "lara6683",
    "IFCYipeS",
    "MissyAlcazarMusic",
    "ItGetsBetter",
    "KDotDaGawd", 
    "yeetitsnikki",
    "POW3R",
    "ibai",
    "ohlindsey",
    "Springsims",
    "willneff",
    "lossayyy",
    "OneGoesDown",
    "BarefootTasha",
    "ARIatHOME",
    "ironmouse",
    "Jinnytty",
    "Repullze",
    "iiTzTimmy",
    "NickEh30",
    "PureColdBlooded",
    "Kaycem",
    "XingKazma",
    "meliajanae",
    "Gaules",
    "Baiano",
    "JonVlogs",
    "alanzoka",
    "cidcidoso",
    "Bagi",
    "belrodrigues",
    "StudyTme",
    "DarioMocciaTwitch",
    "Moonryde",
    "Kurolily",
    "Kroatomist",
    "Babbalucy",
    "GOA7League",
    "Solivann",
    "Dylan_trekking",
    "Ultia",
    "Hortyunderscore",
    "Etoiles",
    "Lu_K",
    "Squeezie",
    "ronnyberger",
    "fisHC0p",
    "Papaplatte",
    "PietSmiet",
    "NoWay4u_Sir",
    "HoneyPuu",
    "wudijo",
    "eliasn97",
    "llunaclark",
    "JorgeIsaac115",
    "ElSpreen",
    "elded",
    "RobertoCein",
    "PattyMeza",
    "alextely",
    "rivers_gg",
    "alexelcapo",
    "BelenJurado",
    "Loserfruit",
    "broxh_",
    "TRASH",
    "Carla",
    "HAYZEE",
    "robcdee",
    "Cuzb0t",
    "raderaderader",
    "vodkavdk",
    "HiNas3",
    "fps_shaka",
    "yuyuta0702",
    "sanninshow_3ns",
    "wen620",
    "iwab_holiday",
    "tsukilin",
    "shuteye_orange",
    "wenlobong",
    "crazyfacetw",
];

