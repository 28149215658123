import { Link } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { submitScoreRoute, getLeaderboardRoute, getScorePosition } from "../../api/routes";
import axios from "../../api/axios";
import "./KokoDebugControls.scss";

const LEADERBOARD_TIMEFRAME = 86400;
//604800 = 1 Week
//86400 = 24 Hours
//3600 = 1 Hour

const NUMBER_OF_SCORES = 20;
const REQUEST_SCORE_HISTROY = true;
const REQUEST_SCORE_POSITION = true;

function Home() {
    const [scoreSubmitData, setScoreSubmitData] = useState({})
    const submitRandomScore = (e) =>
    {

        let randomScore = Math.ceil(Math.random() * 20000);
        let isStreamer = Math.random() > .5 ? true : false;
        e.preventDefault();
        let submitData = {
            name:'TEST NAME '+Math.ceil(Math.random() * 10),
            score: randomScore,
            isStreamer:isStreamer,
            requestHistory:REQUEST_SCORE_HISTROY,
            requestPosition:REQUEST_SCORE_POSITION,
            timePeriod:LEADERBOARD_TIMEFRAME,
            withCredentials:true           
        }
        axios
        .post(submitScoreRoute, submitData)
        .then(function (response) {
            console.log(response.data)
            setScoreSubmitData({name:submitData.name,
            score:submitData.score,
            playerPosition:response.data.position,
            isBestTimeScore:response.data.isBestTimeScore,
            isAllTimeBestScore:response.data.isBestAllTimeScore,
            playCount:response.data.playerData.playCount
            })
        })
        .catch(function (error) {
            alert('Something Went Wrong')
        });
    }
    const [streamerType, setStreamerType] = useState([])
    const [leaderboardData, setLeaderboardData] = useState([])
    const getLeaderboard = (e, isStreamer) =>
    {
       
        axios
        .get(getLeaderboardRoute, {
            params: { isStreamer: isStreamer, limitAmount:NUMBER_OF_SCORES, timePeriod:LEADERBOARD_TIMEFRAME },
            withCredentials:true
        })
        .then((response) => {
            console.log('USERS LOADED', response.data.leaderboardData)
            setStreamerType(isStreamer ? 'STREAMER' : 'INDIVIDUAL');
            setLeaderboardData(response.data.leaderboardData);       
        })
        .catch((error) => {
          //
          //toast.error(error.response.data.msg);
        });
    }

    const getRandomScorePosition = (e) =>
    {
        let randomScore = Math.ceil(Math.random() * 20000);
        let isStreamer = Math.random() > .5 ? true : false;
        axios
        .get(getScorePosition, {
            params: { isStreamer: isStreamer, score:randomScore, timePeriod:LEADERBOARD_TIMEFRAME },
            withCredentials:true
        })
        .then((response) => {
            alert(randomScore
            +' is in position '
            +response.data.position
            +' on the '
            +(isStreamer ? 'streamer' : 'individual')
            + ' leaderboard over the last '
            +secondsToHms(LEADERBOARD_TIMEFRAME ))
           
        })
        .catch((error) => {
          //
          //toast.error(error.response.data.msg);
        });
    }
    
    const secondsToHms = (d)  => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
    
        var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        return hDisplay + mDisplay + sDisplay; 
    }

  return (
    <>
    {/*
        <div>
        <h1 className="mt-4 mb-2">Koko Debug Tools</h1>
        <p><a href='#' onClick={(e) => { submitRandomScore(e) }} >SUBMIT RANDOM SCORE</a></p>
        <p>Score Submitted: {scoreSubmitData.name} | {scoreSubmitData.score} | Position: {scoreSubmitData.playerPosition}, Play Count: {scoreSubmitData.playCount}, Is Reccent Best Score: {String(scoreSubmitData.isBestTimeScore).toUpperCase()} Is All Time Best Score: {String(scoreSubmitData.isAllTimeBestScore).toUpperCase()}</p>
        <p><a href='#' onClick={(e) => { getLeaderboard(e, true) }} >GET STREAMER LEADERBOARD</a></p>
        <p><a href='#' onClick={(e) => { getLeaderboard(e, false) }} >GET INDIVIDUAL LEADERBOARD</a></p>
        <p><a href='#' onClick={(e) => { getRandomScorePosition(e) }} >GET RANDOM SCORE POS</a></p>
    <div className="container">
        <div className="leaderboard">
            <div className="head">
                <i class="fas fa-crown"></i>
                <h1>{streamerType} LEADERBOARD</h1>
            </div>
            <div  className="body">
                <ol>
                    {leaderboardData.map(
                        (val, i) => {
                            return (
                            <li>
                                <mark> {val.name}</mark>
                                <small>{val.score}</small>
                                
                            </li> 
                        )}
                    )}
                </ol>
            </div>
        </div>
    </div>
                            </div>*/}
    </>
   
  );
}

export default Home;
