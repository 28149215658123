import koko from './koko'

export const d = koko.display;
export const v = koko.views;
export const m = koko.math;
export const c = koko.client;
export const a = koko.assets;
export const au = koko.audio;
export const co = koko.cookies;
export const ph = koko.physics;
export const op = koko.objectPooler;
export const fb = koko.social.fb;
export const tw = koko.social.tw;
export const lin = koko.social.lin;
export const tl = koko.textLocaliser;
// export const f = koko.form;
// export const an = koko.analytics || null;