import React from "react";
import ReactFullScreenView from "../koko-framework/ui/react-views/ReactFullScreenView";
import "./ReviveView.scss";

import { assets } from "../assets/assets";
import { config } from "../randomised-runner/model/randomised-runner-config";
import { GAME_CONTROLLER } from "../randomised-runner/controller/randomised-runner-game-controller";
import textLocaliser from "../koko-framework/ui/TextLocaliser";

export default class ReviveView extends ReactFullScreenView {
    reviving = false;

    componentDidMount(props) {
        super.componentDidMount(props);

        // There is some whitespace around our heart graphic, so I'm starting a bit higher than 0% and ending earlier (90%)
        this.setState({
            revivePerc: 8, 
        });
        this.reviving = true;

        document.body.addEventListener('click', this.progressRevive);
    }

    componentWillUnmount() {
        super.componentWillUnmount();

        document.body.removeEventListener('click', this.progressRevive);
    }

    progressRevive = () => {
        if (this.state.revivePerc < 100 && this.reviving) {
            let newRevivePerc = this.state.revivePerc + 100 / config.CLICKS_TO_REVIVE < 100 ? this.state.revivePerc + 100 / config.CLICKS_TO_REVIVE : 100;
            this.setState({
                revivePerc: newRevivePerc,
            });
            
            if (newRevivePerc >= 90) {
                GAME_CONTROLLER.reStartGame();
                this.reviving = false;
            }
        }
    }

    renderContent() {
        return (
            <div>
                <div className="bg-grey-out"></div>
                <div className="revive-container">
                    <div className="heart-holder">
                        <div className="grey-heart" />
                        <div className="purple-heart" style={{height: `${this.state.revivePerc}%`}} />
                    </div>
                    <div className="revive-content-right">
                        <div className="grow"></div>
                        <img className="click-icon" src={assets.ClickMouseRevive} alt="" />
                        <p dangerouslySetInnerHTML={{__html: textLocaliser.getText('revive_message_individual')}}></p>
                        <div className="grow"></div>
                    </div>
                </div>
            </div>
        );
    }
}
