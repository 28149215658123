import { random } from "underscore";
import app from "../../koko-framework/app";
import koko from "../../koko-framework/koko";
import { ChunksByWorldAndDifficulty, ProcessedMapChunks } from "../../koko-framework/tile-engine/MapChunk";
import { ORTHAGONAL, TileMapEngine } from "../../koko-framework/tile-engine/TileMapEngine";
import { ProcessedTilesets } from "../../koko-framework/tile-engine/TileSet";
// We don't need to do any more with MAP_SETUP, importing it causes the map setup to run,
// map parts will be put in a global variable called ProcessedMapChunks
import { MAP_SETUP } from "../model/map-setup/map-setup";
import { GameModel } from "../model/randomised-runner-game-model";
import { config } from "../model/randomised-runner-config";
import * as PIXI from 'pixi.js'
import { EVENTS, GAME_CONTROLLER } from "./randomised-runner-game-controller";
import objectPooler from "../../koko-framework/objectPooler";
import { v } from "../../koko-framework/shorthand";
import gsap from "gsap";
import { GetLangIndex } from "../model/randomised-runner-text";
import { LANGUAGE_SETTINGS } from "../../model/config";
import { GAME_MODE } from "../../contexts/TwitchContext";
export class MapController {
    constructor(view) {
        this.view = view;
        this.mapEngine = new TileMapEngine(ProcessedTilesets, view, config.TILE_WIDTH, config.TILE_HEIGHT, ORTHAGONAL, [], [], this.addTileEffect);
        this.mapEngine.selectedLangIndex = GetLangIndex(LANGUAGE_SETTINGS.lang);
        this.isStreamer = GAME_MODE.isStreamer
    }

    addTileEffect = (tile) => {
        // console.log('Adding tile effect: ', tile);
        if (tile.config.object_id === 'main_collectable' && tile.config.value > 2) {
            // Add shine
            let shine = PIXI.Sprite.from('emote-collect.png');
            shine.anchor = {x: 0.5, y: 0.5};
            shine.x = tile.x + tile.width / 2;
            shine.y = tile.y - tile.height / 2;
            shine.scale.x = shine.scale.y = 1.5;
            this.view.children.layer_behind.addChild(shine);
            shine.tweens = [gsap.to(shine, {rotation: Math.PI * 2, duration: 3, repeat: -1, ease: 'none'})];
            tile.associatedEffect = shine;
            // console.log('Tile effect added: ', shine);
        }
    }

    resize() {
        this.visibleRect = new PIXI.Rectangle(0, 0, app.viewPort.width / config.DRAW_SCALE, app.viewPort.height / config.DRAW_SCALE);
    }

    init(model) {
        this.model = model;
        this.resize();

        this.hardForceChunks = [];
        for (let i = 0; i < config.HARD_FORCE_CHUNKS.length; i++) {
            this.hardForceChunks.push(config.HARD_FORCE_CHUNKS[i]);
        }

        if (config.USE_PERMANENT_GROUND) {
            this.permanentGroundSprite = PIXI.Sprite.from('main_ground.png');
            this.permanentGroundSprite.width = 2000 / config.DRAW_SCALE;
            this.permanentGroundSprite.x = -40 / config.DRAW_SCALE;
            this.permanentGroundSprite.y = config.VIEW_HEIGHT / config.DRAW_SCALE - (config.PERMANENT_GROUND_BOTTOM_OFFSET * config.TILE_HEIGHT);
            this.view.children.permanent_ground.addChild(this.permanentGroundSprite);
        }
    }

    destroyMap() {
        this.mapEngine.destroy();
        this.permanentGroundSprite.parent.removeChild(this.permanentGroundSprite);
        this.permanentGroundSprite.destroy();
    }

    chunkAddedCallback = (mapChunk) => {
        console.log('Chunk added: ', mapChunk);
        this.addParallax(mapChunk);

        if (this.isStreamer) {
            this.removeTilesOfType('start_bonus');
        }
    }

    addStartChunk(chunkX = 0, newChunkCallback = this.chunkAddedCallback) {
        var startChunks = [];
        console.log('Looking for start chunks in: ', ProcessedMapChunks, this.model);
        for (let prop in ProcessedMapChunks) {
            // console.log(ProcessedMapChunks[i]);
            if (ProcessedMapChunks[prop].world === 1/*this.model.currentWorld*/ && ProcessedMapChunks[prop].id.substr(0, 5).toLowerCase() == 'start') {
                startChunks.push(ProcessedMapChunks[prop]);
            }
        }
        if (startChunks.length > 0) {
            var randomStartChunk = startChunks[Math.floor(Math.random() * startChunks.length)];
            var yOffset = 0;
            console.log('adding start chunk: ', randomStartChunk);
            let aligned = false;
            for (var i = 0; i < randomStartChunk.specialTiles.length; i++) {
                if (randomStartChunk.specialTiles[i].config.specialType == 'align') {
                    yOffset -= this.mapEngine.renderer.tileHeight * randomStartChunk.specialTiles[i].gridY;
                    aligned = true;
                }
            }
            if (!aligned) {
                yOffset = this.randomiseChunkYOffset(randomStartChunk);
                const viewHeightTiles = Math.floor((config.VIEW_HEIGHT / config.TILE_HEIGHT) / config.DRAW_SCALE);
                if (yOffset < viewHeightTiles - config.MIN_PLATFORM_BOTTOM_OFFSET_TILES - randomStartChunk.height) {
                    yOffset = viewHeightTiles - config.MIN_PLATFORM_BOTTOM_OFFSET_TILES - randomStartChunk.height;
                }
                yOffset *= config.TILE_HEIGHT;
            }
            this.mapEngine.addMapChunk(randomStartChunk, chunkX, yOffset);

            if (typeof newChunkCallback == 'function') {
                newChunkCallback(this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1]);
            }
        }
    }

    removeOffLeftChunks() {
        var removed = 0;
        if (this.mapEngine.activeMapChunks.length == 0) return removed;
        if (this.mapEngine.activeMapChunks[0].bounds.x + this.mapEngine.activeMapChunks[0].bounds.width < this.model.worldX) {
            this.mapEngine.removeActiveChunk(0);
            // console.log('map chunk removed', this.mapEngine.activeMapChunks.length);
            removed++;
        }
        return removed;
    }

    removeOffRightChunks() {
        var removed = 0;
        if (this.mapEngine.activeMapChunks.length === 0) return removed;
        if (this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].bounds.x > -this.view.container.x / config.DRAW_SCALE + config.VIEW_WIDTH / config.DRAW_SCALE) {
            this.mapEngine.removeActiveChunk(this.mapEngine.activeMapChunks.length - 1);
            // console.log('map chunk removed', this.mapEngine.activeMapChunks.length);
            removed++;
        }
        return removed;
    }

    removeOffRightTiles() {
        const removeMinWorldX = this.model.worldX + config.VIEW_WIDTH / config.DRAW_SCALE;
        let removedCount = 0;
        for (let i = 0; i < this.mapEngine.activeMapChunks.length; i++) {
            for (let j = 0; j < this.mapEngine.activeMapChunks[i].renderedSprites.length; j++) {
                const sprite = this.mapEngine.activeMapChunks[i].renderedSprites[j];
                const spriteWorldX = sprite.x + sprite.cachedBounds.width * sprite.scale.x + sprite.cachedBounds.x * sprite.scale.x;
                if (spriteWorldX > removeMinWorldX) {
                    sprite.alpha = 0;
                    removedCount++;
                    sprite.enabled = false;
                }
            }
        }
        return removedCount;
    }

    addRandomRightChunks(newChunkCallback = this.chunkAddedCallback) {
        // console.log('Adding random right chunks: ', ChunksByWorldAndDifficulty, this.model);
        var randomChunksAvailable = [];
        for (var d = Math.floor(this.model.minDifficulty); d <= Math.floor(this.model.maxDifficulty); d++) {
            // console.log('Looking for difficulty: ', d, ChunksByWorldAndDifficulty[this.model.currentWorld - 1][d]);
            if (d >= ChunksByWorldAndDifficulty[this.model.currentWorld - 1].length) break;
            for (var i = 0; i < ChunksByWorldAndDifficulty[this.model.currentWorld - 1][d].length; i++) {
                if (ChunksByWorldAndDifficulty[this.model.currentWorld - 1][d][i].id.substr(0, 5).toLowerCase() != 'start' && ChunksByWorldAndDifficulty[this.model.currentWorld - 1][d][i].id.substr(0, 10).toLowerCase() != 'checkpoint' && ChunksByWorldAndDifficulty[this.model.currentWorld - 1][d][i].id.substr(0, 5).toLowerCase() != 'berry' && ChunksByWorldAndDifficulty[this.model.currentWorld - 1][d][i].id.substr(0, 6).toLowerCase() != 'energy' && ChunksByWorldAndDifficulty[this.model.currentWorld - 1][d][i].id.substr(0, 5).toLowerCase() != 'bonus') {
                    randomChunksAvailable.push(ChunksByWorldAndDifficulty[this.model.currentWorld - 1][d][i]);
                }
            }
        }
        //console.log('boundstop: ', this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].bounds.y);
        // console.log(this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1], -this.view.container.x / config.DRAW_SCALE + config.DRAW_AHEAD_DISTANCE / config.DRAW_SCALE);
        while (this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].bounds.x + this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].bounds.width < -this.view.container.x / config.DRAW_SCALE + config.DRAW_AHEAD_DISTANCE / config.DRAW_SCALE) {
            var r = this.lastRandomChunk;
            while (r == this.lastRandomChunk) {
                r = Math.floor(Math.random() * randomChunksAvailable.length);
            }
            this.lastRandomChunk = r;
            var randomChunk = randomChunksAvailable[r];
            // this appears to work, universally, but I have to shift it one grid quare afterwards, prob cos the ref of each tile is the middle?
            var yOffset = 0;
            // console.log('adding chunk: ', randomChunk);
            let aligned = false;
            for (var i = 0; i < randomChunk.specialTiles.length; i++) {
                if (randomChunk.specialTiles[i].config.specialType == 'align') {
                    yOffset -= this.mapEngine.renderer.tileHeight * randomChunk.specialTiles[i].gridY;
                    aligned = true;
                }
            }
            if (!aligned) {
                yOffset = this.randomiseChunkYOffset(randomChunk);
                yOffset *= config.TILE_HEIGHT;
            }
            var chunkSpacing = config.CHUNK_MIN_SPACING_TILES + Math.floor(Math.random() * (config.CHUNK_MAX_SPACING_TILES - config.CHUNK_MIN_SPACING_TILES + 1)) * config.TILE_WIDTH;
            var mapPositionOffset = { x: this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].bounds.x + this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].bounds.width + chunkSpacing, y: yOffset };
            this.mapEngine.addMapChunk(randomChunk, mapPositionOffset.x,  mapPositionOffset.y);
            // console.log("diffs: ", this.currentChunkMinDifficulty, this.currentChunkMaxDifficulty);
            // this.increaseDifficulty();

            // console.log('view x, width: ', this.view.container.x, this.view.width);
            if (typeof newChunkCallback == 'function') {
                newChunkCallback(this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1]);
            }
        }
        this.removeTooLowPlatforms();
    }

    addSpecificChunk(id, newChunkCallback = this.chunkAddedCallback) {
        var randomChunksAvailable = [];
        // for (var d = Math.floor(this.currentChunkMinDifficulty); d <= Math.floor(this.currentChunkMaxDifficulty); d++) {
            for (let prop in ProcessedMapChunks) {
                if (ProcessedMapChunks[prop].id.substr(0, id.length).toLowerCase() == id) {
                    randomChunksAvailable.push(ProcessedMapChunks[prop]);
                }
            }
        // }
        //console.log('boundstop: ', this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].bounds.y);
        var randomChunk = randomChunksAvailable[Math.floor(Math.random() * randomChunksAvailable.length)];
        // while (this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].bounds.x < -this.view.container.x + config.DRAW_AHEAD_DISTANCE) {
            // this appears to work, universally, but I have to shift it one grid quare afterwards, prob cos the ref of each tile is the middle?
            var yOffset = 0;
            // console.log('adding chunk: ', randomChunk);
            let aligned = false;
            for (var i = 0; i < randomChunk.specialTiles.length; i++) {
                if (randomChunk.specialTiles[i].config.specialType == 'align') {
                    yOffset -= this.mapEngine.renderer.tileHeight * randomChunk.specialTiles[i].gridY;
                    aligned = true;
                }
            }
            if (!aligned) {
                yOffset = this.randomiseChunkYOffset(randomChunk);
                yOffset *= config.TILE_HEIGHT;
            }
            var chunkSpacing = config.CHUNK_MIN_SPACING_TILES + Math.floor(Math.random() * (config.CHUNK_MAX_SPACING_TILES - config.CHUNK_MIN_SPACING_TILES + 1)) * config.TILE_WIDTH;
            var mapPositionOffset = { x: this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].bounds.x + this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].bounds.width + chunkSpacing, y: yOffset };
            this.mapEngine.addMapChunk(randomChunk, mapPositionOffset.x,  mapPositionOffset.y);
            // console.log("diffs: ", this.currentChunkMinDifficulty, this.currentChunkMaxDifficulty);
        // }
        if (typeof newChunkCallback == 'function') {
            newChunkCallback(this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1]);
        }

        return mapPositionOffset;
    }

    addSpecificRightChunks(id, startAtViewEnd = false, newChunkCallback = this.chunkAddedCallback) {
        var randomChunksAvailable = [];
        // for (var d = Math.floor(this.currentChunkMinDifficulty); d <= Math.floor(this.currentChunkMaxDifficulty); d++) {
            for (let prop in ProcessedMapChunks) {
                if (ProcessedMapChunks[prop].id.substr(0, id.length).toLowerCase() == id && ProcessedMapChunks[prop].world === this.model.currentWorld) {
                    randomChunksAvailable.push(ProcessedMapChunks[prop]);
                }
            }
            if (randomChunksAvailable.length === 0) {
                for (let prop in ProcessedMapChunks) {
                    if (ProcessedMapChunks[prop].id.substr(0, id.length).toLowerCase() == id) {
                        randomChunksAvailable.push(ProcessedMapChunks[prop]);
                    }
                }
            }
        // }
        // console.log('Specific chunks available: ', id, randomChunksAvailable);
        // console.log('boundstop: ', this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].bounds.y);
        var randomChunk = randomChunksAvailable[Math.floor(Math.random() * randomChunksAvailable.length)];
        while (this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].bounds.x < -this.view.container.x / config.DRAW_SCALE + config.DRAW_AHEAD_DISTANCE / config.DRAW_SCALE) {
            // this appears to work, universally, but I have to shift it one grid square afterwards, prob cos the ref of each tile is the middle?
            var yOffset = 0;
            // console.log('adding chunk: ', randomChunk);
            let aligned = false;
            for (var i = 0; i < randomChunk.specialTiles.length; i++) {
                if (randomChunk.specialTiles[i].config.specialType == 'align') {
                    yOffset -= this.mapEngine.renderer.tileHeight * randomChunk.specialTiles[i].gridY;
                    aligned = true;
                }
            }
            if (!aligned) {
                yOffset = this.randomiseChunkYOffset(randomChunk);
                yOffset *= config.TILE_HEIGHT;
            }
            var chunkSpacing = config.CHUNK_MIN_SPACING_TILES + Math.floor(Math.random() * (config.CHUNK_MAX_SPACING_TILES - config.CHUNK_MIN_SPACING_TILES + 1)) * config.TILE_WIDTH;
            var mapPositionOffset = { x: this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].bounds.x + this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].bounds.width + chunkSpacing, y: yOffset };
            if (startAtViewEnd) {
                mapPositionOffset.x = this.model.worldX + config.VIEW_WIDTH / config.DRAW_SCALE;
                startAtViewEnd = false;
            }
            this.mapEngine.addMapChunk(randomChunk, mapPositionOffset.x,  mapPositionOffset.y);
            // console.log("diffs: ", this.currentChunkMinDifficulty, this.currentChunkMaxDifficulty);
            if (typeof newChunkCallback == 'function') {
                newChunkCallback(this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1]);
            }
        }

        return mapPositionOffset;
    }

    removeTooLowPlatforms() {
        if (config.USE_PERMANENT_GROUND) {
            for (let i = 0; i < this.mapEngine.activeMapChunks.length; i++) {
                for (let j = 0; j < this.mapEngine.activeMapChunks[i].renderedSprites.length; j++) {
                    const sprite = this.mapEngine.activeMapChunks[i].renderedSprites[j];
                    if (!sprite.forcedOn && sprite.enabled) {
                        const groundY = config.VIEW_HEIGHT / config.DRAW_SCALE - config.PERMANENT_GROUND_BOTTOM_OFFSET * config.TILE_HEIGHT;
                        if (!this.mapEngine.activeMapChunks[i].mapChunk.properties.lock_to_bottom) {
                            if ((sprite.config.platform && sprite.y > groundY - config.MIN_PLATFORM_GROUND_CLEARANCE_TILES * config.TILE_HEIGHT) || (!sprite.config.platform && sprite.y > groundY - (config.MIN_PLATFORM_GROUND_CLEARANCE_TILES + 3) * config.TILE_HEIGHT)) {
                                if (sprite.config.platform) {
                                    sprite.y -= config.TILE_HEIGHT * 2;
                                }

                                if ((sprite.config.platform && sprite.y > groundY - config.MIN_PLATFORM_GROUND_CLEARANCE_TILES * config.TILE_HEIGHT) || (!sprite.config.platform && sprite.y > groundY - (config.MIN_PLATFORM_GROUND_CLEARANCE_TILES + 3) * config.TILE_HEIGHT)) {
                                    sprite.alpha = 0;
                                    sprite.enabled = false;
                                }
                            }
                        } else {
                            if ((sprite.config.platform && sprite.y > groundY - config.MIN_PLATFORM_GROUND_CLEARANCE_TILES * config.TILE_HEIGHT)) {
                                sprite.alpha = 0;
                                sprite.enabled = false;
                                console.log('Removing platform: ', sprite);
                            }
                        }
                    }
                }
            }
        }
    }

    checkForDueForcedChunks() {
        // console.log('Checking for forced chunks');
        if (this.hardForceChunks.length > 0) {
            const vidView = v.get('videoFeature');
            const vidSeekTime = vidView ? vidView.getVidSeekTime() : 0;
            while (this.hardForceChunks[0].time <= vidSeekTime) {
                // console.log('Forcing chunk: ', this.hardForceChunks[0]);
                this.forceAddChunk(this.hardForceChunks[0]);
                this.hardForceChunks.shift();
                if (this.hardForceChunks.length === 0) break;
            }
        }
    }

    checkForDueAssetSwapsOnPassing() {
        for (let i = 0; i < this.mapEngine.activeMapChunks.length; i++) {
            for (let j = 0; j < this.mapEngine.activeMapChunks[i].renderedSprites.length; j++) {
                const sprite = this.mapEngine.activeMapChunks[i].renderedSprites[j];
                if (sprite.config.switch_asset_on_passing && !sprite.asset_switched && sprite.x + sprite.cachedBounds.x * sprite.scale.x < this.model.worldX + config.FOCUS_LEFT_BUFFER) {
                    sprite.asset_switched = true;
                    const assetList = JSON.parse(sprite.config.asset_list);
                    if (assetList.length) {
                        const asset = assetList[Math.floor(Math.random() * assetList.length)];
                        sprite.defaultSprite.texture = PIXI.Texture.from(asset);
                    }
                }
            }
        }

        // Now check parallax items
        for (let prop in config.PARALLAX_LAYERS) {
            const layerContainer = this.view.children['layer_' + prop];
            for (let i = 0; i < layerContainer.children.length; i++) {
                const sprite = layerContainer.children[i];
                if (sprite.config.switch_asset_on_passing && !sprite.asset_switched && sprite.x < config.FOCUS_LEFT_BUFFER + sprite.width) {
                    sprite.asset_switched = true;
                    const assetList = JSON.parse(sprite.config.asset_list);
                    if (assetList.length) {
                        const asset = assetList[Math.floor(Math.random() * assetList.length)];
                        sprite.defaultSprite.texture = PIXI.Texture.from(asset);
                    }
                }
            }
        }
    }

    forceAddChunk(forceChunkConfig, newChunkCallback = this.chunkAddedCallback) {
        console.log('Forcing chunk: ', forceChunkConfig);
        // First remove chunks off the right edge of the screen
        this.removeOffRightChunks();
        // Now remove any tiles that are right of our force chunk
        const forceChunkWorldX = this.model.worldX + forceChunkConfig.chunkLeftOffset / config.DRAW_SCALE;
        const minRemoveX = this.model.worldX + (config.FOCUS_LEFT_BUFFER * 4) / config.DRAW_SCALE;
        const screenEdgeX = this.model.worldX + config.VIEW_WIDTH / config.DRAW_SCALE;
        let removedCount = 0;
        for (let i = 0; i < this.mapEngine.activeMapChunks.length; i++) {
            for (let j = 0; j < this.mapEngine.activeMapChunks[i].renderedSprites.length; j++) {
                const sprite = this.mapEngine.activeMapChunks[i].renderedSprites[j];
                const spriteWorldX = sprite.x + sprite.cachedBounds.width * sprite.scale.x + sprite.cachedBounds.x * sprite.scale.x;
                if (spriteWorldX > forceChunkWorldX && spriteWorldX > minRemoveX) {
                    if (sprite.x < screenEdgeX) {
                        gsap.to(sprite, {alpha: 0, y: sprite.y + 200, duration: 0.2, delay: removedCount * 0.05});
                    } else {
                        sprite.alpha = 0;
                    }
                    removedCount++;
                    sprite.enabled = false;
                }
            }
        }
        // Now add the forced chunk
        let chunkData = ProcessedMapChunks[forceChunkConfig.chunkId];
        if (chunkData) {
            let yOffset = 0;
            let aligned = false;
            for (var i = 0; i < chunkData.specialTiles.length; i++) {
                if (chunkData.specialTiles[i].config.specialType === 'align') {
                    yOffset -= this.mapEngine.renderer.tileHeight * chunkData.specialTiles[i].gridY;
                    aligned = true;
                }
            }
            if (!aligned) {
                yOffset = config.VIEW_HEIGHT / config.DRAW_SCALE - chunkData.height * config.TILE_HEIGHT - (forceChunkConfig.chunkBottomOffset || 0) * config.TILE_HEIGHT;
            }
            const mapPositionOffset = { x: forceChunkWorldX, y: yOffset };
            this.mapEngine.addMapChunk(chunkData, mapPositionOffset.x,  mapPositionOffset.y);

            // Nicely bring in the replacement tiles

            let layer = 0;
            let layerTweenDelay = 0.3;
            let tweenDelayCount = 0;
            for (let i = 0; i < this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].renderedSprites.length; i++) {
                const sprite = this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].renderedSprites[i];

                if (forceChunkConfig.popIn) {
                    if (layer !== sprite.layer) {
                        layerTweenDelay > 0 ? layerTweenDelay -= 0.1 : layerTweenDelay = 0;
                        layer = sprite.layer;
                        tweenDelayCount = 0;
                    }
                    sprite.alpha = 0;
                    sprite.y += 100;
                    gsap.to(sprite, {alpha: 1, y: sprite.y - 100, duration: 0.35, delay: tweenDelayCount * 0.35 + layerTweenDelay, ease: 'back.easeOut'});
                    tweenDelayCount++;
                }

                sprite.forcedOn = true;
            }

            if (typeof newChunkCallback == 'function') {
                newChunkCallback(this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1]);
            }
            return mapPositionOffset;
        }
    }

    randomiseChunkYOffset(mapChunk) {
        const viewHeightTiles = Math.floor((config.VIEW_HEIGHT / config.DRAW_SCALE) / config.TILE_HEIGHT);
        let yOffset = 0;
        yOffset = viewHeightTiles;
        // console.log('Randomising y offset: ', mapChunk, viewHeightTiles);

        if (!mapChunk.properties.lock_to_bottom) {
            yOffset -= Math.floor(Math.random() * (config.RANDOM_MAP_DISPLACEMENT_Y_TILES + 1));
            yOffset -= mapChunk.height;
            yOffset -= config.MIN_PLATFORM_BOTTOM_OFFSET_TILES;
            // console.log('Y off tiles: ', yOffset);

            const newChunkFirstPlatformYOffset = (mapChunk.firstPlatformYOffset ? mapChunk.firstPlatformYOffset : mapChunk.firstTileYOffset) - mapChunk.minGy;
            const newChunkLastPlatformYOffset = (mapChunk.lastPlatformYOffset ? mapChunk.lastPlatformYOffset : mapChunk.lastTileYOffset) - mapChunk.minGy;
            const newChunkHighestPlatformYOffset = (mapChunk.highestPlatformYOffset ? mapChunk.highestPlatformYOffset : mapChunk.highestTileYOffset) - mapChunk.minGy;
            const newChunkLowestPlatformYOffset = (mapChunk.lowestPlatformYOffset ? mapChunk.lowestPlatformYOffset : mapChunk.lowestTileYOffset) - mapChunk.minGy;

            if (this.mapEngine.activeMapChunks.length > 0) {
                const prevChunkLastPlatformYOffset = (this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].mapChunk.lastPlatformYOffset ? this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].mapChunk.lastPlatformYOffset : this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].mapChunk.lastTileYOffset) - this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].mapChunk.minGy;
                const prevChunkYOffset = (this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1].chunkY / config.TILE_HEIGHT) + prevChunkLastPlatformYOffset;
                // console.log('Prev chunk offset: ', prevChunkYOffset, this.mapEngine.activeMapChunks[this.mapEngine.activeMapChunks.length - 1]);

                while (yOffset + newChunkFirstPlatformYOffset < prevChunkYOffset - config.PREV_MAP_CHUNK_MAX_DISPLACEMENT_Y_TILES) {
                    // yOffset = prevChunkYOffset - config.PREV_MAP_CHUNK_MAX_DISPLACEMENT_Y_TILES - newChunkFirstPlatformYOffset;
                    yOffset++;
                    // console.log('Adjusted down for prev chunk displacement: ', yOffset)
                }
                while (yOffset + newChunkFirstPlatformYOffset > prevChunkYOffset + config.PREV_MAP_CHUNK_MAX_DISPLACEMENT_Y_TILES) {
                    // yOffset = prevChunkYOffset + config.PREV_MAP_CHUNK_MAX_DISPLACEMENT_Y_TILES - newChunkFirstPlatformYOffset;
                    yOffset--;
                    // console.log('Adjusted up for prev chunk displacement: ', yOffset)
                }
            }

            while (yOffset + newChunkHighestPlatformYOffset > viewHeightTiles - config.MIN_PLATFORM_BOTTOM_OFFSET_TILES) {
                // yOffset = viewHeightTiles - config.MIN_PLATFORM_BOTTOM_OFFSET_TILES - newChunkHighestPlatformYOffset;
                yOffset--;
                // console.log('Adjusted up for view height: ', yOffset);
            }
            while (yOffset + newChunkLowestPlatformYOffset < config.MIN_PLATFORM_TOP_OFFSET_TILES) {
                // yOffset = config.MIN_PLATFORM_TOP_OFFSET_TILES - newChunkLowestPlatformYOffset;
                yOffset++;
                // console.log('Adjusted down for view height: ', yOffset);
            }
            if (yOffset < config.MIN_TILE_OFFSET_TOP) {
                yOffset = config.MIN_TILE_OFFSET_TOP;
            }

            // yOffset += mapChunk.minGy;
        } else {
            // console.log('Locking to bottom: ', mapChunk);
            yOffset -= mapChunk.height;
            if (config.USE_PERMANENT_GROUND) {
                yOffset -= config.PERMANENT_GROUND_BOTTOM_OFFSET;
            }
        }

        return yOffset; // - mapChunk.minGy;
    }

    findStart() {
        // start square is always in our first active chunk
        for (var i =0; i < this.mapEngine.activeMapChunks[0].specialSprites.length; i++) {
            if (this.mapEngine.activeMapChunks[0].specialSprites[i].config.start || (this.mapEngine.activeMapChunks[0].specialSprites[i].config.specialType && this.mapEngine.activeMapChunks[0].specialSprites[i].config.specialType.substr(0, 5) == 'start')) {
                return this.mapEngine.activeMapChunks[0].specialSprites[i];
            }
        }
    }

    findClosestStart(xPos) {
        let startDist = Infinity;
        let closestStart = null;
        for (var i =0; i < this.mapEngine.activeMapChunks[0].specialSprites.length; i++) {
            if (this.mapEngine.activeMapChunks[0].specialSprites[i].config.start || (this.mapEngine.activeMapChunks[0].specialSprites[i].config.specialType && this.mapEngine.activeMapChunks[0].specialSprites[i].config.specialType.substr(0, 5) == 'start')) {
                if (this.mapEngine.activeMapChunks[0].specialSprites[i].x < xPos) {
                    let dist = Math.abs(this.mapEngine.activeMapChunks[0].specialSprites[i].x - xPos);
                    if (dist < startDist) {
                        startDist = dist;
                        closestStart = this.mapEngine.activeMapChunks[0].specialSprites[i];
                    }
                }
            }
        }
        return closestStart;
    }

    removeTilesOfType(type) {
        for (var i = 0; i < this.mapEngine.activeMapChunks.length; i++) {
            for (var j = 0; j < this.mapEngine.activeMapChunks[i].renderedSprites.length; j++) {
                if (this.mapEngine.activeMapChunks[i].renderedSprites[j].config.object_id === type) {
                    this.mapEngine.activeMapChunks[i].renderedSprites[j].alpha = 0;
                    this.mapEngine.activeMapChunks[i].renderedSprites[j].enabled = false;
                }
            }
        }
    }

    // new zoom/scroll strategy
    focus(sprite) {
        let xBefore = this.model.worldX;
        this.model.worldX = this.model.worldX > sprite.x - config.FOCUS_LEFT_BUFFER / config.DRAW_SCALE ? this.model.worldX : sprite.x - config.FOCUS_LEFT_BUFFER / config.DRAW_SCALE;
        this.checkIfEnteredNewWorld(xBefore, this.model.worldX);
        this.view.container.x = this.model.worldX > 0 ? -this.model.worldX * config.DRAW_SCALE : 0;
        this.view.children.permanent_ground.x = -this.view.container.x / config.DRAW_SCALE;
        // this.view.container.y = app.viewPort.height / 2 - sprite.y * config.DRAW_SCALE;
        return xBefore !== this.model.worldX;
    }

    // returns the distance scrolled
    focusSmooth(sprite, frameDeltaMult) {
        let xBefore = this.model.worldX;
        this.model.worldX = sprite.x - config.FOCUS_LEFT_BUFFER / config.DRAW_SCALE;
        if (this.model.worldX < 0) this.model.worldX = 0;
        this.checkIfEnteredNewWorld(xBefore, this.model.worldX);

        var mapX = -this.view.container.x;
        mapX += ((this.model.worldX - xBefore) / 100) * frameDeltaMult;
        this.view.container.x = mapX > 0 ? -mapX : 0;
        this.view.children.permanent_ground.x = -this.view.container.x / config.DRAW_SCALE;

        return -(this.view.container.x - xBefore);
    }

    scroll(distance, frameDeltaMult = 1) {
        let xBefore = this.model.worldX;
        this.model.worldX += distance * frameDeltaMult;
        this.checkIfEnteredNewWorld(xBefore, this.model.worldX);
        this.view.container.x = this.model.worldX > 0 ? -this.model.worldX : 0;
    }

    findPotentialLandingPlatforms(minX, maxX) {
        var potentialPlatforms = [];
        for (var i = 0; i < this.mapEngine.activeMapChunks.length; i++) {
            // console.log('active chunk: ', this.mapEngine.activeMapChunks[i].bounds.x, this.mapEngine.activeMapChunks[i].bounds.width, minX, maxX);
            // if (this.mapEngine.activeMapChunks[i].bounds.x + this.mapEngine.activeMapChunks[i].bounds.width > minX && this.mapEngine.activeMapChunks[i].bounds.x < maxX) {
                for (var j = 0; j < this.mapEngine.activeMapChunks[i].renderedSprites.length; j++) {
                    if (this.mapEngine.activeMapChunks[i].renderedSprites[j].enabled && this.mapEngine.activeMapChunks[i].renderedSprites[j].config.platform) {
                        // console.log('potential platform: ', this.mapEngine.activeMapChunks[i].renderedSprites[j], this.mapEngine.activeMapChunks[i].renderedSprites[j].baseTileX, this.mapEngine.activeMapChunks[i].renderedSprites[j].cachedBounds.width);
                        if (this.mapEngine.activeMapChunks[i].renderedSprites[j].x + (this.mapEngine.activeMapChunks[i].renderedSprites[j].config.collision_offset_left || 0) <= maxX 
                        && this.mapEngine.activeMapChunks[i].renderedSprites[j].x + this.mapEngine.activeMapChunks[i].renderedSprites[j].cachedBounds.width * this.mapEngine.activeMapChunks[i].renderedSprites[j].scale.x - (this.mapEngine.activeMapChunks[i].renderedSprites[j].config.collision_offset_right || 0) >= minX) {
                            potentialPlatforms.push(this.mapEngine.activeMapChunks[i].renderedSprites[j]);
                        }
                    }
                }
            // }
        }
        return potentialPlatforms;
    }

    findPotentialCollisions(minX, maxX, minY, maxY, type = null) {
        let includeTypes = [];
        let excludeTypes = [];
        if (type !== null) {
            if (typeof type === 'string') {
                includeTypes.push(type);
            }
            if (Array.isArray(type)) {
                includeTypes = type;
            }
            if (type.hasOwnProperty('include')) {
                if (Array.isArray(type.include)) {
                    includeTypes = type.include;
                } else {
                    includeTypes.push(type.include);
                }
            }
            if (type.hasOwnProperty('exclude')) {
                if (Array.isArray(type.exclude)) {
                    excludeTypes = type.exclude;
                } else {
                    excludeTypes.push(type.exclude);
                }
            }
        }
        // console.log('Looking for collisions');
        // console.log('include: ', includeTypes, 'exclude: ', excludeTypes);

        const isTypeIncluded = function(sprite, includeTypes, excludeTypes) {
            if (includeTypes.length > 0) {
                for (let i = 0; i < includeTypes.length; i++) {
                    if (sprite.config[includeTypes[i]]) {
                        return true;
                    }
                }
                return false;
            }
            if (excludeTypes.length > 0) {
                for (let i = 0; i < excludeTypes.length; i++) {
                    if (sprite.config[excludeTypes[i]]) {
                        return false;
                    }
                }
                return true;
            }
            return true;
        }

        let potentialCollisions = [];
        for (let i = 0; i < this.mapEngine.activeMapChunks.length; i++) {
            for (let j = 0; j < this.mapEngine.activeMapChunks[i].renderedSprites.length; j++) {
                if (this.mapEngine.activeMapChunks[i].renderedSprites[j].enabled && (type === null || isTypeIncluded(this.mapEngine.activeMapChunks[i].renderedSprites[j], includeTypes, excludeTypes))) {
                    // console.log('Found included type: ', this.mapEngine.activeMapChunks[i].renderedSprites[j], this.mapEngine.activeMapChunks[i].renderedSprites[j].config);
                    if (!this.mapEngine.activeMapChunks[i].renderedSprites[j].collided && this.mapEngine.activeMapChunks[i].renderedSprites[j].x + (this.mapEngine.activeMapChunks[i].renderedSprites[j].config.collision_offset_left || 0) <= maxX 
                    && this.mapEngine.activeMapChunks[i].renderedSprites[j].x + this.mapEngine.activeMapChunks[i].renderedSprites[j].cachedBounds.width * this.mapEngine.activeMapChunks[i].renderedSprites[j].scale.x - (this.mapEngine.activeMapChunks[i].renderedSprites[j].config.collision_offset_right || 0) >= minX
                    && this.mapEngine.activeMapChunks[i].renderedSprites[j].y + this.mapEngine.activeMapChunks[i].renderedSprites[j].cachedBounds.y * this.mapEngine.activeMapChunks[i].renderedSprites[j].scale.y + (this.mapEngine.activeMapChunks[i].renderedSprites[j].config.collision_offset_top || 0) <= maxY 
                    && this.mapEngine.activeMapChunks[i].renderedSprites[j].y - (this.mapEngine.activeMapChunks[i].renderedSprites[j].config.collision_offset_bottom || 0) >= minY) {
                        // console.log('Collided!');
                        potentialCollisions.push(this.mapEngine.activeMapChunks[i].renderedSprites[j]);
                    }
                }
            }
        }
        return potentialCollisions;
    }

    checkIfEnteredNewWorld(xBefore, xNow) {
        const vidView = v.get('videoFeature');
        const vidSeekTime = vidView ? vidView.getVidSeekTime() : 0;
        // console.log('vidSeekTime: ', vidSeekTime);
        // Now based on video seek position
        for (let i = config.VID_ZONE_TIMES.length -1; i >= 0; i--) {
            if (vidSeekTime > config.VID_ZONE_TIMES[i].time) {
                if (this.model.currentWorld !== config.VID_ZONE_TIMES[i].zone) {
                    console.log('Entered new world: ', config.VID_ZONE_TIMES[i].zone);
                    this.model.currentWorld = config.VID_ZONE_TIMES[i].zone;
                    this.removeOffRightChunks();
                    if (config.RESET_DIFFICULTY_WHEN_WORLD_ENDS) {
                        console.log('Resetting difficulty');
                        this.model.minDifficulty = config.START_MIN_DIFFICULTY;
                        this.model.maxDifficulty = config.START_MAX_DIFFICULTY;
                    }
                    if (config.VID_ZONE_TIMES[i].triggerPlayerCommand) {
                        GAME_CONTROLLER.playerController[config.VID_ZONE_TIMES[i].triggerPlayerCommand]();
                    }
                    document.dispatchEvent(new CustomEvent(EVENTS.WORLD_END, {detail: {world: this.model.currentWorld}}));
                }
                // Stop at the first valid zone we find
                break;
            }
        }

        if (vidSeekTime >= config.RUN_OFF_END_TIME) {
            this.model.hasReachedEnd = true;
        }
        if (vidSeekTime >= config.GAME_OVER_TIME) {
            console.log('End now!');
            GAME_CONTROLLER.gameComplete();
        }

        /* if (xBefore < config.WORLD_DISTANCE * this.model.currentWorld && xNow >= config.WORLD_DISTANCE * this.model.currentWorld) {
            document.dispatchEvent(new CustomEvent(EVENTS.WORLD_END, {detail: {world: this.model.currentWorld}}));
            if (this.model.currentWorld < config.WORLD_COUNT) {
                console.log('Entered new world: ', this.model.currentWorld);
                this.model.currentWorld++;
                if (config.RESET_DIFFICULTY_WHEN_WORLD_ENDS) {
                    console.log('Resetting difficulty');
                    this.model.minDifficulty = config.START_MIN_DIFFICULTY;
                    this.model.maxDifficulty = config.START_MAX_DIFFICULTY;
                }
            }
            return true;
        } */
        return false;
    }


    hideShowSprites() {
        this.visibleRect.x = -this.view.container.x / config.DRAW_SCALE; // (-this.view.container.x - game.gameView.children.gameContainer.x) + app.viewPort.left;
        this.mapEngine.hideShowSprites(this.visibleRect);
    }

    increaseDifficulty() {
        if (this.model.worldX > this.model.nextDifficultyIncreaseDistance) {
            this.model.minDifficulty += config.MIN_DIFFICULTY_INCREASE;
            if (this.model.minDifficulty > config.MAX_MIN_DIFFICULTY) {
                this.model.minDifficulty = config.MAX_MIN_DIFFICULTY;
            }
            if (this.model.minDifficulty >= ChunksByWorldAndDifficulty[this.model.currentWorld - 1].length) {
                this.model.minDifficulty = ChunksByWorldAndDifficulty[this.model.currentWorld - 1].length - 1;
            }
            this.model.maxDifficulty += config.MAX_DIFFICULTY_INCREASE;
            if (this.model.maxDifficulty > config.MAX_MAX_DIFFICULTY) {
                this.model.maxDifficulty = config.MAX_MAX_DIFFICULTY;
            }
            this.model.nextDifficultyIncreaseDistance += config.DIFFICULTY_INCREASE_DISTANCE;
            // console.log('Difficulty increased to: ', this.model.minDifficulty, this.model.maxDifficulty);
        }
    }

    // There are some issues with this parallax, I think due to DRAW_SCALE stuff, so for now it is a bit innefficient
    // I've hacked about with this a bit to get the stories working on the parallax layer which I'm happy to settle for
    // because it's the only thing that is placed in the editor (in future we should fix this properly)
    addParallax(mapChunk) {
        if (mapChunk.mapChunk.objectLayersData.length) {
            let objX = null;
            let tweenDelay = 0.7;
            for (let i = 0; i < mapChunk.mapChunk.objectLayersData.length; i++) {
                const pObject = mapChunk.mapChunk.objectLayersData[i];
                if (this.view.children['layer_' + pObject.layerName]) {
                    const tConfig = ProcessedTilesets[pObject.tileSet].getTile(pObject.tile);
                    if (typeof tConfig !== 'undefined') {
                        const tileSprite = ProcessedTilesets[pObject.tileSet].getTileSprite(pObject.tile, null, 'parallax_' + tConfig.texture);
                        tileSprite.defaultSprite.anchor.x = tileSprite.defaultSprite.anchor.y = 0.5;
                        if (typeof tConfig.anchor_x === 'number') {
                            tileSprite.defaultSprite.anchor.x = tConfig.anchor_x;
                        }
                        if (typeof tConfig.anchor_y === 'number') {
                            tileSprite.defaultSprite.anchor.y = tConfig.anchor_y;
                        }
                        const tileScale = {x: pObject.width / tileSprite.defaultSprite.texture.width, y: pObject.width / tileSprite.defaultSprite.texture.width};
                        tileSprite.defaultSprite.scale = tileScale;
                        // tileSprite.defaultSprite.width = pObject.width;
                        // tileSprite.defaultSprite.height = pObject.height;
                        tileSprite.defaultSprite.rotation = pObject.rotation * Math.PI / 180;
                        if (objX === null) {
                            objX = pObject.x;
                        } else {
                            objX += 620;
                        }
                        tileSprite.x = objX + mapChunk.chunkX * config.DRAW_SCALE + this.view.container.x + 420 + tConfig.offset_x;
                        
                        tileSprite.y = pObject.y + mapChunk.chunkY + 1850;
                        gsap.to(tileSprite, {y: tileSprite.y - 1200, duration: 0.3, delay: tweenDelay, ease: 'back.out'});
                        tweenDelay += 0.65;

                        this.view.children['layer_' + pObject.layerName].addChild(tileSprite);
                        tileSprite.visible = false;

                        tileSprite.config = tConfig;
                    }
                }
            }
        }

        // this.addRandomParallaxItems();
    }

    addRandomParallaxItems() {
        for (let prop in config.PARALLAX_LAYERS) {
            if (config.RANDOM_PARALLAX_ITEMS[prop].length > 0) {
                if (this.view.children['layer_' + prop].children.length < config.PARALLAX_LAYERS[prop].MAX_PARALLAX_ITEMS) {
                    if (Math.random() < config.PARALLAX_LAYERS[prop].RANDOM_PARALLAX_ITEM_CHANCE) {
                        // Let's add a new item!
                        const item = config.RANDOM_PARALLAX_ITEMS[prop][Math.floor(Math.random() * config.RANDOM_PARALLAX_ITEMS[prop].length)];
                        let tileSprite = objectPooler.getObjectFromPool('parallax_' + item.spriteId);
                        if (!tileSprite) {
                            tileSprite = PIXI.Sprite.from(item.spriteId);
                        }
                        if (tileSprite) {
                            const speedScale = Math.random() * (item.maxScale - item.minScale) + item.minScale;
                            const speedMult = Math.random() * (item.speedMultRange[1] - item.speedMultRange[0]) + item.speedMultRange[0];
                            const tConfig = {
                                speedScale: speedScale,
                                x_speed: item.movementXSpeed || 0,
                            }
                            tileSprite.config = tConfig;
                            tileSprite.anchor.x = tileSprite.anchor.y = 0.5;
                            tileSprite.scale.x = tileSprite.scale.y = speedScale * config.PARALLAX_LAYERS[prop].speed;
                            tConfig.speedScale *= speedMult;
                            const alpha = Math.random() * (item.alphaRange[1] - item.alphaRange[0]) + item.alphaRange[0];
                            tileSprite.alpha = alpha;
                            tileSprite.x = config.VIEW_WIDTH / config.DRAW_SCALE + (tileSprite.width / 2) / config.DRAW_SCALE;
                            tileSprite.y = Math.random() * config.VIEW_HEIGHT / config.DRAW_SCALE;

                            tileSprite.tweens = [];
                            if (item.wobbleTween) {
                                const startY = tileSprite.y;
                                const dir = Math.random() < 0.5 ? -1 : 1;
                                const wobbleTween = gsap.to(tileSprite, {y: startY + (Math.random() * (item.wobbleRange[1] - item.wobbleRange[0]) + item.wobbleRange[0]) * dir, duration: Math.random() * (item.wobbleDurationRange[1] - item.wobbleDurationRange[0]) + item.wobbleDurationRange[0], repeat: -1, yoyo: true, ease: 'sine.inOut'});
                                tileSprite.tweens.push(wobbleTween);
                            }
                            if (item.fadeTween) {
                                const fadeDir = Math.random() < 0.5 ? -1 : 1;
                                if (fadeDir > 0) {
                                    tileSprite.alpha = 0;
                                }
                                const fadeTween = gsap.to(tileSprite, {alpha: fadeDir > 0 ? alpha : 0, duration: Math.random() * (item.fadeDurationRange[1] - item.fadeDurationRange[0]) + item.fadeDurationRange[0], repeat: -1, yoyo: true, ease: 'sine.inOut'});
                                tileSprite.tweens.push(fadeTween);
                            }

                            this.view.children['layer_' + prop].addChild(tileSprite);
                        }
                    }
                }
            }
        }
    }

    updateParallaxLayers(timeDelta, deltaMult, distance) {
        // Our parallax layer don't move with the rest of the layers, instead we are going to move the objects separately,
        // so we need to compensate for the movement of the main container
        this.view.children.layer_behind_parallax.x = -this.view.container.x / config.DRAW_SCALE;
        this.view.children.layer_front_parallax.x = -this.view.container.x / config.DRAW_SCALE;

        // Now we need to move the objects in the parallax layers
        const spritesToRemove = [];
        for (let prop in config.PARALLAX_LAYERS) {
            const layerData = config.PARALLAX_LAYERS[prop];
            const layerContainer = this.view.children['layer_' + prop];
            for (let i = 0; i < layerContainer.children.length; i++) {
                const sprite = layerContainer.children[i];
                let spriteSpeed = sprite.config.parallax_speed || layerData.speed;
                if (sprite.config.speedScale || sprite.speedScale) {
                    spriteSpeed *= (sprite.config.speedScale || sprite.speedScale);
                }
                if (sprite.visible) {
                    sprite.x -= (spriteSpeed * distance);
                    // Apply movement to the sprite
                    if (sprite.config.x_speed || sprite.x_speed) {
                        sprite.x += (sprite.config.x_speed || sprite.x_speed) * spriteSpeed * deltaMult;
                    }
                } else {
                    sprite.x -= distance;
                }
                if (sprite.x < (app.guiSize.width + sprite.width) / config.DRAW_SCALE) {
                    sprite.visible = true;
                }
                if (sprite.x < -config.FOCUS_LEFT_BUFFER - sprite.width) {
                    spritesToRemove.push(sprite);
                }
            }
        }
        if (spritesToRemove.length) {
            for (let i = 0; i < spritesToRemove.length; i++) {
                spritesToRemove[i].parent.removeChild(spritesToRemove[i]);
                if (spritesToRemove[i].tweens) {
                    for (let j = 0; j < spritesToRemove[i].tweens.length; j++) {
                        spritesToRemove[i].tweens[j].kill();
                    }
                }
                if (typeof objectPooler !== 'undefined') {
                    objectPooler.addObjectToPool('parallax_' + spritesToRemove[i].spriteID, spritesToRemove[i]);
                } else {
                    spritesToRemove[i].destroy();
                }
            }
        }
    }

    // timeDelta is the actual elapsed time (in seconds)
    // frame delta should be 1 if the frame was exactly the right length, <1 if we are short, >1 if we are long
    update(timeDelta, deltaMult) {
        this.removeOffLeftChunks();
        this.checkForDueForcedChunks();
        if (this.model.bonusCountdown <= 0) {
            this.addRandomRightChunks();
        } else {
            this.addSpecificRightChunks('bonus');
        }
        this.hideShowSprites();
        this.increaseDifficulty();
        this.addRandomParallaxItems();
        // this.mapEngine.updateSwitcherSprites(timeDelta);
        // return this.mapEngine.updateMovingSprites(frameDelta, -this.view.container.x + SAFE_AREA.WIDTH + this.mapEngine.renderer.tileWidth);
    }
}