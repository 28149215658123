import BaseReactView from './BaseReactView';

/**
 * @author Carl Trelfa
 * Almost identical to the basic full screen view, but will go behind our canvas.
 */
export default class ReactFullScreenViewUnderCanvas extends BaseReactView {
    render() {
        let fadeInClass = this.state.visible ? ' fade-in' : ''
        return (
            <div className={'view-holder under-canvas' + fadeInClass} style={{transformOrigin: '0 0', transform:'scale(' + this.state.scale + ')', left: this.state.zeroLeft, top: this.state.zeroTop, width: this.state.width, height: this.state.height}}>
                {this.renderContent()}
                {this.state.transitioning &&
                    <div className='click-blocker under-canvas'></div>
                }
            </div>
        );
    }

    // you'll need to over-ride this for your layout
    renderContent() {
        return (
            <div>Your view layout goes here</div>
        );
    }
}