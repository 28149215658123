import { LANGUAGE_SETTINGS } from "../../model/config";
class TextLocaliser {
  constructor() {
    this.language = 'en';
    this.texts = {};
  }

  setLanguage(language) {
    this.language = language;
  }

  setTexts(texts) {
    this.texts = texts;
  }

  getText(textId) {
    return this.texts[textId] ? this.texts[textId][LANGUAGE_SETTINGS.lang] || this.texts[textId]['en'] : textId;
  }
}

const textLocaliser = new TextLocaliser();
export default textLocaliser;