/** @author Carl Trelfa
 * 
 * Usage:
 * 
 * You need to import .js versions of the maps from Tiled (Tiled can export maps in this format).
 * 
 * So add script tags for each of the .js map files to your index.html file (it's in public/index.html).
 * Or alternatively (as I have done in the demo) use Tiled's json format and add each tilemap as a prop of some object which is passed in.
 * The maps will then be available for processing.
 * 
 *      import { ProcessLoadedMapChunks, DiscoverMapChunkSpecialTiles } from './here';
 * 
 * -- First process your tile sets (see TileSet.js for that) --
 * 
 * Now call:
 * 
 *      ProcessLoadedMapChunks(tileMaps);
 *      DiscoverMapChunkSpecialTiles(tileSets);  // The tilesets should be in ProcessedTilesets imported from TileSet.js
 * 
 * The map chunks that you loaded in to your index file are now processed and ready to use.
 * 
 * You will find them in exported consts ProcessedMapChunks and ChunksByDifficulty
 * 
 * We also support a config layer for adding local configuration data to a specific grid square.
 * This can be a door to let us know where it leads to or text for a sign or any number of other thigns.
 * 
 * In Tiled, add a new top-most layer of type Object called Config-Layer.
 * Then use the rectangle tool, add a rectangle over the tile you want to add config data to and add properties to the rectangle.
 * This will then be picked up by our tile map engine and added as localConfig to tile instances.
 * This is the only layer type other than Tile Layers we support.
 * 
 * NOTE: We now support json data format direct from Tiled (which saves loads of time and works better with es6).
 */

import { ProcessedTilesets } from "./TileSet";

// No need to export this class as you will never need to directly instantiate it
class MapChunk {
    id = '';
    difficulty = 0;
    /*  layer data is stored as an array of objects in this format:
    *       2d array [x][y], each element is either empty or an object:
    *           {
    *               tileSet: String,        // see TileSet class
    *               tile: int               // tile id is normalised to map directly to id's in tilesets (unlike raw Tiled data)
    *           }
    *
    */
    layers = null;
    layerNames = null;
    specialTiles = null;
    configData = null;
    properties = null;
    width = 0;
    height = 0;
    minGx = 0;
    minGy = 0;
    maxGx = 0;
    maxGy = 0;
    firstTileYOffset = 0;
    lastTileYOffset = 0;
    lowestTileYOffset = 0;
    highestTileYOffset = 0;
    firstPlatformYOffset = 0;
    lastPlatformYOffset = 0;
    highestPlatformYOffset = 0;
    lowestPlatformYOffset = 0;
    objectLayersData = [];

    // pass in some raw map data from Tiled
    constructor(id, rawMapData) {
        this.id = id;
        // console.log(id);
        // console.log("rawmapdata: ", id, rawMapData);
        let diff = 1;
        let world = 1;  
        /*
        if (rawMapData.properties && rawMapData.properties.difficulty) {
            diff = rawMapData.properties.difficulty;
        }
        */
       this.properties = {};
        if (rawMapData.properties) {
            for (let i = 0; i < rawMapData.properties.length; i++) {
                if (rawMapData.properties[i].name === 'difficulty') {
                    diff = rawMapData.properties[i].value;
                }
                if (rawMapData.properties[i].name === 'world') {
                    world = rawMapData.properties[i].value;
                }
                this.properties[rawMapData.properties[i].name] = rawMapData.properties[i].value;
            }
        }
        this.difficulty = diff;
        this.world = world;

        var tileSets = [];
        for (let i = 0; i < rawMapData.tilesets.length; i++) {
            var tsdata = {
                name: rawMapData.tilesets[i].source.substring(rawMapData.tilesets[i].source.lastIndexOf('/') + 1, rawMapData.tilesets[i].source.lastIndexOf('.')),
                offset: rawMapData.tilesets[i].firstgid,
            };
            tileSets.push(tsdata);
        }
        this.layers = [];
        this.layerNames = [];
        // we can't find special tiles without a tileset, so we have a separate function for that
        this.specialTiles = [];
        this.configData = [];
        var maxGx = -100000000;
        var maxGy = -100000000;
        var minGx = 100000000;
        var minGy = 100000000;
        for (let l = 0; l < rawMapData.layers.length; l++) {
            this.layerNames.push(rawMapData.layers[l].name);
            // We now support a config layer that should always be the top most.
            // Just put rectangles 0 width, 0 height over the tile you want to enter config data for,
            // for example you can set the text of a sign or specify where a door leads etc.
            if (rawMapData.layers[l].name === 'Config-Layer') {
                /* We need to know the zeroXOff and zerYOff before we process the config layer...
                for (let o = 0; o < rawMapData.layers[l].objects.length; o++) {
                    let configObj = {
                        gx: Math.floor(rawMapData.layers[l].objects[o].x / rawMapData.tilewidth),
                        gy: Math.floor(rawMapData.layers[l].objects[o].y / rawMapData.tileheight),
                    };
                    for (let p = 0; p < rawMapData.layers[l].objects[o].properties.length; p++) {
                        configObj[rawMapData.layers[l].objects[o].properties[p].name] = rawMapData.layers[l].objects[o].properties[p].value;
                    }
                    this.configData.push(configObj);
                }
                */
            } else {
                if (rawMapData.layers[l].type === 'tilelayer') {
                    for (let c = 0; c < rawMapData.layers[l].chunks.length; c++) {
                        if (rawMapData.layers[l].chunks[c].x + rawMapData.layers[l].chunks[c].width > maxGx) {
                            maxGx = rawMapData.layers[l].chunks[c].x + rawMapData.layers[l].chunks[c].width;
                        }
                        if (rawMapData.layers[l].chunks[c].y + rawMapData.layers[l].chunks[c].height > maxGy) {
                            maxGy = rawMapData.layers[l].chunks[c].y + rawMapData.layers[l].chunks[c].height;
                        }
                        if (rawMapData.layers[l].chunks[c].x < minGx) {
                        minGx = rawMapData.layers[l].chunks[c].x;
                        }
                        if (rawMapData.layers[l].chunks[c].y < minGy) {
                            minGy = rawMapData.layers[l].chunks[c].y;
                        }
                    }
                }
            }
        }
        var zeroXOff = minGx;
        var zeroYOff = minGy;
        var mapWidth = maxGx - minGx;
        var mapHeight = maxGy - minGy;
        /* // now process the config layer
        for (let l = 0; l < rawMapData.layers.length; l++) {
            // We now support a config layer that should always be the top most.
            // Just put rectangles 0 width, 0 height over the tile you want to enter config data for,
            // for example you can set the text of a sign or specify where a door leads etc.
            if (rawMapData.layers[l].name === 'Config-Layer') {
                for (let o = 0; o < rawMapData.layers[l].objects.length; o++) {
                    let configObj = {
                        gx: Math.floor(rawMapData.layers[l].objects[o].x / rawMapData.tilewidth) - zeroXOff,
                        gy: Math.floor(rawMapData.layers[l].objects[o].y / rawMapData.tileheight) - zeroYOff,
                        pixelx: rawMapData.layers[l].objects[o].x - (rawMapData.layers[1].height - 1) * rawMapData.tilewidth / 2,
                        pixely: rawMapData.layers[l].objects[o].y,
                    };
                    for (let p = 0; p < rawMapData.layers[l].objects[o].properties.length; p++) {
                        configObj[rawMapData.layers[l].objects[o].properties[p].name] = rawMapData.layers[l].objects[o].properties[p].value;
                        if (typeof configObj['exit_dir'] === 'string') {
                            configObj['exit_dir'] = JSON.parse(configObj['exit_dir']);
                        }
                    }
                    this.configData.push(configObj);
                }
            }
        } */
        // console.log('chunk bounds: ', minGx, minGy, maxGx, maxGy, mapWidth, mapHeight);
        var tilesMaxGx = -100000000;
        var tilesMaxGy = -100000000;
        var tilesMinGx = 100000000;
        var tilesMinGy = 100000000;
        var firstTileGx = 100000000;
        var firstTileGy = 0;
        var lastTileGx = -100000000;
        var lastTileGy = 0;
        var firstPlatformGx = null;
        var firstPlatformGy = null;
        var lastPlatformGx = null;
        var lastPlatformGy = null;
        var highestPlatformGy = null;
        var lowestPlatformGy = null;
        for (let l = 0; l < rawMapData.layers.length; l++) {
            if (rawMapData.layers[l].name === 'Config-Layer') {
                continue;
            }
            if (rawMapData.layers[l].type !== 'tilelayer') {
                // console.log('Layer ' + l + ', object layer: ', rawMapData.layers[l]);
                this.layers.push({objects: rawMapData.layers[l].objects});
                if (rawMapData.layers[l].type === 'objectgroup') {
                    for (let o = 0; o < rawMapData.layers[l].objects.length; o++) {
                        let tsToUse = 0;
                        for (let i = 0; i < tileSets.length; i++) {
                            // console.log('find tile set');
                            if (tileSets[i].offset >= tileSets[tsToUse].offset && rawMapData.layers[l].objects[o].gid >= tileSets[i].offset) {
                                tsToUse = i;
                                // console.log('found tile set: ', i);
                            }
                        }

                        let obj = {
                            gid: rawMapData.layers[l].objects[o].gid,
                            rotation: rawMapData.layers[l].objects[o].rotation,
                            x: rawMapData.layers[l].objects[o].x,
                            y: rawMapData.layers[l].objects[o].y,
                            width: rawMapData.layers[l].objects[o].width,
                            height: rawMapData.layers[l].objects[o].height,
                            layerName: rawMapData.layers[l].name,

                            tileSet: tileSets[tsToUse].name,
                            tile: rawMapData.layers[l].objects[o].gid - tileSets[tsToUse].offset,
                            mapChunk: this,
                            layer: l,

                            rawObjectData: rawMapData.layers[l].objects[o],
                        };
                        this.objectLayersData.push(obj);
                    }
                }
            } else {
                // console.log('Layer ' + l + ', tile layer: ', rawMapData.layers[l]);
                this.layers.push([]);
                while (this.layers[l].length <= mapWidth) {
                    this.layers[l].push([]);
                }
                for (var ntx = 0; ntx < mapWidth; ntx++) {
                    while (this.layers[l][ntx].length <= mapHeight) {
                        this.layers[l][ntx].push(null);
                    }
                }
                // console.log('blank layer: ', l, this.layers[l]);
                for (let c = 0; c < rawMapData.layers[l].chunks.length; c++) {
                    // console.log('chunk: ', c, rawMapData.layers[l].chunks[c], rawMapData.layers[l].chunks[c].data);
                    for (var d = 0; d < rawMapData.layers[l].chunks[c].data.length; d++) {
                        // console.log('data: ', d, rawMapData.layers[l].chunks[c].data[d])
                        if (rawMapData.layers[l].chunks[c].data[d] > 0) {
                            var tx = (rawMapData.layers[l].chunks[c].x - zeroXOff) + (d % rawMapData.layers[l].chunks[c].width);
                            var ty = (rawMapData.layers[l].chunks[c].y - zeroYOff) + Math.floor(d / rawMapData.layers[l].chunks[c].width);
                            if (tx < tilesMinGx) tilesMinGx = tx;
                            if (tx > tilesMaxGx) tilesMaxGx = tx;
                            if (ty < tilesMinGy) tilesMinGy = ty;
                            if (ty > tilesMaxGy) tilesMaxGy = ty;
                            if (tx < firstTileGx) {
                                firstTileGx = tx;
                                firstTileGy = ty;
                            }
                            if (tx > lastTileGx) {
                                lastTileGx = tx;
                                lastTileGy = ty;
                            }
                            var tsToUse = 0;
                            for (let i = 0; i < tileSets.length; i++) {
                                // console.log('find tile set');
                                if (tileSets[i].offset >= tileSets[tsToUse].offset && rawMapData.layers[l].chunks[c].data[d] >= tileSets[i].offset) {
                                    tsToUse = i;
                                    // console.log('found tile set: ', i);
                                }
                            }
                            this.layers[l][tx][ty] = {
                                        tileSet: tileSets[tsToUse].name,
                                        tile: rawMapData.layers[l].chunks[c].data[d] - tileSets[tsToUse].offset,
                                        mapChunk: this,
                                        layer: l,
                                    };
                            
                            if (ProcessedTilesets && ProcessedTilesets[tileSets[tsToUse].name]) {
                                const tileData = ProcessedTilesets[tileSets[tsToUse].name].getTile(rawMapData.layers[l].chunks[c].data[d] - tileSets[tsToUse].offset);
                                // console.log('Tiledata: ', tileData);
                                if (tileData && tileData.platform) {
                                    // console.log('Tiledata: ', tileData);
                                    // This is a platform!
                                    if (firstPlatformGx === null || tx < firstPlatformGx) {
                                        firstPlatformGx = tx;
                                        firstPlatformGy = ty;
                                    }
                                    if (lastPlatformGx === null || tx > lastPlatformGx) {
                                        lastPlatformGx = tx;
                                        lastPlatformGy = ty;
                                    }
                                    if (highestPlatformGy === null || ty > highestPlatformGy) {
                                        highestPlatformGy = ty;
                                    }
                                    if (lowestPlatformGy === null || ty < lowestPlatformGy) {
                                        lowestPlatformGy = ty;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // trim the data
        var actualChunkWidth = tilesMaxGx - tilesMinGx;
        var actualChunkHeight = tilesMaxGy - tilesMinGy;
        // console.log('trim data: ', tilesMinGx, tilesMinGy, tilesMaxGx, tilesMaxGy, actualChunkWidth, actualChunkHeight);
        this.width = actualChunkWidth;
        this.height = actualChunkHeight;
        this.minGx = tilesMinGx;
        this.minGy = this.lowestTileYOffset = tilesMinGy;
        this.maxGx = tilesMaxGx;
        this.maxGy = this.highestTileYOffset = tilesMaxGy;
        this.firstTileYOffset = firstTileGy;
        this.lastTileYOffset = lastTileGy;
        this.firstPlatformYOffset = firstPlatformGy;
        this.lastPlatformYOffset = lastPlatformGy;
        this.highestPlatformYOffset = highestPlatformGy;
        this.lowestPlatformYOffset = lowestPlatformGy;
        
        for (let l = 0; l < this.layers.length; l++) {
            
            for (let i = 0; i < tilesMinGx; i++) {
                this.layers[l].shift();
            }
            for (let i = 0; i < this.layers[l].length; i++) {
                for (var j = 0; j < tilesMinGy; j++) {
                    this.layers[l][i].shift();
                }
            }
            
            // I'm cheating a bit here by leaving a buffer at these edges cos
            // there was a random bug where tiles would be trimmed that shouldn't be
            var trimX = this.layers[l].length - actualChunkWidth;
            if (trimX > 1) {
                for (let i = 0; i < trimX - 1; i++) {
                    this.layers[l].pop();
                }
            }
            
            for (let gx = 0; gx < this.layers[l].length; gx++) {
                var trimY = this.layers[l][gx].length - actualChunkHeight;
                if (trimY > 1) {
                    for (let i = 0; i < trimY - 1; i++) {
                        this.layers[l][gx].pop();
                    }
                }
            }
            
            // console.log('layer ' + l + ': ', this.layers[l]);
            for (let gx = 0; gx < this.layers[l].length; gx++) {
                for (let gy = 0; gy < this.layers[l][gx].length; gy++) {
                    if (this.layers[l][gx][gy] != null) {
                        this.layers[l][gx][gy].gridX = gx;
                        this.layers[l][gx][gy].gridY = gy;
                    }
                }
            }
        }

        // now process the config layer
        for (let l = 0; l < rawMapData.layers.length; l++) {
            // We now support a config layer that should always be the top most.
            // Just put rectangles 0 width, 0 height over the tile you want to enter config data for,
            // for example you can set the text of a sign or specify where a door leads etc.
            if (rawMapData.layers[l].name === 'Config-Layer') {
                for (let o = 0; o < rawMapData.layers[l].objects.length; o++) {
                    let configObj = {
                        gx: Math.floor(rawMapData.layers[l].objects[o].x / rawMapData.tilewidth) - zeroXOff,
                        gy: Math.floor(rawMapData.layers[l].objects[o].y / rawMapData.tileheight) - zeroYOff,
                        isogx: rawMapData.layers[l].objects[o].isogx,
                        isogy: rawMapData.layers[l].objects[o].isogy,
                    };
                    for (let p = 0; p < rawMapData.layers[l].objects[o].properties.length; p++) {
                        configObj[rawMapData.layers[l].objects[o].properties[p].name] = rawMapData.layers[l].objects[o].properties[p].value;
                        if (typeof configObj['exit_dir'] === 'string') {
                            configObj['exit_dir'] = JSON.parse(configObj['exit_dir']);
                        }
                    }
                    this.configData.push(configObj);
                }
            }
        }
    }

    getSurroundingTilesFromGridPos(gridX, gridY, radiusToGet, tileSet, spriteGrid, ignoreLayers) {
        // console.log('mapchunk gx, gy: ', gridX, gridY, radiusToGet);
        var tilesFound = [];
        gridX -= radiusToGet;
        gridY -= radiusToGet;
        var gridEndX = gridX + radiusToGet * 2;
        var gridEndY = gridY + radiusToGet * 2;
        if (gridEndX < 0 || gridEndY < 0 || gridX > this.layers[0].length || gridY > this.layers[0][0].length) {
            return [];
        }
        if (gridX < 0) gridX = 0;
        if (gridY < 0) gridY = 0;
        if (gridEndX > this.layers[0].length - 1) gridEndX = this.layers[0].length - 1;
        if (gridEndY > this.layers[0][0].length - 1) gridEndY = this.layers[0][0].length - 1;
        for (let l = 0; l < this.layers.length; l++) {
            if (ignoreLayers && ignoreLayers.lastIndexOf(l) >= 0) continue;
            for (var gx = gridX; gx <= gridEndX; gx++) {
                for (var gy = gridY; gy <= gridEndY; gy++) {
                    if (this.layers[l] && this.layers[l][gx] && this.layers[l][gx][gy] != null) {
                        tilesFound.push({tile: this.layers[l][gx][gy], sprite: spriteGrid[l][gx][gy], tileConfig: tileSet[this.layers[l][gx][gy].tileSet].configLookup[this.layers[l][gx][gy].tile]});
                    }
                }
            }
        }
        // console.log('mapChunk tilesfound: ', tilesFound);
        return tilesFound;
    }

    findSpecialTiles(tileSets) {
        this.specialTiles = [];
        for (let l = 0; l < this.layers.length; l++) {
            for (var gx = 0; gx < this.layers[l].length; gx++) {
                for (var gy = 0; gy < this.layers[l][gx].length; gy++) {
                    if (this.layers[l] && this.layers[l][gx] && this.layers[l][gx][gy] != null) {
                        if (tileSets[this.layers[l][gx][gy].tileSet].configLookup[this.layers[l][gx][gy].tile].special) {
                            this.specialTiles.push({layer: l, gridX: gx, gridY: gy, config: tileSets[this.layers[l][gx][gy].tileSet].configLookup[this.layers[l][gx][gy].tile]});
                        }
                    }
                }
            }
        }
    }
}

/* Process all the map chunks that have been loaded in.
* If you use the .js file format from tiles, then these raw data will be in a global array called TileMaps,
* in the demo I've opted to use Tiled's json format for this instead.
* we're putting it in to a global object called ProcessedMapChunks, the prop for each map is the filename in Tiled (minus the extension).
* ChunksByDifficulty is used for random endless games, using our randomised mappers.
*/
export const ProcessedMapChunks = {};
export const ChunksByDifficulty = [];
export const ChunksByWorldAndDifficulty = [];

/**
 * Process loaded tilemaps. If you are using the .js format, this data is available in the global TileMaps object.
 * However, if you are using the .json format (recommended for es6), you will need to run these through CreateMapChunksObjectFromJsonData
 * first to get them in to the correct object format and ensure the names of the map chunks are correct.
 * The processed map data is put in the above arrays (ProcessedMapChunks and ChunksByDifficulty) which can be imported and used whereever you need.
 * @param {object} tileMaps 
 */
export function ProcessLoadedMapChunks(tileMaps) {
    for (var prop in tileMaps) {
        // console.log('Processing map chunk: ', prop);
        ProcessedMapChunks[prop] = new MapChunk(prop, tileMaps[prop]);
    }
    console.log('Processed Map Chunks: ', ProcessedMapChunks);
    // ChunksByDifficulty = [];
    for (let prop in ProcessedMapChunks) {
        let world = 1;
        let difficulty = 1;
        if (typeof ProcessedMapChunks[prop].world != 'undefined') {
            world = ProcessedMapChunks[prop].world;
            if (world < 1) world = 1;
        }
        if (typeof ProcessedMapChunks[prop].difficulty != 'undefined') {
            difficulty = ProcessedMapChunks[prop].difficulty;
            if (difficulty < 1) difficulty = 1;
        }
        while (ChunksByWorldAndDifficulty.length < world) {
            ChunksByWorldAndDifficulty.push([]);
        }
        while (ChunksByWorldAndDifficulty[world - 1].length < difficulty) {
            ChunksByWorldAndDifficulty[world - 1].push([]);
        }
        ChunksByWorldAndDifficulty[world - 1][difficulty - 1].push(ProcessedMapChunks[prop]);
        while (ChunksByDifficulty.length < difficulty) {
            ChunksByDifficulty.push([]);
        }
        ChunksByDifficulty[difficulty - 1].push(ProcessedMapChunks[prop]);
    }
    console.log('chunks by world and diff: ', ChunksByWorldAndDifficulty);
    console.log('chunks by diff: ', ChunksByDifficulty);
}

/**
 * If using json data you will need to ensure the map data is placed in an object of the correct format for Processing.
 * Essentially we have the json data in a js file and export it from there. We can then import it in to our project and push
 * all the map data we want to use in to an array which is passed in here and then passed through to ProcessLoadedMapChunks for final processing.
 * @param {array} arrayOfJsonData 
 */
export function ProcessMapChunksFromJsonData(arrayOfJsonData, mapNamesArray = undefined) {
    let tileMapsReadyForProcessing = {};
    let mapNames = [];
    for (let i = 0; i < arrayOfJsonData.length; i++) {
        let name = '';
        if (arrayOfJsonData[i].properties) {
            for (let j = 0; j < arrayOfJsonData[i].properties.length; j++) {
                if (arrayOfJsonData[i].properties[j].name === 'name') {
                    name = arrayOfJsonData[i].properties[j].value;
                    // console.log('map name pulled from properties: ', name);
                    break;
                }
            }
        }
        if (name === '') {
            if (mapNamesArray && mapNamesArray[i]) {
                name = mapNamesArray[i];
            } else {
                name = arrayOfJsonData[i].editorsettings.export.target.split('.')[0];
                name = name.split('/');
                name = name[name.length - 1];
            }
        }
        tileMapsReadyForProcessing[name] = arrayOfJsonData[i];
        mapNames.push(name);
    }
    ProcessLoadedMapChunks(tileMapsReadyForProcessing);
    return mapNames;
}

/**
 * If you want to unload your map chunks...
 */
export function UnloadMapChunks() {
    for (let prop in ProcessedMapChunks) {
        ProcessedMapChunks[prop] = null;
        delete ProcessedMapChunks[prop];
    }
    // ProcessedMapChunks = {};
    while (ChunksByDifficulty.length > 0) {
        ChunksByDifficulty.pop();
    }
    // ChunksByDifficulty = [];
}

/**
 * You should call this after processing your map data with either ProcessMapChunksFromJsonData or ProcessLoadedMapChunks.
 * It will make it easier to find special tiles such as state switchers or moving objects later.
 * @param {object} tileSets - most likely the ProcessedTileSets object exported from TileSet.js
 */
export function DiscoverMapChunkSpecialTiles(tileSets) {
    for (let i = 0; i < ProcessedMapChunks.length; i++) {
        ProcessedMapChunks[i].findSpecialTiles(tileSets);
    }
}