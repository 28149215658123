import app from './app'

export class clipManager {
	id = 0;
	anims = null;
	movieClip = null;
	activeAnim =  null;
	events = null;
	app = null;
    
	constructor(mc, anims, animationSpeed, events){
		this.id = Date.now();
		this.movieClip = mc;
		
		this.events = events;
		
        this.movieClip.loop = false;
		this.movieClip.animationSpeed = animationSpeed ? animationSpeed : 1;
		
		this.anims = anims;
		this.activeAnim = anims[0];
		
		app.addUpdateListener(this.id, function(){
			this.update();
		}.bind(this));
    }
    
	gotoAndStop(anim) {
		this.activeAnim = anim;
		this.movieClip.gotoAndStop(this.anims[anim][1]);
    }

	gotoAndPlay(anim) {
		this.activeAnim = anim;
		this.movieClip.gotoAndPlay(this.anims[anim][1]);
    }
    
    gotoAndPlayRandom(anim) {
		this.activeAnim = anim;
        var randomNumber = this.anims[anim][1] + Math.floor(Math.random() * (this.anims[anim][2] - this.anims[anim][1])) ;
		this.movieClip.gotoAndPlay(randomNumber);
    }
    
    update(skipPlayingCheck) {
		if(!this.movieClip.playing && !skipPlayingCheck) return;
		
		var a = this.anims[this.activeAnim];
		
		//shouldnt loop
		if(!a[0] && this.movieClip._currentTime >= a[2]) {
			this.movieClip._currentTime = a[2];
			this.movieClip.stop();
			if(this.events && this.events.complete) this.events.complete.call(this, this);
		}
		//should loop
		else if(a[0] && this.movieClip._currentTime >= a[2]) {
			this.movieClip._currentTime -= a[2]-a[1];
			if(a[0] && this.movieClip._currentTime >= a[2]) {
				this.update();
				if(this.events && this.events.loop) this.events.loop.call(this, this);
				this.movieClip.update(0);
				return;
			}
			if(this.events && this.events.loop) this.events.loop.call(this, this);
		}
		
		this.movieClip.update(0);
    }
    
    destroy() {
        this.movieClip.destroy();
        this.anims = null;
        app.removeUpdateListener(this.id);
    }
}