/** @author Carl Trelfa
 * 
 */

import app from '../app'
import * as PIXI from 'pixi.js'

export class SnowController {
    settings = {
        snowflakeCount: 120,
        windDirection: 1,
    
        flakeSpeedXMin: 1,
        flakeSpeedXVariation: 3,
        flakeSpeedYMin: 2,
        flakeSpeedYVariation: 6,
    
        waveXMaxDeviationMin: 7,
        waveXMaxDeviationVariation: 3,
        waveYMaxDeviationMin: 4,
        waveYMaxDeviationVariation: 2,
        
        waveXMaxVelocityMin: 1,
        waveXMaxVelocityVariation: 2,
        waveYMaxVelocityMin: 1,
        waveYMaxVelocityVariation: 2,
    
        waveXAccelerationMin: 0.1,
        waveXAccelerationVariation: 0.4,
        waveYAccelerationMin: 0.1,
        waveYAccelerationVariation: 0.3,
    
        flakeScaleMin: 0.4,
        flakeScaleVariation: 0.8,
    
        flakeAlphaMin: 0.6,
        flakeAlphaVariation: 0.4,
    };

    mainContainer = null;
    snowContainer = null;
    snowFlakes = null;

    inited = false;

    /**
     * Initialise the snow.
     * @param {PIXI.Container} container - the display object that will hold your snow
     * @param {Object} options - a bunch of config options
     * 
     * See the settings property for a full list of options, notable options:
     * 
     *  snowflakeCount = default 120
     *  windDirection = 1 for left to right, -1 for right to left
     *  flakeSpeedXMin: 1, flakeSpeedXVariation: 3 = effectively the wind speed, 
     *                  although you could use bigger / smaller values for windDirection instead
     *  flakeScaleMin: 0.4, flakeScaleVariation: 0.8 = pretty obvious what these do
     *  flakeAlphaMin: 0.6, flakeAlphaVariation: 0.4 = pretty obvious what these do
     */
    init(container, options = {}) {
        for (let p in options) {
            this.settings[p] = options[p];
        }

        this.mainContainer = container;
        this.snowContainer = new PIXI.ParticleContainer();
        container.addChild(this.snowContainer);
        this.snowFlakes = [];
        for (var i = 0; i < this.settings.snowflakeCount; i++) {
            var oneFlake = new SnowFlakeController();
            oneFlake.init(this.settings);
            this.snowContainer.addChild(oneFlake.sprite);
            this.snowFlakes.push(oneFlake);
        }
        this.inited = true;
    }

    update($delta) {
        if (!this.inited) {
            return;
        }
        // update stuff
        // delta is currently worked for 30fps, even though we are really running at 60fps
        for (var i = 0; i < this.settings.snowflakeCount; i++) {
            this.snowFlakes[i].update($delta);
        }
    }

    scroll(amount) {
        if (!this.inited) {
            return;
        }
        for (var i = 0; i < this.snowflakeCount; i++) {
            this.snowFlakes[i].scroll(amount);
        }
    }

    destroy() {
        // console.log('destroy snow');
        for (var i = 0; i < this.snowflakeCount; i++) {
            this.snowFlakes[i].sprite.parent.removeChild(this.snowFlakes[i].sprite);
            this.snowFlakes[i].sprite.destroy();
        }
        this.snowFlakes = null;
        this.snowContainer.parent.removeChild(this.snowContainer);
        this.snowContainer.destroy();
        this.snowContainer = null;

        this.inited = false;
    }
}

class SnowFlakeController {
    settings = null;

    // the particle x, y and direction
    px = 0;
    py = 0;
    dx = 0;
    dy = 0;

    // a bit of a wave
    wx = 0;
    wy = 0;
    wxDir = 0;
    wxVel = 0;
    wxAcc = 0;
    wxMax = 0;
    wxMaxVel = 0;
    wyDir = 0;
    wyVel = 0;
    wyMax = 0;
    wyMaxVel = 0;
    wyAcc = 0;

    sprite = null;

    init(settings) {
        this.settings = settings;

        var s = app.guiSize;
        this.px = Math.random() * s.width;
        this.py = Math.random() * s.height;

        this.sprite = PIXI.Sprite.from('snowflake.png');
        this.sprite.x = this.px + this.wx;
        this.sprite.y = this.py + this.wy;

        this.randomiseMovement();
    }

    randomiseMovement() {
        this.dx = (Math.random() * this.settings.flakeSpeedXVariation + this.settings.flakeSpeedXMin) * this.settings.windDirection;
        this.dy = Math.random() * this.settings.flakeSpeedYVariation + this.settings.flakeSpeedYMin;
        this.wxMax = Math.random() * this.settings.waveXMaxDeviationVariation + this.settings.waveXMaxDeviationMin;
        this.wyMax = Math.random() * this.settings.waveYMaxDeviationVariation + this.settings.waveYMaxDeviationMin;;
        this.wxMaxVel = Math.random() * this.settings.waveXMaxVelocityVariation + this.settings.waveXMaxVelocityMin;
        this.wyMaxVel = Math.random() * this.settings.waveYMaxVelocityVariation + this.settings.waveYMaxVelocityMin;
        this.wxVel = Math.random() * this.wxMaxVel;
        this.wyVel = Math.random() * this.wyMaxVel;
        this.wxAcc = Math.random() * this.settings.waveXAccelerationVariation + this.settings.waveXAccelerationMin;
        this.wyAcc = Math.random() * this.settings.waveYAccelerationVariation + this.settings.waveYAccelerationMin;
        this.wxDir = Math.random() < 0.5 ? -1 : 1;
        this.wyDir = Math.random() < 0.5 ? -1 : 1;

        this.sprite.scale.x = this.sprite.scale.y = this.settings.flakeScaleMin + Math.random() * this.settings.flakeScaleVariation;
        this.sprite.alpha = this.settings.flakeAlphaMin + Math.random() * this.settings.flakeAlphaVariation;
    }

    update($delta) {
        this.px += this.dx * $delta;
        this.py += this.dy * $delta;

        this.wx += this.wxVel * $delta;
        if ( (this.wx > this.wxMax && this.wxDir > 0) || (this.wx < -this.wxMax && this.wxDir < 0) ) {
            this.wxDir = -this.wxDir;
        }
        this.wxVel += this.wxAcc * this.wxDir * $delta;
        if (Math.abs(this.wxVel) > this.wxMaxVel) {
            this.wxVel = this.wxMaxVel * this.wxDir;
        }
        this.wy += this.wyVel * $delta;
        if ( (this.wy > this.wyMax && this.wyDir > 0) || (this.wy < -this.wyMax && this.wyDir < 0) ) {
            this.wyDir = -this.wyDir;
        }
        this.wyVel += this.wyAcc * this.wyDir * $delta;
        if (Math.abs(this.wyVel) > this.wyMaxVel) {
            this.wyVel = this.wyMaxVel * this.wyDir;
        }

        this.wrap();
        this.sprite.x = this.px + this.wx;
        this.sprite.y = this.py + this.wy;
    }

    scroll(amount) {
        var scrollScale = 1 + (this.sprite.scale.x - this.SMALLEST_FLAKE_SCALE);
        this.py -= amount * scrollScale;
    }

    wrap() {
        var reRandomise = false;
        if (this.px < 0) {
            this.px += app.guiSize.width;
            reRandomise = true;
        }
        if (this.px > app.guiSize.width) {
            this.px -= app.guiSize.width;
            reRandomise = true;
        }
        if (this.py < 0) {
            this.py += app.guiSize.height;
            reRandomise = true;
        }
        if (this.py > app.guiSize.height) {
            this.py -= app.guiSize.height;
            reRandomise = true;
        }
        if (reRandomise) {
            this.randomiseMovement();
        }
    }
}