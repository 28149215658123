/**
 * Bootstrap our game - this component uses a componentDidMount event to kick everything in to life.
 * Note that is you want to use the Click Engine / Penna example or any other game starter example,
 * make sure you check out the correct branch. I'm keeping examples seperate so you don't pull in too
 * much extra stuff like assets etc.
 * @author Carl Trelfa
 */
import React from 'react'
import app from './koko-framework/app'
import koko from './koko-framework/koko';
import { useContext, setState } from 'react';
import ReactViewController from './koko-framework/ui/react-views/ReactViewController';
import main from './main'
import BasicReactView from './react-view/BasicReactView';
import VideoFeatureView from './react-view/VideoFeatureView';
import SelectGameModeView from './react-view/SelectGameModeView';
import BehindCanvasReactView from './react-view/BehindCanvasReactView';
import FloatingPanelView from './react-view/FloatingPanelView';
import { MAP_SETUP } from './randomised-runner/model/map-setup/map-setup';
import { TwitchContext, TwitchProvider } from './contexts/TwitchContext';
import BackgroundView from './react-view/BackgroundView';
import { BACKGROUND_STATE } from './model/config';
import MainMenuView from './react-view/MainMenuView';
// Penna example - un-comment to add in Penna views...
// import PennaBootstrap from './koko-framework/click-engine/orth/penna/PennaBootstrap';
import { GAME_CONTROLLER } from './randomised-runner/controller/randomised-runner-game-controller'
import GameHUDView from './react-view/GameHUDView';
import { v } from './koko-framework/shorthand';
import InGameHud from './react-view/InGameHud';
import ReviveView from './react-view/ReviveView';
import GameOverView from './react-view/GameOverView';
import textLocaliser from './koko-framework/ui/TextLocaliser';
import { texts } from './randomised-runner/model/randomised-runner-text';
import HelpScreens from './react-view/help-screens/HelpScreens';
import MainMenuStarsBgView from './react-view/MainMenuStarsBgView';
import Leaderboard from './react-view/Leaderboard';
import GameOverPlayButtonView from './react-view/GameOverPlayButton';
import FirstPreloadView from './react-view/FirstPreloadView';
import YTBackgroundView from './react-view/YTBackgroundView';
import BufferingMessage from './react-view/BufferingMessage';
import BufferingBgView from './react-view/BufferingBgView';

import * as PIXI from 'pixi.js';

export const REACT_VIEW_CONTROLLER = new ReactViewController();
export default class Bootstrap extends React.Component {

    static contextType = TwitchContext
    constructor(props, context) {
        super(props, context);

        this.state = {
            bootstrapped: false,
            twitchPayload: {},
            gameMode: '',
            isGamePlaying: false,
            background: BACKGROUND_STATE.LOGIN,
            backgroundView_active: true,
        };


        REACT_VIEW_CONTROLLER.init(this);
        REACT_VIEW_CONTROLLER.addViewId('videoFeature');
        REACT_VIEW_CONTROLLER.addViewId('backgroundView');
        REACT_VIEW_CONTROLLER.addViewId('gameHUD');
        REACT_VIEW_CONTROLLER.addViewId('gameover_gameHUD');
        REACT_VIEW_CONTROLLER.addViewId('ingameHUD');
        REACT_VIEW_CONTROLLER.addViewId('menuStarsBgView');
        REACT_VIEW_CONTROLLER.addViewId('mainMenu');
        REACT_VIEW_CONTROLLER.addViewId('gameMode');
        REACT_VIEW_CONTROLLER.addViewId('reviveView');
        REACT_VIEW_CONTROLLER.addViewId('gameoverView');
        REACT_VIEW_CONTROLLER.addViewId('gameoverViewPlayButton');
        REACT_VIEW_CONTROLLER.addViewId('helpScreens');
        REACT_VIEW_CONTROLLER.addViewId('leaderboard');
        REACT_VIEW_CONTROLLER.addViewId('gameover_leaderboard');

        REACT_VIEW_CONTROLLER.addViewId('bufferingMess');
        REACT_VIEW_CONTROLLER.addViewId('bufferingBg');

        REACT_VIEW_CONTROLLER.addViewId('basicview');
        REACT_VIEW_CONTROLLER.addViewId('examplepanel', this.positionExamplePanel);
        REACT_VIEW_CONTROLLER.addViewId('behindcanvas');
    }

    positionExamplePanel = () => {
        let vp = app.viewPort;
        let panelInstance = koko.views.get('examplepanel');
        panelInstance.setPosition(vp.centerX + Math.floor(Math.random() * 200) - 200, vp.centerY + Math.floor(Math.random() * 600) - 300);
    }


    parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
    componentDidUpdate() {
        if (!this.context.state.isLoggedIn) {
            if (this.state.background != BACKGROUND_STATE.LOGIN) {
                this.setState({
                    background: BACKGROUND_STATE.LOGIN
                })
            }
        }
        this.checkUserLogin();




    }

    loadingGame = false;
    componentDidMount() {
        /*  const script = document.createElement("script");
          script.src = "https://analytics.m7g.twitch.tv/77e11fef789668ab2024/";
          script.async = true;
          document.body.appendChild(script);*/

        if (!this.state.bootstrapped) {
            window.deviceWidth = window.innerWidth;
            window.deviceHeight = window.innerHeight;
            
            if (!main.switchedOff) {
                this.checkUserLogin();
            } else {
                if (!this.loadingGame) {
                    this.loadPixiGame();
                    this.loadingGame = true;
                }
            }
        }
    }

    showMenuBg = () => {
        koko.views.showView('backgroundView');
        this.setState({
            background: BACKGROUND_STATE.MAIN_MENU
        });
    }

    checkUserLogin = () => {
        if (!this.state.bootstrapped) {
            if (this.context.state.twitchData) {
                //console.log('TC:', twitchContext)
                if (this.context.state.twitchData.access_token) {
                    this.setState({
                        background: BACKGROUND_STATE.LOGIN,
                        bootstrapped: true,
                        gameMode: 'streamer'
                    })

                    this.loadPixiGame();
                }
            }
        }
        /* else
        {
            if(this.context.state.twitchData && this.context.state.twitchData.access_token && !this.state.mainMenu_active)
            {
                //main.init();
                if(!this.state.isGamePlaying)
                {
                    koko.views.showView('mainMenu')
                    this.setBackgroundState(BACKGROUND_STATE.MAIN_MENU)  
                }
            }
        } */
    }

    setBackgroundState = (state) => {
        if (!main.switchedOff) {
            this.setState({
                background: state
            })
        } else {
            this.setState({
                background: BACKGROUND_STATE.MAIN_MENU
            })
        }
    }

    setGameState = (isGamePlaying) => {
        this.setState({
            isGamePlaying: isGamePlaying,
            // background: BACKGROUND_STATE.GAME
        })
    }

    loadPixiGame = () => {

        /* This is how it was set up for the demo */
        // app.assetsBase = './';
        // app.bgColor = 0xBBF0FE;  // transparent canvas won't work if you set this
        app.guiSize = { width: 1920, height: 1080 };
        app.minSize = { width: 1920, height: 1080 };
        app.anti_alias = true;
        app.retina = true;
        app.roundPixels = true;
        app.forceFXAA = false;
        app.autoDensity = true;
        app.transparent = true;

        app.init();
        app.setupComplete = function () {
            main.init();
            app.resize();
        }
        app.loaded();
        app.appendRenderer();

        /* View Hiding and Showing is now universal for pixi and react views */
        koko.views.registerReactViewController(REACT_VIEW_CONTROLLER);
        /*
            * You can now do:
            *      koko.views.showView / showViews / showAllViews / showAllViewsBut
            *      koko.views.hideView / hideViews / hideAllViews / hideAllViewsBut
            * 
            * Pixi views will be transitioned in / out and so will React views, 
            * so the same line of code regardless! 
            * 
            * Take a look in the preloader to see this in action.
            */

        let mapSetup = MAP_SETUP;
        console.log('Map setup: ', mapSetup);
        if (!main.switchedOff) {
            this.setBackgroundState(BACKGROUND_STATE.LOADING);
        } else {
            this.setBackgroundState(BACKGROUND_STATE.MAIN_MENU);
        }

        // v.showView('gameoverView');
    }

    startGame = () => {
        koko.audio.fadeSoundTo('menu_music', 0, 2000);
        koko.views.showView('videoFeature');
        koko.views.showView('bufferingBg');
        koko.views.showView('ingameHUD');
        koko.views.hideView('mainMenu');
        koko.views.hideView('menuBgPixi');
        koko.views.hideView('menuStarsBgView');
        koko.views.hideView('gameHUD');
        koko.views.hideView('gameover_gameHUD');
        koko.views.hideView('backgroundView');
        koko.views.hideView('helpScreens');
        koko.views.hideView('leaderboard');
        koko.views.hideView('gameover_leaderboard');
        GAME_CONTROLLER.startGame();
        this.setGameState(true);
    }

    // canvas will be attached inside our game-div
    // will probably need some css, depending on your needs
    render() {
        // if(this.context.state.twitchData && this.context.state.twitchData.access_token) console.log('### TTP ###',this.parseJwt(this.context.state.twitchData.id_token))
        return (
            <>
                {<FirstPreloadView />}

                {main.switchedOff &&
                    <div className='switched-off-bg'></div>
                }

                {/* We can only access our viewport data once bootstrapped! */}
                {/* You might want to comment these 3 lines out for the Penna example, but it will work with them in... */}
                {this.state.backgroundView_active && <BackgroundView viewId="backgroundView" viewController={REACT_VIEW_CONTROLLER} backgroundState={this.state.background}></BackgroundView>}
                {this.state.videoFeature_active && <VideoFeatureView viewId="videoFeature" viewController={REACT_VIEW_CONTROLLER} id='PH_7M62V7RY'></VideoFeatureView>}
              
                {this.state.bufferingBg_active && <BufferingBgView viewId="bufferingBg" viewController={REACT_VIEW_CONTROLLER}></BufferingBgView>}

               {!this.context.state.isLoggedIn && !main.switchedOff && <SelectGameModeView viewId="gameMode" viewController={REACT_VIEW_CONTROLLER}></SelectGameModeView>}
                {this.state.menuStarsBgView_active && <MainMenuStarsBgView viewId="menuStarsBgView" viewController={REACT_VIEW_CONTROLLER}></MainMenuStarsBgView>}
                {this.state.mainMenu_active &&
                    <MainMenuView
                        viewId="mainMenu"
                        viewController={REACT_VIEW_CONTROLLER}
                        startGameFunc={this.startGame}
                    ></MainMenuView>}
                {this.state.reviveView_active &&
                    <ReviveView
                        viewId="reviveView"
                        viewController={REACT_VIEW_CONTROLLER}
                    />
                }
                {this.state.ingameHUD_active &&
                    <InGameHud
                        viewId="ingameHUD"
                        viewController={REACT_VIEW_CONTROLLER}
                    />
                }

                {this.state.gameoverView_active &&
                    <GameOverView
                        viewId="gameoverView"
                        viewController={REACT_VIEW_CONTROLLER}
                    />
                }
                {this.state.gameoverViewPlayButton_active &&
                    <GameOverPlayButtonView
                        viewId="gameoverViewPlayButton"
                        viewController={REACT_VIEW_CONTROLLER}
                    />
                }
                {this.state.helpScreens_active &&
                    <HelpScreens
                        viewId="helpScreens"
                        viewController={REACT_VIEW_CONTROLLER}
                        startGameFunc={this.startGame}
                    ></HelpScreens>
                }
                {this.state.leaderboard_active &&
                    <Leaderboard
                        viewId="leaderboard"
                        viewController={REACT_VIEW_CONTROLLER}
                        gameoverStyle={false}
                        startGameFunc={this.startGame}
                    ></Leaderboard>
                }
                {this.state.gameover_leaderboard_active &&
                    <Leaderboard
                        viewId="gameover_leaderboard"
                        viewController={REACT_VIEW_CONTROLLER}
                        gameoverStyle={true}
                        startGameFunc={this.startGame}
                    ></Leaderboard>
                }

                {this.state.gameHUD_active && this.context.state.isLoggedIn &&
                    <GameHUDView
                        viewId="gameHUD"
                        viewController={REACT_VIEW_CONTROLLER}
                        gameoverStyle={false}
                        startGameFunc={this.startGame}
                    ></GameHUDView>}

                {this.state.gameover_gameHUD_active && this.context.state.isLoggedIn &&
                    <GameHUDView
                        viewId="gameover_gameHUD"
                        viewController={REACT_VIEW_CONTROLLER}
                        gameoverStyle={true}
                        startGameFunc={this.startGame}
                    ></GameHUDView>}

                {this.state.bufferingMess_active &&
                    <BufferingMessage viewId="bufferingMess" viewController={REACT_VIEW_CONTROLLER}></BufferingMessage>
                }

                {/*this.state.basicview_active && <BasicReactView viewId="basicview" viewController={REACT_VIEW_CONTROLLER}></BasicReactView>}
                {this.state.examplepanel_active && <FloatingPanelView viewId="examplepanel" viewController={REACT_VIEW_CONTROLLER}></FloatingPanelView>}
            {this.state.behindcanvas_active && <BehindCanvasReactView viewId="behindcanvas" viewController={REACT_VIEW_CONTROLLER}></BehindCanvasReactView>*/}

                {/* Penna example - un-comment to add in Penna views... */}
                {/* <PennaBootstrap parent={this} reactViewController={REACT_VIEW_CONTROLLER}></PennaBootstrap> */}

            </>
        );
    }
}
