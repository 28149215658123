/**
 * Object Pooler class
 * @author Carl Trelfa
 * 
 * Usage:
 * 
 * You shouldn't ever need to create a new ObjectPooler yourself. Just import op from shorthand:
 * 
 *      import { op } from './koko-framework/shorthand'
 * 
 * Then use:
 * 
 *      op.addObjectToPool(id, object) and op.getObjectFromPool(id)
 * 
 * and
 * 
 *      op.clearPool()
 * 
 * Technically you can pool any type of object, but it is most useful for often used PIXI DisplayObjects.
 * Personally I think you need to beware of pooling absolutely everything. A small amount of often used objects is good,
 * a large amount of rarely used objects, not so good, as it can cause the garbage collector to do lots of extra work.
 */
const MAX_POOL_LENGTH = 40;     // only allow this many of any item to be pooled
class ObjectPooler {
    objectPool = null;

    constructor() {
        this.objectPool = {};
    };

    /**
     * Add an object to our pool.
     * @method addObjectToPool
     * 
     * @param {Number | String} id 
     * @param {Object} object 
     * 
     * @returns {Boolean} True if the object was pooled, false if not (due to MAX_POOL_LENGTH)
     */
    addObjectToPool(id/*string or number, but prefer string*/, object) {
        // make sure our id doesn't contain dots
        id = id.replace('.', '_');
        if (typeof this.objectPool[id] == 'undefined') {
            this.objectPool[id] = [];
        }
        if (this.objectPool[id].length < MAX_POOL_LENGTH) {
            this.objectPool[id].push(object);
            return true;
        }
        return false;
    }

    /**
     * Get an object off the pool.
     * Prepare for it to return null in which case you will need to create a new object.
     * @method getObjectFromPool
     * 
     * @param {Number | String} id 
     * 
     * @returns {Object | null}
     */
    getObjectFromPool(id) {
        // make sure our id doesn't contain dots
        id = id.replace('.', '_');
        if (typeof this.objectPool[id] != 'undefined' && this.objectPool[id].length > 0) {
            return this.objectPool[id].pop();
        }
        return null;
    }

    /**
     * Clear the pool. Do this when you destroy your game.
     * @method clearPool
     */
    clearPool() {
        for (var prop in this.objectPool) {
            for (var i = 0; i < this.objectPool[prop].length; i++) {
                if (typeof this.objectPool[prop][i].destroy == 'function') {
                    this.objectPool[prop][i].destroy();
                }
                this.objectPool[prop][i] = null;
            }
            this.objectPool[prop] = [];
        }
    }
}

// we only ever need a single instance, so here it is!
const objectPooler = new ObjectPooler();
export { objectPooler as default }