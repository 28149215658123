import _ from 'underscore'
import gsap from 'gsap'
import koko from '../koko-framework/koko'
import { v, a } from '../koko-framework/shorthand'
import app from '../koko-framework/app'
import main from '../main'
import * as PIXI from 'pixi.js'
import { setupClicker } from '../koko-framework/ui/ButtonController'
import { GAME_CONTROLLER } from '../randomised-runner/controller/randomised-runner-game-controller'

const reloading = {r: false};
export class PreloaderView extends koko.view {
    constructor () {
        super();

        this.updateLoadingBar = this.updateLoadingBar.bind(this);
        this.finishLoading = this.finishLoading.bind(this);
    }

    assets = [
        { id: 'preloader', src: 'https://d17sm93bt61fgk.cloudfront.net/game_assets/preloader_assets.json' },
    ];

    gfxPerc = 0;

    loadedSounds = false;
    loadedGraphics = false;
    loadedImageAssets = false;

    disabled = false;
    mobileTapToStart = false;
    
    buildView () {
        localStorage.setItem('isGOAT', false)
        if (reloading.r === true) {
            window.location.reload();
            return;
        }

        var vp = app.viewPort;

        this.children = koko.display.buildLayout([
            {name:'loadingBarBack', type:'sprite', id:'PreloadBg', x: vp.centerX - 200, y: vp.centerY + 130, width: 400, height: 40},
            {name:'loadingBarFill', type:'sprite', id:'PreloadFill', x: vp.centerX - 195, y: vp.centerY + 135, width: 390, height: 30},
            // {name:'loadingBarTop', type:'sprite', id:'bar_mask_ld', x: vp.centerX - 136, y: vp.centerY + 150, width: 272, height: 38},

            {name:'playButton', id: 'tapToPlay_btn', type: 'sprite', x: vp.centerX, y: vp.centerY + 265, anchorX: 0.5, anchorY: 0.5, width: 304, height: 68, alpha: 0},
        ], this.container);

        let barMask = new PIXI.Graphics();
        barMask.beginFill(0xff0000, 1);
        barMask.drawRoundedRect(0, 0, 390, 30, 15);
        barMask.endFill();
        barMask.x = vp.centerX - 195;
        barMask.y = vp.centerY + 135;
        this.container.addChild(barMask);
        this.children.barMask = barMask;
        this.children.loadingBarFill.mask = barMask;
        this.children.loadingBarFill.visible = false;

        this.resize();

        console.log("Game Credits:")
        console.log("--------------------------------------------");
        console.log('[KOKO DIGITAL] www.kokodigital.co.uk')
        console.log("--------------------------------------------");
        console.log('%c Game Director:', 'font-weight:bold;')
        console.log('Stuart Howarth')
        console.log('%c Creative Directors:', 'font-weight:bold;')
        console.log('Karl Bloor')
        console.log('Chris Steele')
        console.log('%c Development Lead:', 'font-weight:bold;')
        console.log('Carl Trelfa')
        console.log('%c Creative Lead:', 'font-weight:bold;')
        console.log('Ben Alves')
        console.log('%c Developers:', 'font-weight:bold;')
        console.log('Gerry McGettigan')
        console.log('%c Quality Assurance:', 'font-weight:bold;')
        console.log('Keiron White')
        console.log('Jay Watson')

        console.log = function() {}



        koko.views.showView('YTBackground1');
    }

    resize () {
        // var vp = app.viewPort;
        /* resize code here */
    }

    updateLoadingBar () {
        if (reloading.r === true) {
            window.location.reload();
            return;
        }

        let vp = app.viewPort;
        var p = this.gfxPerc;
        let percDiv = 1;

        if (main.soundsToLoad > 0) {
            // p *= 0.5;
            percDiv++;
            p += koko.audio.loaded / main.soundsToLoad; // * 0.5;
            if (koko.audio.loaded >= main.soundsToLoad) {
                this.loadedSounds = true;
            }
        }
        if (main.reactAssetsToLoad > 0) {
            percDiv++;
            p += main.reactAssetsLoadedCount / main.reactAssetsToLoad;
           // console.log('IMAGES LOADED', main.reactAssetsLoadedCount, main.reactAssetsToLoad);
            if (main.reactAssetsLoadedCount >= main.reactAssetsToLoad) {
                this.loadedImageAssets = true;
                //console.log('IMAGE ASSETS ALL LOADED');
            }
        }

        p /= percDiv;

        // this.children.loadingText.text = Math.floor(p * 100) + '%';
        this.children.loadingBarFill.x = ((vp.centerX - 195) - 390) + p * 390;
        this.children.loadingBarFill.visible = true;

        if (this.loadedSounds && this.loadedGraphics && this.loadedImageAssets) {
            setTimeout(this.finishLoading, 500);
        }

        // I'm not 100% sure why this delayed call is here, but it doesn't seem to work without it for soem reason!
        gsap.killTweensOf(this.finishLoading);
        gsap.delayedCall(1, this.finishLoading);
    }

    transitionIn () {
        if (_.size(this.children) === 0) {
            this.buildView();
            this.show();
        }

        // load the assets
        main.setupAudio();

        this.loadedGraphics = a.manifest.length > 0 ? false : true;
        this.loadedSounds = main.soundsToLoad > 0 ? false : true;
        this.loadedImageAssets = main.reactAssetsToLoad > 0 ? false : true;
        this.loadedTimeWait = false;

        document.body.insertBefore(app.renderer.view, null);

        // load the graphics for the rest of the game
        a.loadManifest(function (perc) {
            this.gfxPerc = perc;
            this.updateLoadingBar();
        }.bind(this), function () {
            this.loadedGraphics = true;
        }.bind(this));


        // load audio
        if (main.soundsToLoad > 0) {
            koko.audio.progressHandler = this.updateLoadingBar;
            koko.audio.load();
        }
        // image assets
        if (main.reactAssetsToLoad > 0) {
            main.reactAssetLoadProgressHandler = this.updateLoadingBar;
        }

        this.show();

        this.container.alpha = 0;
        gsap.to(this.container, {alpha:1, duration: 0.35});

        app.addResizeListener('ingame', function() {
            this.resize();
        }.bind(this));

        if (main.switchedOff) {
            this.container.visible = false;
        }
    }

    finishLoading () {
        gsap.killTweensOf(this.finishLoading);
        if (this.loadedSounds && this.loadedGraphics && this.loadedImageAssets) {
            this.transitionOut()
           // gsap.to(this.children.playButton, {alpha: 1, duration: 0.35});
            //setupClicker(this.children.playButton, this.transitionOut, 'loader-continue');
            // this.transitionOut();
        } else {
            gsap.delayedCall(0.2, this.finishLoading);
        }
    }

    transitionOut = () => {
        // app.goFullScreen();

        this.children.playButton.interactive = false;
        gsap.to(this.container, {alpha:0, onComplete:this.transitionOutDone, duration: 0.35});
    }

    transitionOutDone = () => {
        if (!this.disabled) {
            app.removeResizeListener('ingame');
            while (app.resizeListeners['ingame']) {
                app.removeResizeListener('ingame');
            }

            this.disabled = true;
            this.disable();
            this.destroyView();
            this.hide();

            this.gotoApp();

            reloading.r = true;
        }
    }

    gotoApp () {
        console.log("--------------------------------------------");
        console.log("KOKO ES6 GAME FRAMEWORK - v2.0.1 - LOADED") 
        console.log("--------------------------------------------");
       
        /* the old way */
        // v.each(['demoview'], 'transitionIn');
        // REACT_VIEW_CONTROLLER.showView('basicview');

        /* the new way */
        /* Note, demoview is a pixi based view, the rest are react based views */
        // v.showViews(['demoview', 'basicview', 'examplepanel', 'behindcanvas']);
       
        if (!main.switchedOff) {
            v.showViews(['mainMenu', 'gameHUD']);
        } else {
            v.showViews(['leaderboard']);
        }
       // GAME_CONTROLLER.startGame();
    }
}
