import koko from "../koko-framework/koko";
import app from "../koko-framework/app";
import gsap from "gsap";
import * as PIXI from 'pixi.js'

import { FRAME_TIME, FRAME_DELTA_LIMIT } from "../model/config";
import { GAME_MODE } from "../contexts/TwitchContext";
export class MenuPixiView extends koko.view {
    tweens = [];
    randomStarTimeout = null;
    shown = false;

    buildView() {
        var g = app.guiSize,
            vp = app.viewPort;

        this.children = koko.display.buildLayout(
            [
                {name: 'speedlines_1', type: 'sprite', id: 'speedlines_1', x: 960, y: 540, anchorX: 0.5, anchorY: 0.5, alpha: 0},
                {name: 'speedlines_2', type: 'sprite', id: 'speedlines_2', x: 960, y: 540, anchorX: 0.5, anchorY: 0.5, alpha: 0},
                {name: 'speedlines_3', type: 'sprite', id: 'speedlines_3', x: 960, y: 540, anchorX: 0.5, anchorY: 0.5, alpha: 0},
                {name: 'speedlines_4', type: 'sprite', id: 'speedlines_4', x: 960, y: 540, anchorX: 0.5, anchorY: 0.5, alpha: 0},

                {name: 'stars_container', type: 'container', x: 0, y: 0}
            ],
            this.container
        );

        this.resize();
    }

    resize() {

    }

    transitionIn() {
        if (!this.shown) {
            this.shown = true;

            super.transitionIn();
            this.startUpdate();
            if (this.tweens.length === 0) {
                this.doSpeedlineAnim();
            }
        }
    }

    transitionOutDone = () => {
        while(this.tweens.length > 0) {
            this.tweens.pop().kill();
        }
        clearTimeout(this.randomStarTimeout);
        while (this.children.stars_container.children.length > 0) {
            this.children.stars_container.children[0].tween.kill();
            this.children.stars_container.removeChild(this.children.stars_container.children[0]);
        }
        this.stopUpdate();
        // super.transitionOutDone();

        this.destroyView();
        this.hide();

        this.shown = false;
    }

    doSpeedlineAnim = () => {
        while(this.tweens.length > 0) {
            this.tweens.pop().kill();
        }
        this.children.speedlines_1.alpha = 0.6;

        this.tweens.push(gsap.to(this.children.speedlines_1, {alpha: 0, duration: 0.3}));
        this.tweens.push(gsap.to(this.children.speedlines_2, {alpha: 0.6, duration: 0.3}));
        this.tweens.push(gsap.to(this.children.speedlines_2, {alpha: 0, duration: 0.3, delay: 0.3}));
        this.tweens.push(gsap.to(this.children.speedlines_3, {alpha: 0.6, duration: 0.3, delay: 0.3}));
        this.tweens.push(gsap.to(this.children.speedlines_3, {alpha: 0, duration: 0.3, delay: 0.6}));
        this.tweens.push(gsap.to(this.children.speedlines_4, {alpha: 0.6, duration: 0.3, delay: 0.6}));
        this.tweens.push(gsap.to(this.children.speedlines_4, {alpha: 0, duration: 0.3, delay: 0.9}));
        this.tweens.push(gsap.to(this.children.speedlines_1, {alpha: 0.6, duration: 0.3, delay: 0.9, onComplete: this.doSpeedlineAnim}));
    }

    addRandomStar = () => {
        if (app.windowInFocus) {
            const starScale = Math.random() * 0.75 + 0.25;

            let imageFile = GAME_MODE.isGOAT ? 'img/shooting_star_goat.png' : 'shooting_star_1.png'
            const star = PIXI.Sprite.from(imageFile);
            //const star = PIXI.Sprite.from(starScale > 0.6 ? 'shooting_star_1.png' : 'shooting_star_2.png');
            const topStar = Math.random() > 0.25;
            star.x = topStar ? Math.random() * 1920 : 1920;
            star.y = !topStar ? Math.random() * 1080 : 0;
            star.anchor.x = 0;
            star.anchor.y = 1;
            star.scale.x = star.scale.y = starScale > 0.6 ? starScale : starScale * 1.9;
            this.children.stars_container.addChild(star);
            star.tween = gsap.to(star, {x: star.x - 2200, y: star.y + 2200, duration: 14 * (1.2 - starScale) + Math.random() * 2, onComplete: () => {
                this.children.stars_container.removeChild(star);
                star.destroy();
            }});
        }

        this.randomStarTimeout = setTimeout(this.addRandomStar, Math.random() * 1000 + 500);
    }

    startUpdate() {
        this.addRandomStar();
        app.removeUpdateListener("menuPixiBg");
        app.addUpdateListener("menuPixiBg", (time, delta) => {
            let deltaRatio = 1;
            deltaRatio = delta / FRAME_TIME;
            if (deltaRatio > FRAME_DELTA_LIMIT) {
                deltaRatio = FRAME_DELTA_LIMIT;
            }
        });
    }

    stopUpdate() {
        app.removeUpdateListener("menuPixiBg");
    }


}
