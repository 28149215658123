import axiosDefault from "axios";

const isProduction = process.env.NODE_ENV === "production";

  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
   // withCredentials:true,
    headers: {
      /*
      "Content-Type": "application/json",
      'Access-Control-Allow-Origin': process.env.REACT_APP_AXIOS_ORIGIN,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      "Access-Control-Allow-Headers":"x-requested-with, Content-Type, origin, authorization, accept, client-security-token"*/
    },
  };
  

const axios = axiosDefault.create(defaultOptions);

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  config.headers.Authorization = 'Bearer '+token || "";
  return config;
});

export default axios;
