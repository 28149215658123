import { assets } from "../assets/assets";
import { v } from "../koko-framework/shorthand";
import textLocaliser from "../koko-framework/ui/TextLocaliser";
import ReactFullScreenView from "../koko-framework/ui/react-views/ReactFullScreenView";
import { GAME_CONTROLLER } from "../randomised-runner/controller/randomised-runner-game-controller";
import { config } from "../randomised-runner/model/randomised-runner-config";
import { TwitchContext, TwitchProvider} from '../contexts/TwitchContext';
import "./InGameHud.scss";
import { GAME_MODE } from "../contexts/TwitchContext";
export default class InGameHud extends ReactFullScreenView {
    static contextType = TwitchContext;

    state = {
        meters: 0,
        collected: 0,
        boostProgress: 0,
        isStreamer: GAME_MODE.isStreamer,
    }

    /* constructor(props, context) {
        super(props, context);
    } */

    // make sure you call the superclass in didMount and willUnmount
    componentDidMount(props) {
        // I would recommend doing your stuff before calling super.componentDidMount,
        // as this may cause a callback...
        super.componentDidMount(props);
    }
    
    componentWillUnmount() {
        // the super version of this removes a resize listener so, make sure you call this!
        super.componentWillUnmount();
    }

    update() {
        const vidView = v.get('videoFeature');
        const progress = GAME_CONTROLLER.vidStarted ? vidView.getVidSeekTime() / /*vidView.getVidDuration()*/ config.RUN_OFF_END_TIME : 0;
        const model = GAME_CONTROLLER.model;

        this.setState({
            meters: Math.floor(model.maxWorldXReached / config.PIXELS_PER_METER),
            collected: model.itemsCollected,
            progress: progress <= 1 ? progress : 1,
            boostProgress: model.boostProgressDisplay
        });
    }
    
    /* There a alot of spacer empty growing row divs here! */
    renderContent() {
        return (
            <>
            <div className="in-game-hud-container">
                {/* <div className="meters-holder">
                    {this.state.meters.toLocaleString() + 'm'}
                </div> */}
                <div className="collected-holder">
                    <img className="collected-icon" src={assets.GoatHud} alt="" />
                    <div>{this.state.collected}</div>
                </div>
            </div>

                <div className="game-progress-bar-container">
                    <div className="game-progress-bar">
                        <div className="game-progress-bar-inner" style={{width: `${this.state.progress * 100}%`}}></div>
                        <img className="game-progress-bar-dino" src={assets.ProgressDino} alt="" style={{marginLeft: `${this.state.progress * 1100}px`}} />
                    </div>
                </div>


                {this.state.isStreamer &&
                    <div className="chat-progress-bar-container">

                        <div className="chat-progress-bar">

                            <div className="chat-progress-bar-inner" style={{ left: `${(this.state.boostProgress * 100) - 100}%` }}></div>
                            <div className="chat-progress-message" dangerouslySetInnerHTML={{ __html: textLocaliser.getText('chat_progress_message').replace('|', /* '<span class="pinktext">' */'').replace('||', /* '</span>' */'') }}></div>

                            

                        </div>
                        <div className="twitch-speech-bubble"></div>
                        
                    </div>
                }
         


            </>
        );
    }
}