import React from "react";
import ReactFullScreenView from "../../koko-framework/ui/react-views/ReactFullScreenView";
import "./HelpScreens.scss";
import textLocaliser from "../../koko-framework/ui/TextLocaliser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faPlay, faX } from "@fortawesome/free-solid-svg-icons";
import koko from "../../koko-framework/koko";

export default class HelpScreens extends ReactFullScreenView {
    componentDidMount(props) {
        super.componentDidMount(props);

        this.setState({page: 1});
    }

    componentWillUnmount() {
        super.componentWillUnmount();
    }

    nextPage = () => {
        if (this.state.page < 4) {
            this.setState({page: this.state.page + 1});
        }
    }

    prevPage = () => {
        if (this.state.page > 1) {
            this.setState({page: this.state.page - 1});
        }
    }

    closeHelp = () => {
        koko.views.hideViews(['helpScreens']);
        koko.views.showViews(['mainMenu', 'gameHUD']);
    }

    renderContent() {
        return (
            <div>
                <div className="bg-grey-out"></div>
                <div className={`help-container help-page-${this.state.page}`}>
                    <div className="help-content">
                        <h2 dangerouslySetInnerHTML={{__html: textLocaliser.getText(`help_screen_${this.state.page}_title`)}}></h2>
                        <p className="dark-text" dangerouslySetInnerHTML={{__html: textLocaliser.getText(`help_screen_${this.state.page}_message`)}}></p>
                        <div className="button-container">
                            {// this.state.page > 1 &&
                                <div className={`buttonIcon${this.state.page > 1 ? '' : ' bi-disabled'}`} onClick={this.prevPage}>
                                    <FontAwesomeIcon className='icon' icon={faArrowLeft} size="2x" />
                                </div>
                            }
                            <div className="grow"></div>
                            {// this.state.page < 4 &&
                                <div className={`buttonIcon${this.state.page < 4 ? '' : ' bi-disabled'}`} onClick={this.nextPage}>
                                    <FontAwesomeIcon className='icon' icon={faArrowRight} size="2x" />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="help-bottom-buttons">
                    <div className="grow"></div>
                    <div className="buttonIcon close-button" onClick={this.closeHelp}>
                        <FontAwesomeIcon className='icon' icon={faX} size="1x" />
                    </div>
                    <div className="buttonIcon play-button" onClick={() => {this.props.startGameFunc();}}>
                        <FontAwesomeIcon className='icon' icon={faPlay} size="3x" />
                    </div>
                    <div className="grow"></div>
                </div>
            </div>
        );
    }
}
