import koko from '../../koko-framework/koko'
import app from '../../koko-framework/app'
import { ConfettiController } from '../../koko-framework/particle-effects/ConfettiController';
import * as PIXI from 'pixi.js'

const FRAME_DELTA_LIMIT = 3;
const FRAME_TIME = 16.666;

export class ConfettiView extends koko.view {
    assets = [
        { id: 'confetti_theme1_assets', src: 'https://d17sm93bt61fgk.cloudfront.net/game_assets/confetti_themes.json' },
    ];

    confettiController = null;
    
    buildView() {
        var g = app.guiSize, vp = app.viewPort;

        if(this.children.confettiContainer)
        {
            while(this.children.confettiContainer.children[0]) this.children.confettiContainer.removeChildAt(0);
            app.removeUpdateListener('controlConfetti');
        }


        this.children = koko.display.buildLayout([
            //{name: 'guiSize', type: 'shape', width: g.width, height: g.height, x: 0, y: 0, fill: 0, alpha: 0.2},
            {name:'confettiContainer', type:'container'},
   
        ], this.container);

        this.resize();

        // this.confettiController = new ConfettiController();
    }

    startFeature = () =>{
        while(this.children.confettiContainer.children[0]) this.children.confettiContainer.removeChildAt(0);
        app.removeUpdateListener('controlConfetti');
           
        this.confettiController = new ConfettiController();
        this.confettiController.init(this.children.confettiContainer);

        //START FEATURE UPDATE
		app.addUpdateListener('controlConfetti', (time, delta) => { 
            let deltaRatio = 1;
            deltaRatio = delta / FRAME_TIME;
            if (deltaRatio > FRAME_DELTA_LIMIT) {
                deltaRatio = FRAME_DELTA_LIMIT;
            }
            
            if(this.confettiController) this.confettiController.update(1);
        });
    }

    reduce = () => {
        if(this.confettiController) this.confettiController.reduceAmountTo();
    }

    destroyFeature = () =>{
        while(this.children.confettiContainer.children[0]) this.children.confettiContainer.removeChildAt(0);
        app.removeUpdateListener('controlConfetti');
    }

    resize() {

    }

    transitionIn() {
        super.transitionIn();

        app.addResizeListener('featureResize', function(){
            this.resize();
        }.bind(this));

        this.startFeature();
    }

    transitionOutDone = () => {
        //this.stopUpdate();
        this.destroyFeature();

        app.removeResizeListener('featureResize');
        while (app.resizeListeners['featureResize']) {
            app.removeResizeListener('featureResize');
        }
    }

    startUpdate() {

    }
}