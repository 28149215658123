export const config = {
    DEBUG_SLOMO_FACTOR: 1,
    USE_LOCAL_VIDEO: false,

    DRAW_SCALE: 0.6,
    VIEW_WIDTH: 1920,
    VIEW_HEIGHT: 1080,

    PIXELS_PER_METER: 100,
    CLICKS_TO_REVIVE: 15,

    USE_PERMANENT_GROUND: true,
    PERMANENT_GROUND_BOTTOM_OFFSET: 2,  // In tiles
    PERMANENT_GROUND_PLATFORM_OFFET_TOP: 10,  // In pixels
    MIN_PLATFORM_GROUND_CLEARANCE_TILES: 1,
    SLOW_DOWN_ON_PERMANENT_GROUND: false,
    PERMANENT_GROUND_SLOW_DOWN_SPEED: 0.05,

    GOAT_BONUS_TIME_SECS: 5,
    // GOATS_LOST_WHEN_HIT: 7,
    GOATS_PERC_LOAST_WHEN_HIT: 0.1,
    MAX_GOATS_LOST_WHEN_HIT: 100,
    MAX_GOATS_SHOWN_WHEN_HIT: 13,

    // How far the player character is from the left edge of the screen before the camera starts to follow them
    FOCUS_LEFT_BUFFER: 400,
    DRAW_AHEAD_DISTANCE: 2000,
    // Tile size in pixels
    TILE_WIDTH: 90,
    TILE_HEIGHT: 90,
    START_CHUNK_MAX_BOTTOM_OFFSET_TILES: 4,
    MIN_PLATFORM_BOTTOM_OFFSET_TILES: 7,
    MIN_PLATFORM_TOP_OFFSET_TILES: 10,
    PREV_MAP_CHUNK_MAX_DISPLACEMENT_Y_TILES: 3,
    RANDOM_MAP_DISPLACEMENT_Y_TILES: 4,
    CHUNK_MIN_SPACING_TILES: 2,
    CHUNK_MAX_SPACING_TILES: 4,
    MIN_TILE_OFFSET_TOP: 2,

    // Difficulty settings, minimum difficulty of a map chunk is 1, but they are arranged into an array.
    // So difficulty 1 is actually at index 0 - we're using array indexes to represent difficulty levels here.
    START_MIN_DIFFICULTY: 0.6,
    START_MAX_DIFFICULTY: 0.8,
    // The maximum difficulty that can be reached in the game.
    // Difficulty is set on your map chunks inside Tiled.
    // Set these according to the difficulty of your map chunks.
    MAX_MIN_DIFFICULTY: 1,
    MAX_MAX_DIFFICULTY: 3,
    // The amount that the difficulty increases by every DIFFICULTY_INCREASE_DISTANCE pixels
    // We increase max difficulty faster than min difficulty to give us an expanding range of potential level chunk difficulties,
    // this way we don't suddenly get a load of really hard chunks.
    MIN_DIFFICULTY_INCREASE: 0.010,
    MAX_DIFFICULTY_INCREASE: 0.015,
    // The distance that the player must travel before the difficulty increases
    DIFFICULTY_INCREASE_DISTANCE: 150,

    WORLD_COUNT: 11,
    WORLD_DISTANCE: 10000,
    RESET_DIFFICULTY_WHEN_WORLD_ENDS: true,

    RUNNER: {
        view: {
            // These need to match the strings in PLAYER_STATES
            running: {
                animateRunner: true,
                stillRunnerFrame: 'dino-run-1.png',
                animationName: 'dino-run-',
                animationSpeed: 0.2,
                animationFrameCount: 13,
                leadingZeros: 0,
                animationLoop: true,
            },
            jumping: {
                animateRunner: false,
                stillRunnerFrame: 'dino-run-1.png',
                animationName: 'DinoJump',
                animationSpeed: 0.2,
                animationFrameCount: 10,
                animationLoop: false,
            },
            falling: {
                animateRunner: false,
                stillRunnerFrame: 'dino-run-1.png',
                animationName: 'DinoFall',
                animationSpeed: 0.2,
                animationFrameCount: 10,
                animationLoop: false,
            },
            hit: {
                animateRunner: false,
                stillRunnerFrame: 'dino-run-1.png',
                animationName: 'DinoFall',
                animationSpeed: 0.2,
                animationFrameCount: 10,
                animationLoop: false,
            },
        },
        startRunSpeed: 14,
        maxRunSpeed: 25,
        runSpeedIncreasePerSecond: 0.5,
        accelleration: 0.5,
        jumpSpeed: 36,
        bouncerJumpSpeed: 52,
        doubleJumpSpeed: 36,
        addJump3StarsMinJumpSpeed: 34,
        addJump2StarsMinJumpSpeed: 30,
        addJump1StarsMinJumpSpeed: 8,
        minJumpStars: 1,
        maxJumpStars: 3,
        gravity: 1.5,
        maxFallSpeed: 30,
        collisionWidth: 160,
        collisionTopOffset: 0,
        collisionBottomOffset: 0,
        hitBumpLeftSpeed: 5,
        hitBumpUpSpeed: 25,
        viewScale: 0.75,
    },

    SOUNDS: {
        COLLECT: 'collect',
        COLLECT_main_collectable: 'collect_main',
        COLLECT_start_bonus: 'collect_special',
        COLLIDE: 'collide',

        COLLIDE_GENERIC: 'death',
        COLLIDE_fireBasket: 'hitFireBasket',
        COLLIDE_spike: 'hitSpike',
        COLLIDE_bush: 'death',
        COLLIDE_tnt: 'hitTnt',

        JUMP: {id: 'jump', variations: 7},
        DOUBLE_JUMP: {id: 'jump', variations: 7},
        BOUNCE_JUMP: 'bounce',
        DEATH: 'death',
    },

    PARALLAX_LAYERS: {
        behind_parallax: {
            speed: 0.6,
            RANDOM_PARALLAX_ITEM_CHANCE: 0.015,
            MAX_PARALLAX_ITEMS: 5,
        },
        front_parallax: {
            speed: 1.3,
            RANDOM_PARALLAX_ITEM_CHANCE: 0.01,
            MAX_PARALLAX_ITEMS: 2,
        },
    },

    RANDOM_PARALLAX_ITEMS: {
        behind_parallax: [
            /* {
                spriteId: 'shooting_star.png',
                minScale: 0.5,
                maxScale: 1,
                movementXSpeed: -10,
            } */
            {
                spriteId: 'bokeh-purple.png',
                minScale: 0.5,
                maxScale: 1,
                movementXSpeed: -5,
                speedMultRange: [0.5, 1],
                alphaRange: [0.1, 0.2],
                wobbleTween: true,
                wobbleRange: [50, 100],
                wobbleDurationRange: [2, 4],
                fadeTween: true,
                fadeDurationRange: [1, 4],
            },
            {
                spriteId: 'bokeh-white.png',
                minScale: 0.5,
                maxScale: 1,
                movementXSpeed: -5,
                speedMultRange: [0.5, 1],
                alphaRange: [0.1, 0.2],
                wobbleTween: true,
                wobbleRange: [50, 100],
                wobbleDurationRange: [2, 4],
                fadeTween: true,
                fadeDurationRange: [1, 4],
            }
        ],
        front_parallax: [
            /* {
                spriteId: 'shooting_star.png',
                minScale: 0.75,
                maxScale: 1.25,
                movementXSpeed: -10,
            } */
            {
                spriteId: 'bokeh-purple.png',
                minScale: 0.8,
                maxScale: 1.2,
                movementXSpeed: -5,
                speedMultRange: [0.5, 1.2],
                alphaRange: [0.15, 0.25],
                wobbleTween: true,
                wobbleRange: [100, 200],
                wobbleDurationRange: [3, 5],
                fadeTween: true,
                fadeDurationRange: [2, 4],
            },
            {
                spriteId: 'bokeh-white.png',
                minScale: 0.6,
                maxScale: 1.5,
                movementXSpeed: -5,
                speedMultRange: [0.5, 1.2],
                alphaRange: [0.15, 0.25],
                wobbleTween: true,
                wobbleRange: [100, 200],
                wobbleDurationRange: [3, 5],
                fadeTween: true,
                fadeDurationRange: [2, 4],
            }
        ],
    },

    BOOKEND_TIME:6,

    VID_ZONE_TIMES: [
        {time: 0, zone: 1},
        {time: 13, zone: 1},
        {time: 20, zone: 2},
        {time: 49, zone: 3},
        {time: 71.5, zone: 5, triggerPlayerCommand: 'showBeret'},    // Clouds
        {time: 79, zone: 4, triggerPlayerCommand: 'hideBeret'},
        {time: 85, zone: 8},    // 
        {time: 95, zone: 6},
        {time: 130, zone: 11},  // White platforms

        // Just goats
        {time: 154, zone: 13},
        // Nothing - just run off the end
        // {time: 158, zone: 13},
    ],
    RUN_OFF_END_TIME: 161.5,
    GAME_OVER_TIME: 163,
   //GAME_OVER_TIME: 15,
    HARD_FORCE_CHUNKS: [
        // {time: 5, chunkId: 'map_w12_d1_1', chunkLeftOffset: 1920, chunkBottomOffset: 1, popIn: false},
        {time: 73, chunkId: 'map_twitchcon_flag', chunkLeftOffset: 1920, chunkBottomOffset: 8, popIn: false},
        {time: 83.5, chunkId: 'map_story_chunk_1', chunkLeftOffset: 1920, chunkBottomOffset: 5, popIn: false},
        {time: 88.5, chunkId: 'map_diversity_cozy_chunk_1', chunkLeftOffset: 1920, chunkBottomOffset: 10},
        // {time: 88, chunkId: 'map_diversity_chatting_chunk_1', chunkLeftOffset: 1920, chunkBottomOffset: 10},
        {time: 91, chunkId: 'map_diversity_cooking_chunk_1', chunkLeftOffset: 1920, chunkBottomOffset: 10},
        {time: 93, chunkId: 'map_diversity_music_chunk_1', chunkLeftOffset: 1920, chunkBottomOffset: 10},
        {time: 146, chunkId: 'map_categories_chunk_1', chunkLeftOffset: 1920, chunkBottomOffset: 4},
        {time: 154.5, chunkId: 'map_w12_d1_1', chunkLeftOffset: 1920, chunkBottomOffset: 1, popIn: false},
    ],

    VFX: {
        /* BOUNCE_PLATFORM: {
            animPrefix: 'bouncePlatform_effect/bouncePlatform_',
            animFrames: 25,
            startFrame: 0,
            leadingZeros: 1,
            scale: 1.5,
        }, */
        COLLECT_main_collectable: {
            animPrefix: 'collectible_GOAT_V2/collectGOAT_',
            animFrames: 19,
            startFrame: 0,
            leadingZeros: 1,
            scale: 0.5,
            animSpeed: 1,
        },
        OBSTACLE_fireBasket: {
            animPrefix: 'obstacle_fireBasket/fireBasket_',
            animFrames: 10,
            startFrame: 1,
            leadingZeros: 0,
            scale: 1.5,
            offsetX: 20,
            offsetY: -20,
        },
        OBSTACLE_spike: {
            animPrefix: 'obstacle_spike/spike_',
            animFrames: 8,
            startFrame: 1,
            leadingZeros: 0,
            scale: 1.5,
            offsetX: 20,
            offsetY: -20,
        },
        OBSTACLE_bush: {
            animPrefix: 'obstacle_spike/spike_',
            animFrames: 8,
            startFrame: 1,
            leadingZeros: 0,
            scale: 1.5,
            offsetX: 20,
            offsetY: -20,
        },
        OBSTACLE_tnt: {
            animPrefix: 'obstacle_tnt/tnt_',
            animFrames: 7,
            startFrame: 1,
            leadingZeros: 0,
            scale: 1.5,
            offsetX: 20,
            offsetY: -20,
        },
        JUMP_EFFECT: {
            animPrefix: 'bouncePlatform_effect/bouncePlatform_',
            animFrames: 25,
            animSpeed: 1,
            startFrame: 0,
            leadingZeros: 1,
            minScale: 0.6,
            maxScale: 0.8,
            minXOffset: -40,
            maxXOffset: 40,
            minYOffset: -20,
            maxYOffset: 0,
        },
        BONUS_EFFECT: {
            animPrefix: 'bouncePlatform_effect/bouncePlatform_',
            animFrames: 25,
            animSpeed: 1,
            startFrame: 0,
            leadingZeros: 1,
            minScale: 0.6,
            maxScale: 0.8,
            minXOffset: -10,
            maxXOffset: 10,
            minYOffset: -10,
            maxYOffset: 10,
        },
        GOAT_EFFECT: {
            animPrefix: 'Collectable',
            animFrames: 1,
            animSpeed: 1,
            startFrame: 1,
            leadingZeros: 0,
            minScale: 1,
            maxScale: 1,
            minXOffset: 0,
            maxXOffset: 0,
            minYOffset: 0,
            maxYOffset: 0,
        },
        VAL_1: {
            animPrefix: 'collect_',
            animFrames: 1,
            animSpeed: 1,
            startFrame: 1,
            leadingZeros: 0,
            minScale: 1,
            maxScale: 1,
            minXOffset: 0,
            maxXOffset: 0,
            minYOffset: 0,
            maxYOffset: 0,
        },
        VAL_2: {
            animPrefix: 'collect_',
            animFrames: 1,
            animSpeed: 1,
            startFrame: 2,
            leadingZeros: 0,
            minScale: 1,
            maxScale: 1,
            minXOffset: 0,
            maxXOffset: 0,
            minYOffset: 0,
            maxYOffset: 0,
        },
        VAL_100: {
            animPrefix: 'collect_',
            animFrames: 1,
            animSpeed: 1,
            startFrame: 100,
            leadingZeros: 0,
            minScale: 0.8,
            maxScale: 0.8,
            minXOffset: 0,
            maxXOffset: 0,
            minYOffset: 0,
            maxYOffset: 0,
            yMoveDistance: 400,
        },
    },
}
