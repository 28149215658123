import koko from '../koko-framework/koko'
import app from '../koko-framework/app'
import { SnowController } from '../koko-framework/particle-effects/SnowController'
import { FRAME_TIME, FRAME_DELTA_LIMIT } from '../model/config'

export class SnowEffect extends koko.view {
    snowController = null;

    assets = [
        { id: 'demo_assets', src: 'img/demo_assets.json' },
    ];

    buildView() {
        var g = app.guiSize, vp = app.viewPort;

        this.children = koko.display.buildLayout([
            {name: 'guiSize', type: 'shape', width: g.width, height: g.height, x: 0, y: 0, fill: 0, alpha: 0.2},

            {name:'snowContainer', type:'container'},

            {name:'logo', type:'sprite', id:'logo192', x: vp.centerX, y: 495, anchorX: 0.5, anchorY: 0.5},
        ], this.container);

        this.resize();
    }

    resize() {

    }

    transitionIn() {
        super.transitionIn();

        app.addResizeListener('snowresize', function(){
            this.resize();
        }.bind(this));

        this.snowController = new SnowController();
        this.snowController.init(this.children.snowContainer, {});
        this.startUpdate();
    }

    transitionOutDone = () => {
        this.stopUpdate();

        app.removeResizeListener('snowresize');
        while (app.resizeListeners['snowresize']) {
            app.removeResizeListener('snowresize');
        }

        super.transitionOutDone();
    }

    startUpdate() {
        app.removeUpdateListener('snowupdater');
           
		app.addUpdateListener('snowupdater', (time, delta) => { 
            let deltaRatio = 1;
            deltaRatio = delta / FRAME_TIME;
            if (deltaRatio > FRAME_DELTA_LIMIT) {
                deltaRatio = FRAME_DELTA_LIMIT;
            }

            this.snowController.update(deltaRatio);
        });
    }

    stopUpdate() { 
        app.removeUpdateListener('snowupdater');
        while (app.updateListeners['snowupdater']) {
            app.removeUpdateListener('snowupdater');
        }
    }
}