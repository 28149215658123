import React from "react";
import "./FirstPreloadView.scss";

import $ from 'jquery'
import { firstLoadAssets, vidsToPreload } from "../assets/first-load-assets";

import gsap from "gsap";
import getUserLocale from 'get-user-locale';
import { verifyLang } from "../model/config";
import { SWITCH_OFF_TIME } from "../../config/switch-off-config";



export default class FirstPreloadView extends React.Component {
    startedLoading = false;
    assetsLoaded = false;
    vidsLoadedCount = 0;
    vidsReady = true;
    loaded = false;

    constructor(props) {
        super(props);

        const scale = this.calculateOpenAreaScale();
        this.state = {
            openAreaWidth: 1920 * scale,
            openAreaHeight: 1080 * scale,
            percLoaded: 0,
            loadingBarVisible: true,
            curtainsOpen: false,
            switchedOff: Date.now() / 1000 > SWITCH_OFF_TIME,
        }


        let params = new URLSearchParams(document.location.search);
        let lang = params.get("lang"); 
        if(lang)
        {
            console.log('SET LANG FROM PARAMS')
            verifyLang(lang)
        }
        else
        {
            console.log('SET LANG FROM DEVICCE')
            verifyLang(getUserLocale().substring(0, 2))
            
        }

    }

    calculateOpenAreaScale() {
        // Borrowed from app.js
        var height = window.innerHeight ? window.innerHeight + 1 : $(window).height() + 1;
        //variables
        var min = { width: 1920, height:1080 }, width = window.innerWidth;
        height = height - 1;

        //scale to fit height
        let scale = height / min.height;
        //if the new scaled width is wider than the width of screen
        if ((min.width * scale) > width) {
            scale = width / min.width;
        }

        return scale;
    }

    resize = () => {
        const scale = this.calculateOpenAreaScale();
        this.setState({openAreaWidth: 1920 * scale, openAreaHeight: 1080 * scale});
    }

    preloadReactAssets(assetsArray) {
        if (window.firstPreloadImages === undefined) {
            window.firstPreloadImages = [];
        }
        this.reactAssetsToLoad = 0;
        this.reactAssetsLoadedCount = 0;
        if (Array.isArray(assetsArray)) {
            assetsArray.forEach((image) => {
                const newImage = new Image();
                newImage.onload = this.imageLoaded;
                newImage.src = image;
                window.firstPreloadImages.push(newImage);
                this.reactAssetsToLoad++;
            });
        } else {
            for (let p in assetsArray) {
                const newImage = new Image();
                newImage.onload = this.imageLoaded;
                newImage.src = assetsArray[p];
                window.firstPreloadImages.push(newImage);
                this.reactAssetsToLoad++;
            }
        }
        console.log('Preload react assets: ', assetsArray, window.firstPreloadImages, this.reactAssetsToLoad, this.reactAssetsLoadedCount);
    }

    imageLoaded = (e) => {
        console.log('REACT ASSET LOADED: ', e);
        this.reactAssetsLoadedCount++;
        if (this.reactAssetsLoadedCount >= this.reactAssetsToLoad) {
            this.assetsLoaded = true;
            console.log('All assets loaded!');
            this.checkReady();
        }
    }

    vidLoaded = (e) => {
        console.log('VID LOADED: ', e);
        this.vidsLoadedCount++;
        if (this.vidsLoadedCount >= vidsToPreload.length) {
            this.vidsReady = true;
            console.log('All vids loaded!');
            this.checkReady();
        }
    }

    checkReady() {
        const percLoaded = (this.reactAssetsLoadedCount + this.vidsLoadedCount) / (this.reactAssetsToLoad + vidsToPreload.length);
        this.setState({percLoaded: percLoaded <= 1 ? percLoaded : 1});
        if (this.assetsLoaded && this.vidsReady && !this.loaded) {
            this.loaded = true;
            console.log('Open curtains');
            // this.setState({curtainsOpen: true});
            gsap.to('#loadingBar', {duration: 0.5, opacity: 0, ease: 'power2.inOut', onComplete: () => {this.setState({loadingBarVisible: false})}});
            gsap.to('#leftCurtain', {duration: 1, delay: 0.5, marginRight: this.state.openAreaWidth / 2, ease: 'power2.inOut'});
            gsap.to('#rightCurtain', {duration: 1, delay: 0.5, marginLeft: this.state.openAreaWidth / 2, ease: 'power2.inOut', onComplete: () => {this.setState({curtainsOpen: true})}});
        }
    }

    componentDidMount() {
        if (!this.startedLoading) {
            this.resize();
            window.addEventListener('resize', this.resize);
            this.preloadReactAssets(firstLoadAssets)
            this.startedLoading = true;
        }
    }

    render() {
        return (
            <div className="first-preload-view">
                {!this.state.switchedOff &&
                <>
                    <div className="top-cover" style={{top: (window.innerHeight - this.state.openAreaHeight) / 2}}></div>
                    <div className="bottom-cover" style={{bottom: (window.innerHeight - this.state.openAreaHeight) / 2}}></div>
                </>
                }
                <div className="left-curtain" id="leftCurtain" style={{marginRight:this.state.curtainsOpen ? this.state.openAreaWidth / 2 : 0}}></div>
                <div className="right-curtain" id="rightCurtain" style={{marginLeft:this.state.curtainsOpen ? this.state.openAreaWidth / 2 : 0}}></div>
     
                <div className="loading-bar" id="loadingBar">
                    <div className="fill" style={{width: this.state.percLoaded * 100 + '%'}}></div>
                </div>
            </div>
        );
    }
}
