import React from 'react'
import ReactFullScreenView from '../koko-framework/ui/react-views/ReactFullScreenView';
import "./Leaderboard.scss";
import { useEffect } from 'react';
import axios from "../../api/axios";
import { submitScoreRoute, getLeaderboardRoute, getScorePosition } from "../../api/routes";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import { faQuestion, faRankingStar, faPlay, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import textLocaliser from '../koko-framework/ui/TextLocaliser';
import koko from '../koko-framework/koko';
import { REACT_VIEW_CONTROLLER } from '../Bootstrap';
import { LANGUAGE_CHANGED_EVENT, LEADERBOARD_SETTINGS } from '../model/config';
import gsap from 'gsap';
import { assets } from '../assets/assets';
import { TwitchContext, TwitchProvider} from '../contexts/TwitchContext';
import { GAME_MODE } from '../contexts/TwitchContext';

import { FINAL_SCORES, SWITCH_OFF_TIME } from '../../config/switch-off-config';
export default class Leaderboard extends ReactFullScreenView {

  static contextType = TwitchContext
    LEADERBOARD_TYPES = {
      global:1,
      streamer:2,
      allTime:3
    }

    testData = [
      {name:'Player 1',  score:1234, image:'', timeSubmitted:1700657513226},
      {name:'Player 2',  score:200,  image:'', timeSubmitted:1700657513226},
      {name:'Player 3',  score:434,  image:'', timeSubmitted:1700657513226},
      {name:'Player 4',  score:1326, image:'', timeSubmitted:1700657513226},
      {name:'Player 5',  score:985,  image:'', timeSubmitted:1700657513226},
      {name:'Player 6',  score:436,  image:'', timeSubmitted:1700657513226},
      {name:'Player 7',  score:924,  image:'', timeSubmitted:1700657513226},
      {name:'Player 8',  score:543,  image:'', timeSubmitted:1700657513226},
      {name:'Player 9',  score:756,  image:'', timeSubmitted:1700657513226},
      {name:'Player 10', score:196,  image:'', timeSubmitted:1700657513226},
      {name:'Player 11', score:472,  image:'', timeSubmitted:1700657513226},
      {name:'Player 12', score:1058, image:'', timeSubmitted:1700657513226},
      {name:'Player 13', score:945,  image:'', timeSubmitted:1700657513226},
      {name:'Player 14', score:328,  image:'', timeSubmitted:1700657513226},
      {name:'Player 15', score:742,  image:'', timeSubmitted:1700657513226},
      {name:'Player 16', score:296,  image:'', timeSubmitted:1700657513226},
      {name:'HIPSTER82', score:1286, image:'', timeSubmitted:1700657513226},
      {name:'Player 18', score:215,  image:'', timeSubmitted:1700657513226},
      {name:'Player 19', score:1175, image:'', timeSubmitted:1700657513226},
      {name:'Player 20', score:764,  image:'', timeSubmitted:1700657513226}
    ]
    constructor(props) {
      super(props);
      console.log(this.context)
      const lbType = GAME_MODE.isStreamer ? this.LEADERBOARD_TYPES.streamer : this.LEADERBOARD_TYPES.global;
      const switchedOff = Date.now() / 1000 > SWITCH_OFF_TIME;
      this.state = {mounted:false, loading:false, scoreBoard:[], isStreamer:GAME_MODE.isStreamer , type:lbType, forceUpdate: false, switchedOff:switchedOff};
    }

    scoreBoard = null;

    langChangeHandler = (e) => {
      this.setState({forceUpdate: !this.state.forceUpdate});
    }

    isLoadingLeaderboard = false;
    scoresInitLoaded = false;

    getLeaderboard = (e, isStreamer) =>
    {

      if (this.state.switchedOff) {
        this.setLeaderboardData(FINAL_SCORES);
        this.scoresInitLoaded = true;
        this.isLoadingLeaderboard = false;
        return;
      }

      let streamerStatus = this.state.isStreamer
      /*if(isStreamer != null) {
        console.log("FORCED STREAMER")
        streamerStatus = isStreamer
      }
*/
      //return;
       if(!this.isLoadingLeaderboard)
       {
        this.isLoadingLeaderboard = true;
        axios
        .get(getLeaderboardRoute, {
            params: { isStreamer:streamerStatus, limitAmount:LEADERBOARD_SETTINGS.NUMBER_OF_SCORES, timePeriod:this.state.type == this.LEADERBOARD_TYPES.allTime ? 0 : LEADERBOARD_SETTINGS.LEADERBOARD_TIMEFRAME, getUserPos:true, username:localStorage.getItem('username') },
            withCredentials:true
        })
        .then((response) => {
           // setStreamerType(isStreamer ? 'STREAMER' : 'INDIVIDUAL');
           this.scoresInitLoaded = true;  
           GAME_MODE.bestScore = response.data.bestPosition.bestScore
           GAME_MODE.position = response.data.bestPosition.position
            this.setLeaderboardData(response.data.leaderboardData); 
            this.isLoadingLeaderboard = false 
            
        })
        .catch((error) => {
          this.isLoadingLeaderboard = false     
          //
          //toast.error(error.response.data.msg);
        });
      }
    }

    mounted = false;
    // make sure you call the superclass in didMount and willUnmount
    componentDidMount(props) {
    
        // I would recommend doing your stuff before calling super.componentDidMount,
        // as this may cause a callback...
        if(!this.mounted)
        {
          this.mounted = true
          this.scoresInitLoaded = false;
          this.setState({mounted:true})
          // koko.views.showViews(['menuStarsBgView']);
          REACT_VIEW_CONTROLLER.rootView.showMenuBg();
          this.getLeaderboard();
         //setTimeout(() =>{this.getLeaderboard(null, isStreamer)}, 200)

          document.removeEventListener(LANGUAGE_CHANGED_EVENT, this.langChangeHandler);
          document.addEventListener(LANGUAGE_CHANGED_EVENT, this.langChangeHandler);
        }
         super.componentDidMount(props);
    }
  
    componentWillUnmount() {
        // the super version of this removes a resize listener so, make sure you call this!
        super.componentWillUnmount();

        // koko.views.hideViews(['menuStarsBgView']);
        document.removeEventListener(LANGUAGE_CHANGED_EVENT, this.langChangeHandler);
    }

    componentDidUpdate(prevProps, prevState) {
      // do something
      if(this.state && prevState)
      {
      if(this.state.type != prevState.type)
      {
        this.getLeaderboard();
      }
      if(this.state.scoreBoard != prevState.scoreBoard)
      {
        this.animateScoreBars()
      }
     
    }
    }

    scoreBoxRefs = []
    createScoreRef = (i) =>{
        if(!this.scoreBoxRefs[i]) this.scoreBoxRefs.push(React.createRef(null));
        return this.scoreBoxRefs[i] 
    }
    changeLeaderboard = (e, leaderboardType) => {
      e.preventDefault();
      switch(leaderboardType)
      {
      case this.LEADERBOARD_TYPES.global:
        this.setState({isStreamer:false, type:leaderboardType})
        GAME_MODE.isGOAT = false;
      break;
      case this.LEADERBOARD_TYPES.streamer:
        this.setState({isStreamer:true, type:leaderboardType})
        GAME_MODE.isGOAT = false;
       
      break;
      case this.LEADERBOARD_TYPES.allTime:
        this.setState({isStreamer:GAME_MODE.isStreamer, type:leaderboardType})
        GAME_MODE.isGOAT = true;
      break;
    }
  }

    setLeaderboardData = (rawData) => {
      //this.setState({loading:true});

        //axiuos call here


      const sortedScores = rawData.sort(function(a, b) {
        return parseFloat(b.score) - parseFloat(a.score);
      });
      if(localStorage.getItem('position') > LEADERBOARD_SETTINGS.NUMBER_OF_SCORES)
      {
        sortedScores.push({position:localStorage.getItem('position'), name:localStorage.getItem('username'), score:localStorage.getItem('bestScore'), profilePic:localStorage.getItem('profilePic')})
      }
      this.scoreBoard = sortedScores
      
      this.setState({scoreBoard:sortedScores});
      //this.animateScoreBars();
    }

    animateScoreBars = () => {
      if(  this.scoresInitLoaded )
      {
        for(let i=0; i<this.scoreBoxRefs.length; i++)
        {
          let scoreBarRef = this.scoreBoxRefs[i];
          let startDelay = i*.1;
          if(scoreBarRef.current)
          {
          // scoreBarRef.current.style.transform = `rotate(0deg) scale(1, 1)`;
            scoreBarRef.current.style.marginLeft = '80px';
            scoreBarRef.current.style.opacity = 0;
            gsap.to(scoreBarRef.current, {delay:startDelay,  onStart:() => this.playScoreBoxSFX(), overwrite: true, opacity: 1,  marginLeft:0, duration:.4, ease:'back.out' });                
      
          }
        }
      }
    }
    playScoreBoxSFX = () =>{

    }

    openTwitchPage(username) {
      window.open("https://www.twitch.tv/"+username);
    }

    openMainMenu(e) {
      e.preventDefault();
      localStorage.setItem('isGOAT', false)
      koko.views.showView('mainMenu');
      koko.views.hideViews(['leaderboard']);
    }
    /* There a alot of spacer empty growing row divs here! */
    renderContent() {
      const leaderboardListItems = this.state.scoreBoard.map((scoreData, index) =>
      <div 
      onClick={(e) => {this.openTwitchPage(scoreData.name)}}
      className={'scoreBar ' + (index == 0 ? 'firstPos' : '') + ((localStorage.getItem('username') == scoreData.name? 'isPlayer' : ''))} 
      ref={this.createScoreRef(index)} 
      key={index}>
          <div className='positionBox'>{scoreData.position ? scoreData.position : index+1}</div>
          <div className='playerImage'>
            <img src={scoreData.profilePic} />
          </div>
          <div className='playerName'>{scoreData.name}</div>
          <div className='score'>{scoreData.score}</div>
      </div>);
      return (
      <div className={`leaderboard-holder${this.props.gameoverStyle ? ' game-over-leaderboard' : ''}`}>

        {!this.props.gameoverStyle && !this.state.switchedOff &&
          <div className={'back-button'} onClick={(e) => {this.openMainMenu(e)}}> <FontAwesomeIcon className='icon' icon={faArrowLeft} size="3x" /></div>
        }
        <div className={`leaderboardContainer`}>
          <div className="tab-bar" />
          {!this.state.switchedOff &&
            <div className="tabs">
              <div className={"tab"+ (this.state.type == this.LEADERBOARD_TYPES.global ? ' is-tab-selected' : '')} onClick={(e) => {this.changeLeaderboard(e, this.LEADERBOARD_TYPES.global)}}> 
                <p>{textLocaliser.getText('global_leaderboard_tab_title')}</p>
                <p>{textLocaliser.getText('global_leaderboard_tab_subtitle')}</p>
              </div>
              <div className={"tab"+ (this.state.type == this.LEADERBOARD_TYPES.streamer ? ' is-tab-selected' : '')} onClick={(e) => {this.changeLeaderboard(e, this.LEADERBOARD_TYPES.streamer)}}>   
                <p>{textLocaliser.getText('streamer_leaderboard_tab_title')}</p>
                <p>{textLocaliser.getText('streamer_leaderboard_tab_subtitle')}</p>
              </div>
              <div className={"tab"+ (this.state.type == this.LEADERBOARD_TYPES.allTime ? ' is-tab-selected' : '')} onClick={(e) => {this.changeLeaderboard(e, this.LEADERBOARD_TYPES.allTime)}}>
                <p>{textLocaliser.getText('goats_leaderboard_tab_title')}</p>
                <p>{textLocaliser.getText('goats_leaderboard_tab_subtitle')}</p>
              </div>
            </div>
          }
          {this.state.switchedOff &&
            <div className="tabs-switched-off">
              <div className={"tab is-tab-selected"}></div>
              <div className={"tab is-tab-selected"}>
                <p>{textLocaliser.getText('goats_leaderboard_tab_title')}</p>
                <p>{textLocaliser.getText('goats_leaderboard_tab_subtitle')}</p>
              </div>
              <div className={"tab is-tab-selected"}></div>
            </div>
          }
          {!this.state.loading && <div className='scoreContainer'>
          {/*<p>Please check back later to see if you are in the leaderboard</p>*/}
              {leaderboardListItems}
          </div>}
        </div>
        {this.props.gameoverStyle &&
          <div className='ground-full-game-over'>
            <img className='thumbs-up' src={assets._3DThumbsUp} alt="Thumbs Up!" />
            <p className='end-game-text'>{textLocaliser.getText('game_complete_message')}</p>
            <div className='grow'></div>

            <a 
  href={"https://twitter.com/intent/tweet?text="+textLocaliser.getText('share_game_copy')} target='_blank' via='twitch'>

                      <div onClick={/*this.nextScreen*/null} className='menu-button'>
                        {/* <FontAwesomeIcon icon={faPlay} size="3x" /> */}
                        <p>{textLocaliser.getText('share_button_text')}</p>

                      



            <img src={assets.SolidShareIcon} alt='' />
                      </div>
              </a>
              <div onClick={this.props.startGameFunc} className='menu-button'>
                {/* <FontAwesomeIcon icon={faPlay} size="3x" /> */}
                <img src={assets.RotateLeftIcon} alt='' />
              </div>
          </div>
        }

        {this.state.switchedOff &&
        <>
          <img className="small-twitch-logo" src={assets.TwitchLogo} />
          <div className='small-playthrough-title'><p>{textLocaliser.getText('logo_tag_line')}</p></div>
        </>
        }
      </div>)
    }
}
