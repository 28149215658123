import ReactFullScreenViewUnderCanvas from "../koko-framework/ui/react-views/ReactFullScreenViewUnderCanvas";

export default class BufferingBgView extends ReactFullScreenViewUnderCanvas {
    // make sure you call the superclass in didMount and willUnmount
    componentDidMount(props) {
        // I would recommend doing your stuff before calling super.componentDidMount,
        // as this may cause a callback...
        super.componentDidMount(props);
    }
    
    componentWillUnmount() {
        // the super version of this removes a resize listener so, make sure you call this!
        super.componentWillUnmount();
    }

    /* There a alot of spacer empty growing row divs here! */
    renderContent() {
        return (
            <>
                <div className="buffering-bg">
                </div>
            </>
        );
    }
}