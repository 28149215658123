import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";

//import Register from "../Register/Register";
// import ConfirmLogin from "../Login/ConfirmLogin";
// import Feature from "../Feature/Feature";
import KokoDebugControls from "../Debug/KokoDebugControls";
import Bootstrap from "../../game/Bootstrap";
import { TwitchProvider } from "../../game/contexts/TwitchContext";
import "../../game/react-view/SelectGameMode.scss";
import { OAuthPopup } from "@tasoskakour/react-use-oauth2";
import FirstPreloadView from "../../game/react-view/FirstPreloadView";
import RotateDevice from "../RotateDevice";



const MainSiteContainer = () => {
    
    return (
        <TwitchProvider>
            <RotateDevice />
            <Routes>
                {/* Feature route allowed when not logged in! */}
                <Route path={'/'} element={<Bootstrap />} />
                <Route path={TOP_LEVEL_NAV_ROUTES.KOKO_DEBUG_TOOLS} element={<KokoDebugControls />} /> 
                <Route element={<OAuthPopup />} path="/callback" />
            </Routes>
        </TwitchProvider>
    );
}

export default MainSiteContainer;
