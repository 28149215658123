
export const getAssetBaseUrl = (assetName) => {
    return 'https://d17sm93bt61fgk.cloudfront.net/menu_assets/'
   }
export const firstLoadAssets = {
    SpeedLines: getAssetBaseUrl()+'speedlines-1.png',
    Dino: getAssetBaseUrl()+'3d_pixel-dino.png',
    LoginStars: getAssetBaseUrl()+'login-stars.png',
    LoginControllers: getAssetBaseUrl()+'login-controllers.png',
    imgMainMenuTwitchLogo: getAssetBaseUrl()+'main_menu_twitch_logo.png',
    RotateDeviceImg: getAssetBaseUrl()+'rotate-device.svg',
}

export const vidsToPreload = [

]
