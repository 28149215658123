import { ProcessMapChunksFromJsonData, ProcessedMapChunks } from "../../../koko-framework/tile-engine/MapChunk";
import { InitJsonTilesets } from "../../../koko-framework/tile-engine/TileSet";
import { config } from "../randomised-runner-config";

// require all modules on the path and with the pattern defined
const reqTilesets = require.context('./tilesets/', true, /.json$/);
const TILESETS_RAW = reqTilesets.keys().map(reqTilesets);

const reqMaps = require.context('./tilemaps/', true, /.json$/);
// const TILEMAPS_RAW = reqMaps.keys().map(reqMaps);
// add map data to a const called TILEMAPS_RAW adding name property to each map object from the filename
const TILEMAPS_RAW = reqMaps.keys().map((key) => {
    const map = reqMaps(key);
    const mapNameObj = {name: 'name', type: 'string', value: key.replace('./', '').replace('.json', '')};
    map.properties.push(mapNameObj);
    return map;
});

class MapSetup {
    tileSets_raw = null;
    tilemaps_raw = null;

    constructor() {
        // These will be processed
        this.tileSets_raw = TILESETS_RAW;
        this.tileMaps = TILEMAPS_RAW;
        console.log('Raw Tilesets: ', this.tileSets_raw);
        console.log('Raw Tilemaps: ', this.tileMaps);

        InitJsonTilesets(TILESETS_RAW, config.TILE_WIDTH, config.TILE_HEIGHT, null);
        ProcessMapChunksFromJsonData(TILEMAPS_RAW);
    }
}

export const MAP_SETUP = new MapSetup();
