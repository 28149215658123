// app
// when you import this it acts like a global var, like the old framework
import _ from 'underscore'
import $ from 'jquery'
import md5 from 'md5'
// import FB from 'fb'
import * as PIXI from 'pixi.js'
import koko from './koko'

const app = {
    stage: null,
    renderer: null,
    targetFPS: 30,
    guiSize: { width: 1010, height: 610 },
    minSize: { width: 660, height: 610 },
    viewPort: { x: 0, y: 0, left: 0, right: 0, top: 0, bottom: 0, width: 1920, height: 1080, centerX: 405, centerY: 305 },
    scale: 1,
    hasLoaded: false,
    url: '',
    api_url: '',
    anti_alias: true,
    autoDensity: false,
    retina: false,
    resolution: window.devicePixelRatio,
    fakePortrait: false,
    transparent: false,
    bgColor: 0x000000,
    roundPixels: false,
    windowInFocus: true,

    setupComplete: function () { },

    init: function () {
        koko.client.init();
        this.is_cocoon_js = !!navigator.isCocoonJS;
        koko.assets.init();
        this.update = this.update.bind(this);
    },

    token: "",
    tokenRetries: 0,
    getToken: function (complete) {
        if (this.tokenRetries >= 3) return;

        this.getApi('token', null, function (data) {
            this.token = data.response.token;
            this.tokenRetries = 0;
            if (complete) complete(data);
        });
    },

    sessionIds: {
        php: "",
        cloudflare: "",
    },

    postApi: function (api, params, complete, error) {
        if (!params) params = {};
        params.methodType = 'POST';
        this.api(api, params, complete, error);
    },

    getApi: function (api, params, complete, error) {
        if (!params) params = {};
        params.methodType = 'GET';
        this.api(api, params, complete, error);
    },

    api: function (api, params, complete, error) {
        if (!params) params = {};
        var origParams = _.extend(params);

        //data object
        var data = {
            token: md5(this.token.substr(8, 15) + this.token.substr(3, 7) + this.token.substr(5, 3))
        }

        var token_encrypt = params.token_encrypt ? params.token_encrypt : 'player_score';

        if (params[token_encrypt] !== undefined) {
            data.token = md5(this.token.substr(8, 15) + params[token_encrypt] + this.token.substr(3, 7) + params[token_encrypt] + this.token.substr(5, 3));
        }

        if (!params.token) {
            delete data.token;
        } else {
            params.token = data.token;
        }

        var postMethodType = 'GET';
        if (params.hasOwnProperty('methodType')) {
            postMethodType = params.methodType;
            delete params.methodType;
        }
        //extend it
        _.extend(data, params);

        var http = new XMLHttpRequest();

        var url = this.api_url + api;
        /*
        var formattedParams;
        if (postMethodType === 'GET') {
            formattedParams = params;
        } else {
            formattedParams = JSON.stringify(params);
        }
        */

        http.onload = function () {
            if (http.readyState === 4) {

                var data = JSON.parse(http.responseText);

                if (http.status === 200) {
                    //here it is
                    complete(data);

                    //save de cookie data for android
                    var cookie = null;

                    if (koko.client.system.android) {
                        try {
                            cookie = http.getResponseHeader('Set-Cookie');
                        } catch (e) { };
                    }
                    if (cookie && cookie.length > 0) {
                        cookie = cookie.split(';');
                        for (var i = 0; i < cookie.length; i++) {
                            var c = cookie[i].split('=');
                            if (c[0] === '__cfduid') this.sessionIds.cloudflare = c[1];
                            if (c[0] === ' HttpOnly PHPSESSID') this.sessionIds.php = c[1];
                        }
                    }
                } else {
                    //INVALID TOKEN
                    this.tokenRetries++;

                    if (this.tokenRetries >= 3) {
                        if (error) error(http);
                    } else {
                        if (data && data.error.code === '401') {
                            //add method type back for the repost
                            origParams.methodType = postMethodType;
                            this.getToken(function () {
                                this.api(api, origParams, complete, error);
                            });
                        }
                    }
                }
            }
        }

        http.open(postMethodType, url, true);
        http.withCredentials = true;

        http.setRequestHeader('token', this.token);

        if (this.sessionIds && this.sessionIds.cloudflare.length > 0) {
            http.setRequestHeader('Cookie', '__cfduid=' + this.sessionIds.cloudflare + '; PHPSESSID=' + this.sessionIds.php + ';');
        }

        if (postMethodType === 'GET') {
            http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            http.send(params);
        }
        else if (postMethodType === 'POST') {
            http.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
            http.setRequestHeader("Accept", "application/json; text/javascript; text/html;");
            http.send(JSON.stringify(params));
        }
    },

    loaded: function () {
        //create pixi stage
        var width = window.innerWidth, height = window.innerHeight ? window.innerHeight : $(window).height();
        var resolution = this.retina ? this.resolution : 1

        var rendererOptions = {
            width: width,
            height: height,
            antialias: this.anti_alias,
            transparent: this.transparent,
            resolution: resolution,
            forceFXAA: this.forceFXAA,
            autoDensity: this.autoDensity,
            backgroundColor: this.bgColor,
            autoResize: this.autoResize,
            // roundPixels: this.roundPixels || true,
            resizeTo: window,
        }
        PIXI.settings.ROUND_PIXELS = this.roundPixels || true;
        //auto detect renderer (webGL or Canvas);
        // this.renderer = PIXI.autoDetectRenderer(width, height, rendererOptions);
        this.renderer = new PIXI.Application(rendererOptions);

        // document.body.insertBefore(this.renderer.view, document.body.firstChild);
        // console.log('renderer: ', this.renderer);
        // console.log('game div: ', document.getElementById('game-div'));
        // document.getElementById('game-div').appendChild(this.renderer.view);
        // $('#game-div').append(this.renderer.view);

        // this.renderer.plugins.interaction.resolution = rendererOptions.resolution;
        this.renderer.autoResize = true;

        //stage
        this.stage = app.renderer.stage; // new PIXI.Container();

        //start e'rythin
        this.hasLoaded = true;

        this.resize();
        this.setupComplete();

        requestAnimationFrame(this.update);
        window.addEventListener('resize', function () {
            this.fireResize();
        }.bind(this));

        this.renderer.resize(this.renderer.view.width, this.renderer.view.height);
    },

    appendRenderer: function() {
        // console.log('appending renderer');
        document.body.insertBefore(this.renderer.view, document.body.firstChild);
    },

    checkOrientation: function () {
        koko.client.calculateOrientation();
        if (koko.client.system.isMobile && this.orientationChangeHandler) {
            this.orientationChangeHandler.call(this, koko.client.browser.orientation);
        }
    },

    updateListeners: {},
    addUpdateListener: function (name, func, scope) {
        this.updateListeners[name] = { func: func, scope: scope };
    },

    removeUpdateListener: function (name) {
        this.updateListeners[name] = null;
        delete this.updateListeners[name];
    },

    onReadyListeners: {},
    addOnReadyListener: function (name, func, scope) {
        this.onReadyListeners[name] = { func: func, scope: scope, hasRun: false };
    },

    removeOnReadyListener: function (name) {
        this.onReadyListeners[name] = null;
        delete this.onReadyListeners[name];
    },

    paused: false,
    lastUpdate: 0,
    update: function (time) {
        let delta = this.lastUpdate === 0 ? 0 : time - this.lastUpdate;
        this.lastUpdate = time;

        if (this.paused) return;
        //update listeners
        for (let key in this.updateListeners) {
            var r = this.updateListeners[key];
            r.func.call(r.scope, time, delta);
        }

        if (this.resolution > 1 && PIXI.Ticker.shared.FPS < 10) {
            this.lowFrameRateCount++;
            if (this.lowFrameRateCount > 5) {
                this.resolution = 1;
                this.renderer.resolution = 1;
                // this.renderer.plugins.interaction.resolution = 1;
                this.renderer.view.style[koko.client.cssprops.transform] = '';
                this.resize();
            }
        } else {
            this.lowFrameRateCount = 0;
        }

        for (let key in this.onReadyListeners) {
            var onReadyListener = this.onReadyListeners[key];

            if (onReadyListener && !onReadyListener.hasRun) {
                onReadyListener.hasRun = true;
                onReadyListener.func.call(onReadyListener.scope);
            }
        }

        //render stage
        this.renderer.render(this.stage);

        requestAnimationFrame(this.update);
    },

    resizeTimer: null,
    fireResize: function () {
        if (this.resizeTimer) clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(function () {
            this.resize();
        }.bind(this), 100);
    },

    blockResize: false,
    lowFrameRateCount: 0,
    resize: function () {
        if (this.blockResize) {
            this.checkOrientation();
        }

        var height = window.innerHeight ? window.innerHeight + 1 : $(window).height() + 1;

        if (!this.hasLoaded || this.blockResize) return;

        //variables
        var gui = this.guiSize, vp = this.viewPort, min = this.minSize, width = window.innerWidth;
        height = height - 1;

        if (this.fakePortrait) {
            width = height;
            height = window.innerWidth;
        }

        //reset the vp vars
        vp.width = gui.width;
        vp.height = gui.height;
        vp.left = 0;
        vp.right = vp.width;
        vp.top = 0;
        vp.bottom = vp.height;
        vp.x = 0;
        vp.y = 0;
        vp.s = 1;

        //var clientHeight = document.body.clientHeight;
        var heightDiff = height - vp.height;
        if (heightDiff !== 0) {
            vp.clientTop = 0 - heightDiff / 2;
            vp.clientBottom = vp.height + heightDiff / 2;
        }

        //scale to fit height
        vp.s = height / min.height;

        //if the new scaled width is wider than the width of screen
        if ((min.width * vp.s) > width) {
            vp.s = width / min.width;
        }

        //positioning of the canvas (this could focus on any corner or sid of the canvas(center by default))
        vp.x = (width - (vp.width * vp.s)) * 0.5; //center
        vp.y = (height - (vp.height * vp.s)) * 0.5; //center

        var visibleWidth = width / vp.s;
        if (visibleWidth > gui.width) visibleWidth = gui.width;

        var visibleHeight = height / vp.s;
        if (visibleHeight > gui.height) visibleHeight = gui.height;

        if (vp.x < 0) {
            vp.left = vp.x * -1 / vp.s;
            vp.right = vp.left + visibleWidth;
        }
        if (vp.y < 0) {
            vp.top = vp.y * -1 / vp.s;
            vp.bottom = vp.top + visibleHeight;
        }

        vp.width = visibleWidth;
        vp.height = visibleHeight;

        vp.centerX = vp.left + (vp.right - vp.left) * 0.5;
        vp.centerY = vp.top + (vp.bottom - vp.top) * 0.5;

        //woo
        this.scale = vp.s;


        //only resize renderer if neccessary
        if (this.renderer.width !== (window.innerWidth * this.renderer.resolution) || this.renderer.height !== (height * this.renderer.resolution)) {
            this.renderer.resize(window.innerWidth, height);
        }

        if (this.fakePortrait) {
            this.stage.scale.x = this.stage.scale.y = vp.s;
            this.stage.rotation = koko.math.angleToRadians(270);
            this.stage.x = 0 - (vp.top * vp.s);

            this.stage.y = window.innerHeight + (vp.left * vp.s)
        } else {
            //move stage
            this.stage.scale.x = this.stage.scale.y = vp.s;//
            this.stage.x = vp.x;
            this.stage.y = vp.y;
        }

        this.checkOrientation();

        //resize listeners
        for (var key in this.resizeListeners) {
            var r = this.resizeListeners[key];
            r.func.call(r.scope);
        }

        //render
        this.renderer.render(this.stage);
    },

    resizeListeners: {},
    //proxy functions
    addResizeHandler: function () { this.addResizeListener.apply(this, arguments); },
    removeResizeHandler: function (name) { this.removeResizeListener.apply(this, arguments); },

    //resize stuff
    addResizeListener: function (name, func, scope) {
        this.resizeListeners[name] = {
            func: func,
            scope: scope
        };
        func.call(scope);
    },

    removeResizeListener: function (name) {
        this.resizeListeners[name] = null;
        delete this.resizeListeners[name];
    },

    openLink: function (url) {
        if (koko.client.engine.ie > 0 || koko.client.browser.firefox > 0) {
            this.popup = window.open(url);
            setTimeout(function () {
                if (!this.popup) {
                    alert("We have detected you may have a popup blocker enabled. Please add this site to your exception list.");
                }
            }.bind(this), 500);
        } else {
            var a = document.createElement('a');
            a.setAttribute("href", url);

            /*
            if (koko.facebook.enabled) {
                a.setAttribute("target", FB.UA.nativeApp() ? "_self" : "_blank");
            } else {
                a.setAttribute("target", "_blank");
            }
            */

            var dispatch = document.createEvent("HTMLEvents");
            dispatch.initEvent("click", true, true);
            a.dispatchEvent(dispatch);
        }
    },

    goFullScreen: function() {
        var el = document.body;
        if (el.webkitRequestFullScreen) {
            el.webkitRequestFullScreen();
        }
        else {
            el.mozRequestFullScreen();
        }
    }
}

export { app as default }
