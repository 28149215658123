import {Howl, Howler} from 'howler';
import koko from './koko';
import app from './app';

const audio = {
	sounds: {},
	groups: {},
	soundLength: 0,
	loaded: 0,
	volume:1,
	progressHandler: null,
	loadedHandler: null,
	mute: false,
	sfxMuted: false,
	musicMuted: false,
	blocked: false,
	dir: './audio/',
	init: function(){
        // this.blocked = koko.client.engine.webkit < 535 && koko.client.system.isMobile;
        
        (function() {
            var hidden = "hidden";
            
            window.currentMute = false;
            
            if (hidden in document)
                document.addEventListener("visibilitychange", onchange);
            else if ((hidden = "mozHidden") in document)
                document.addEventListener("mozvisibilitychange", onchange);
            else if ((hidden = "webkitHidden") in document)
                document.addEventListener("webkitvisibilitychange", onchange);
            else if ((hidden = "msHidden") in document)
                document.addEventListener("msvisibilitychange", onchange);
            else if ("onfocusin" in document)
                document.onfocusin = document.onfocusout = onchange;
            else
                window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;
        
            function onchange (evt) {
				var v = "visible", h = "hidden",
                evtMap = { focus:v, focusin:v, pageshow:v, blur:h, focusout:h, pagehide:h };
                evt = evt || window.event;
        
                var isVisible = false;
        
                if (evt.type in evtMap) {
                    isVisible = evtMap[evt.type] === 'visible';
                } else {
                    isVisible = !this[hidden];
                }
                
                if(isVisible) {
                    koko.audio.setMute(window.currentMute);
					app.windowInFocus = true;
                } else {
                    window.currentMute = koko.audio.mute;
                    koko.audio.setMute(true);
					app.windowInFocus = false;
                }
        
            }
        })();
	},
	add: function(name, src, volume, group, size){
		// if(this.blocked) return;
		
		this.sounds[name] = {
			au: new Howl({
				name:name,
			  	src: [this.dir+src+'.ogg', this.dir+src+'.mp3'],
				volume:0,
				autoLoad:false,
				onload: function(){
					this.audioManager.loaded += this.size;
					if(this.audioManager.progressHandler) this.audioManager.progressHandler();
					if(this.audioManager.loaded === this.audioManager.soundLength && this.audioManager.loadedHandler) this.audioManager.loadedHandler();
				},
				onplay: function(){
                    this.sounds[name].isPlaying = true;
				}.bind(this),
                onend: function(){
                    this.sounds[name].isPlaying = false;
                }.bind(this)
			}),
			group: group,
			volume: volume,
			size: size,
            isPlaying: false
		};
		
		this.sounds[name].au.name = name;
		this.sounds[name].au.size = size || 1;
		this.sounds[name].au.audioManager = this;
		
		this.soundLength += size || 1;
	},
	giveupTimer: function(duration) {
		koko.timer(this.checkLoaded, duration, koko.audio);
	},
	checkLoaded:function(){
		if(this.loaded === 0){
			this.blocked = true;
			this.soundLength = 1;
			this.loaded = 1;
			if(this.loadedHandler) this.loadedHandler()
		}
	},
	// used by tile engine - probably should update the tile engine to work with playRandom() instead,
	// although this one doesdo some extra stuff with volume,so probably not
	playRandomSound: function(namePrefix, volume = 1, variations = 3, delay = 0){
		if(this.blocked) return;

		let played = false;
		let attempts = 0;

		while (!played && attempts < 20) {
			attempts++;
			let v = Math.floor(Math.random() * variations) + 1;
			var s = this.sounds[namePrefix + v];
			if (s) {
				volume *= s.volume * this.groups[s.group].volume * this.volume;
				if (!s.isPlaying || s.au._volume <= volume || volume == 0) {
					// s.au.stop();
					s.au.loop(false);
					s.au.volume(volume);
		
					if(delay) {
						koko.timer(s.au.play, delay, s.au);
					} else {
						s.au.play();
					}
					played = true;
				}
			}
		}
	},
	playRandom:function(prefix, minVar, maxVar, volume = 1, loop = false, delay = 0, allowMultiple = false) {
		if(this.blocked) return;
		var variation = Math.floor(Math.random() * (maxVar - minVar)) + minVar;
		this.play(prefix + variation, volume, loop, delay, allowMultiple);
	},
	play: function(name, volume = 1, loop = false, delay = 0, allowMultiple = false){
		if(this.blocked) return;
		if (typeof name === 'object' && name.id && name.variations) {
			// console.log('play random sound', name);
			this.playRandom(name.id, 1, name.variations, volume, loop, delay, allowMultiple);
			return;
		}

		//s
		var s = this.sounds[name];
		if (s) {
			// console.log(s, Howler);
			
			//find sound group
			// volume = volume || 1;
			// loop = loop || false;
			//this play volume * sound settings volume * sound group volume * master volume
			volume *= s.volume * this.groups[s.group].volume * this.volume;
					
			if (delay > 0 || !s.isPlaying || allowMultiple) {
				s.au.loop(loop);
				s.au.volume(volume);
			
				if(delay) {
					koko.timer(s.au.play, delay, s.au);
				} else {
					s.au.play();
				}
			}
		}
	},
	stop: function (name) {
		var s = this.sounds[name];
		if (s) {
			s.au.stop();
			s.isPlaying = false;
		}
	},
	stopAllSounds: function() {
		for (var s in this.sounds)
		{
			console.log(this.sounds[s])
			this.sounds[s].au.stop();
			this.sounds[s].isPlaying = false;
		}
	},
	isPlaying: function(name) {
		var s = this.sounds[name];
		return s.isPlaying;
	},
	get: function(name) {
		return this.sounds[name].au;	
	},
	setMute: function(mute){
		this.mute = mute;
		if(mute) {
			Howler.mute(true);
		} else {
			Howler.mute(false);
		}	
	},
	setVolume: function(volume) {
		this.volume = volume;
		//update all group volumes
		for( var key in this.groups ) {
			this.setSoundGroupVolume(key, this.groups[key].volume);
		}
	},
	// used by tile engine
	setSoundVolume: function(soundName, volume, variations = 1) {
		for (let i = 0; i < variations; i++) {
			let sN = soundName;
			if (variations > 1) {
				sN += (i + 1);
			}
			let s = this.sounds[sN];
			if (s) {
				volume *= s.volume * this.groups[s.group].volume * this.volume;
				s.au.volume(volume);
			}
		}
	},
	toggleMute: function() {
		this.mute = !this.mute;
		this.setMute(this.mute);
		return this.mute;
	},
	toggleSfxMute: function() {
		this.sfxMuted = !this.sfxMuted;
		let sfxVolume = this.sfxMuted ? 0 : 1;
		this.setSoundGroupVolume('sfx', sfxVolume);
		return this.sfxMuted;
	},
	toggleMusicMute: function() {
		this.musicMuted = !this.musicMuted;
		let musicVolume = this.musicMuted ? 0 : 1;
		this.setSoundGroupVolume('music', musicVolume);
		return this.musicMuted;
	},
	addSoundGroup: function(name, volume) {
		this.groups[name] = {
			enabled: true,
			volume: volume == null ? 1 : volume,
			instances: []
		};
	},
	disableSoundGroup: function(name) {
		if(!this.groups.hasOwnProperty(name)) return;
		this.groups[name].enabled = false;
	},
	setSoundGroupVolume: function(name, volume) {
		if(!this.groups.hasOwnProperty(name)) return;
		this.groups[name].volume = volume;
		
		//loop over sounds and update them
		for( var key in this.sounds ) {
			var sound = this.sounds[key];
			if(sound.group === name) {
				sound.au.volume(sound.volume * volume * this.volume);
			}
		}
	},
	fadeSoundTo: function(name, fadeTo, timeMillis) {
		var s = this.sounds[name];
		// console.log('fade: ', s);
		let vol = s.au._volume;
		fadeTo *= s.volume * this.groups[s.group].volume * this.volume;
		s.au.fade(vol, fadeTo, timeMillis);
	},
	load: function(){
		if(this.blocked) {
			this.soundLength = 1;
			this.loaded = 1;
			this.progressHandler();
			if (this.loadedHandler) this.loadedHandler();
			return;
		}
		for( var key in this.sounds ){
			this.sounds[key].au.load();	
		}
	},
	// getAudioContext - handy if you want to add effects or use analyzer to do visualisations
	// see https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext
	getAudioContext: function() {
		return Howler.ctx;
	},
};

export { audio as default };
