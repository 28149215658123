import BaseReactView from './BaseReactView';

/**
 * @author Carl Trelfa
 * Another version of a react view. This will be the size you make it and can be positioned with setPosition.
 * It's position will be scaled along with the canvas as will it's size.
 * Think of your view and positioning as the same as you would for a pixi container, so use the same coordinate space.
 * One caveat - you will need to call showView to mount the view before you can position it
 * (you can set a mountCallback in ReactViewController to help with this).
 * Very useful for floating panels, think speech panels or info panels.
 * Also very handy for huds, as it doesn't have to fill the whole screen and therefore won't completely block interaction.
 */
export default class PositionableReactView extends BaseReactView {
    // use anchorCenter = true if you want to treat the center of your panel as the anchor point or false for top-left anchoring
    setPosition = (x, y, anchorCenter = true) => {       
        this.setState({
            left: x,
            top: y,
            anchorCenter: anchorCenter,
        });
    }

    render() {
        let fadeInClass = this.state.visible ? ' fade-in' : '';
        let transform = 'scale(' + this.state.scale + ')';
        if (this.state.anchorCenter) {
            transform += ' translate(-50%, -50%)'
        }
        let leftScaled = typeof this.state.left === 'number' ? (this.state.left * this.state.scale) + this.state.zeroLeft : this.state.left;
        let topScaled = typeof this.state.top === 'number' ? (this.state.top * this.state.scale) + this.state.zeroTop : this.state.top;
        return (
            <div className={'view-holder over-canvas' + fadeInClass} style={{transformOrigin: '0 0', transform:transform, left: leftScaled, top: topScaled}}>
                {this.renderContent()}
            </div>
        );
    }

    // you'll need to over-ride this for your layout
    renderContent() {
        return (
            <div>Your view layout goes here</div>
        );
    }
}