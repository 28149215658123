import PropTypes from 'prop-types';
import React from 'react'
import ReactFullScreenViewUnderCanvas from '../koko-framework/ui/react-views/ReactFullScreenViewUnderCanvas';

import "./VideoFeature.scss";

import { TwitchContext, TwitchProvider} from '../contexts/TwitchContext';
import { GAME_CONTROLLER } from '../randomised-runner/controller/randomised-runner-game-controller';
import {isMobile} from 'react-device-detect';
import { config } from '../randomised-runner/model/randomised-runner-config';

const youTube = {
  player: null,
}

export default class YTBackgroundView extends ReactFullScreenViewUnderCanvas {
    static propTypes = {
        id: PropTypes.string.isRequired,
      };
      constructor(props) {
        super(props);
      }
    tag = document.createElement('script');
    firstScriptTag = document.getElementsByTagName('script')[0];
    // make sure you call the superclass in didMount and willUnmount
    componentDidMount() {
        // I would recommend doing your stuff before calling super.componentDidMount,
        // as this may cause a callback...

        let firstScriptTag = document.getElementsByTagName('script')[0];
       


        if (!config.USE_LOCAL_VIDEO) {
          if (!window.YT) { // If not, load the script asynchronously
              const tag = document.createElement('script');
              tag.src = 'https://www.youtube.com/iframe_api';
        
              // onYouTubeIframeAPIReady will load the video after the script is loaded
              window.onYouTubeIframeAPIReady = this.loadVideo;
        
              firstScriptTag = document.getElementsByTagName('script')[0];
              firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        
            } else { // If script is already there, load the video directly
              this.loadVideo();
            }
        }
      //  super.componentDidMount();
    }

    componentWillUnmount() {
        // the super version of this removes a resize listener so, make sure you call this!
        super.componentWillUnmount();
    }
    componentDidUpdate(prevProps, prevState) {
      // do something

    }

    getVidDuration() {
        const vid = this.player || youTube.player;
        if (vid && vid.getDuration) {
          // console.log(vid.getDuration())
          return vid.getDuration();
        }
        return 0;
    }

    getVidSeekTime() {
        const vid = this.player || youTube.player; // document.getElementById("featureVideo");
        if (vid && vid.getCurrentTime) {
          return vid.getCurrentTime(); // vid.currentTime;
        }
        return 0;
    }

    stopTwitchClip = () => {
  
    }



    loadVideo = () => {
        const { id, loop, mute } = this.props;
    
        // the Player object is created uniquely based on the id in props
        this.player = new window.YT.Player(`youtube-player-${id}`, {
          videoId: id,
          events: {
            onReady: this.onPlayerReady,
            onStateChange: this.onPlayerStateChange
          },
          playerVars: {
            playsinline:0,
            mute:true
          }
        });

        if (this.player) {
          youTube.player = this.player;
        }

        console.log('player', this.player, youTube);
      };
    
      onPlayerStateChange = event => {
        if(event.data == 1) {
          console.log('VIDEO PLAYING')
        } 
        if (event.data == 0) {
          console.log('VIDEO ENDED, RESTART', event)
          youTube.player.playVideo();
      }
      }

      onPlayerReady = event => {
        //this.loadTwitchClip();
        console.log('PLAYER RERADY',  youTube.player)
        youTube.player.playVideo();
      };





//<iframe frameborder="0" src="https://www.youtube.com/embed/pwuCabmvYEY?si=iqoxuyW0cOs112Xj?&controls=0&&showinfo=0&autoplay=1&playsinline=1" ></iframe>
    
/*
<iframe
    src="https://clips.twitch.tv/embed?clip=CooperativeThirstyLocustSoonerLater-9a7p62zwqqAPmE3f&parent=localhost&autoplay=true&mute=0"
    height="1920px"
    width="1080px"
    autoplay='true'
    allowfullscreen>
</iframe>
*/

    /* There a alot of spacer empty growing row divs here! */
    renderContent() {
        const { id } = this.props;
        const isMuted = isMobile ? '{ "muted": true }' : '';
        return (
  
        
            <div className={"video-container"}>
             
                  <div id={`youtube-player-${id}`} className={'video'} />
                
           </div>
        );
    }
}