// User
export const loginRoute = "/user/login";
export const registerRoute = "/user/signup";
export const changePasswordRoute = "/user/update-password";
export const profileRoute = "/user/profile";

export const submitScoreRoute = "/leaderboard/submit-score";
export const getLeaderboardRoute = "/leaderboard/get-leaderboard";
export const getScorePosition = "/leaderboard/calculate-position";
export const getTokenRoute = "/leaderboard/generate-token";

export const getPlayCountRoute = "/settings/get-playcount";
