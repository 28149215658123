import * as PIXI from 'pixi.js'
import _ from 'underscore'
import koko from './koko'
import gsap from 'gsap'

/**
 * Now supports react views, make sure you register your ReactViewController(s) and you can use
 * the same code to hide / show / get instances of your views!
 * 
 * You will currently still need to add react views using the ReactViewController, but once set up
 * views can be shown / hidden and retrieved by using the functions:
 * 
 * showView(id)
 * hideView(id)
 * get(id)
 * 
 * Regardless of whether they are react based or pixi based.
 */
export const views = {
	pool: {},
	reactViewControllers: [],
	registerReactViewController: function(aReactControllerInstance) {
		this.reactViewControllers.push(aReactControllerInstance);
	},
	add: function(name, view, parent) {
		view.name = name;
        koko.views.pool[name] = view;
		//add to parent container
		if(parent != null) {
			if (typeof parent == 'string' || parent instanceof String) {
				koko.views.pool[parent].container.addChild( view.container );
			} else {
				parent.addChild(view.container);
			}
		}
	},
	get: function(name) {
		if (koko.views.pool[name]) {
			return koko.views.pool[name];
		}
		for (let i = 0; i < this.reactViewControllers.length; i++) {
			if (this.reactViewControllers[i].views && this.reactViewControllers[i].views[name] 
				&& this.reactViewControllers[i].views[name].mounted && this.reactViewControllers[i].views[name].viewInstance) {
				return this.reactViewControllers[i].views[name].viewInstance;
			}
		}
		return undefined;
	},
	getMany: function(names) {
		if(names == null) {
			return false;
		}
		if(names.length == null) {
			names = [names];
		}

		var views = [];
		for(var key in names) {
			var name = names[key];
			var view = this.get(name);
			if(view) {
				views.push(view);
			}
		}

		return views;
	},
	getAll: function() {
		return koko.views.pool;
	},
	getAllBut: function(names) {
		if(names == null) {
			return false;
		}
		if(names.length == null) {
			names = [names];
		}

		var allViews = this.getAll();
		var views = [];
		for(var viewKey in allViews) {
			var view = allViews[viewKey];
			var ignore = false;
			for(var nameKey in names) {
				var name = names[nameKey];
				if(viewKey === name) {
					ignore = true;
				}
			}
			if(!ignore) {
				views.push(view);
			}
		}
		return views;
	},
	destroy: function(name) {
		var view = koko.views.pool[name];
		if(view.container.parent != null) view.container.parent.removeChild(view.container);
		delete koko.views.pool[name];
	},
	each: function(views, method) {
	   for( var i = 0; i < views.length; i++ ) {
			var view = koko.views.get(views[i]);
      if(!view) {
          console.log(views[i],'is not a view');
          continue;
      }
      if (typeof method == 'string' || method instanceof String) {
				if(method in view) {
					view[method].call(view);
				}
			} else {
				method(view);
			}
		}
	},
	all: function(method) {
		var names = [];
		for( var key in koko.views.pool ) names.push(key);
		koko.views.each(names, method);
	},
	allBut: function(ignoreArray, method) {
		var names = [], ignore = false;
		for( var key in koko.views.pool ) {
			ignore = false;
			for( var i = 0; i < ignoreArray.length; i++ ) {
				if(ignoreArray[i] === key) ignore = true;
			}
			if(!ignore) names.push(key);
		}
		koko.views.each(names, method);
	},
	filter: function(_func, method){
		var names = [];
		for( var key in koko.views.pool ) {
			if(_func(koko.views.pool[key], key)) names.push(key);
		}
		koko.views.each(names, method);
	},
	allVisible: function(method) {
		koko.views.filter(function(v, k){
			return v.container.visible && _.size(v.children) > 0;
		}, method);
	},

	// shorthand functions for transitioning views in / out, supports both pixi based views and react views
	showView: function(viewId) {
		if (this.pool[viewId]) {
			if (this.get(viewId).container.visible === false) {
				this.get(viewId).transitionIn();
			}
		}
		for (let i = 0; i < this.reactViewControllers.length; i++) {
			if (this.reactViewControllers[i].isViewActive(viewId) === false) {
				this.reactViewControllers[i].showView(viewId);
			}
		}
	},

	showViews: function(viewIdArray) {
		for (let i = 0; i < viewIdArray.length; i++) {
			this.showView(viewIdArray[i]);
		}
	},

	showAllViews: function() {
		this.all('transitionIn');
		for (let i = 0; i < this.reactViewControllers.length; i++) {
			this.reactViewControllers[i].showAllViews();
		}
	},

	showAllViewsBut: function(exceptionArray) {
		this.allBut(exceptionArray, 'transitionIn');
		for (let i = 0; i < this.reactViewControllers.length; i++) {
			this.reactViewControllers[i].showAllBut(exceptionArray);
		}
	},

	hideView: function(viewId) {
		if (this.pool[viewId]) {
			this.get(viewId).transitionOut();
		}
		for (let i = 0; i < this.reactViewControllers.length; i++) {
			this.reactViewControllers[i].hideView(viewId);
		}
	},

	hideViews: function(viewIdArray) {
		for (let i = 0; i < viewIdArray.length; i++) {
			this.hideView(viewIdArray[i]);
		}
	},

	hideAllViews: function() {
		this.all('transitionOut');
		for (let i = 0; i < this.reactViewControllers.length; i++) {
			this.reactViewControllers[i].hideAllViews();
		}
	},

	hideAllViewsBut: function(exceptionArray) {
		this.allBut(exceptionArray, 'transitionOut');
		for (let i = 0; i < this.reactViewControllers.length; i++) {
			this.reactViewControllers[i].hideAllBut(exceptionArray);
		}
	},
}

export class view {
    container = null;
    assets = null;
    children = null;
    elements = null;

    constructor(){
		this.container = new PIXI.Container();
		this.assets = [];
		this.children = [];
		this.elements = [];
		this.hide();
    }

    queueAssets(){
        koko.assets.addToManifest(this.assets);
    }

    buildView(){}
    
	// standardised transitions - although can be over-ridden, but a quick fade is usually fine
	transitionIn(){
		this.buildView();
        this.show();
        this.enable();
        this.container.alpha = 0;

        gsap.to(this.container, {alpha:1, duration: 0.35});
	}

    transitionOut(){
		gsap.to(this.container, {alpha:0, onComplete:this.transitionOutDone, duration: 0.35});
	}
	transitionOutDone = () => {
        this.destroyView();
        this.hide();
    }

    resize(){}

    enable() {
        for(var i = 0; i < this.elements.length; i++) {
            this.elements[i].enable();
        }
    }

    disable(){
        for(var i = 0; i < this.elements.length; i++) {
            this.elements[i].disable();
        }
    }

    show() {
        this.container.visible = true;
    }

    hide(){
        this.container.visible = false;
    }

    destroyView(){ this.destroy(); }
    
    destroy(){
       koko.display.destroyContainerChildren(this.container);
       this.children = null;
    }
}
