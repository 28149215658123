
import { useContext } from 'react';
import { useOAuth2 } from "@tasoskakour/react-use-oauth2";
import { TwitchContext } from '../../contexts/TwitchContext';
import axios from "../../api/axios";
import './TwitchLoginButton.scss';
import koko from '../../koko-framework/koko';
import textLocaliser from '../../koko-framework/ui/TextLocaliser';

const TwitchLoginButton = (props) => {

    const twitchContext = useContext(TwitchContext);
    if (twitchContext.state.isLoggedIn) {
      /*  if(!twitchContext.twitchData.access_token)
        {
            //There is persistent data present but the Twitch Context is old, update it with the saved data
            setTwitchContext((oldValues) => {
                return {...oldValues, bootstrapped:false, isLoggedIn:true, twitchData:data}
              })
        }*/
      return (
        <div>
          <button onClick={() => twitchContext.logout()}>{textLocaliser.getText('logout_button')}</button>
        </div>
      )
    }

    return (
      <button className='tw-login-button' type="button" onClick={() => twitchContext.login(props.isStreamer)}>
        {props.buttonText}
      </button>
    );
  };

  export default TwitchLoginButton;