import React from 'react'
import ReactFullScreenView from '../koko-framework/ui/react-views/ReactFullScreenView';

import * as oauth2 from 'oauth4webapi'
import { OAuthPopup } from "@tasoskakour/react-use-oauth2";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./SelectGameMode.scss";
import TwitchLoginButton from './components/TwitchLoginButton';
import textLocaliser from '../koko-framework/ui/TextLocaliser';

import $ from 'jquery'
import { firstLoadAssets } from '../assets/first-load-assets';
import { assets } from '../assets/assets';
import YTBackgroundView from './YTBackgroundView';
import {isMobile} from 'react-device-detect';
import koko from '../koko-framework/koko';
export default class SelectGameModeView extends React.Component {
  _mounted = false;

  constructor(props) {
    super(props);

    const scale = this.calculateOpenAreaScale();
    this.state = {
      scale: scale,
      authorised:true,
      pwd:'',
      portraitMode:false
    }
  }

  // make sure you call the superclass in didMount and willUnmount
  componentDidMount(props) {
    // I would recommend doing your stuff before calling super.componentDidMount,
    // as this may cause a callback...
    // super.componentDidMount(props);
    this.calculateOpenAreaScale();
    if (!this._mounted) {
      this._mounted = true;
      window.addEventListener('resize', this.resize);
      this.resize();
    }
  }

  checkAuth = () =>{
    if(this.state.pwd == process.env.REACT_APP_AUTH) 
    {
      this.setState({ 
        authorised:true
      })
    }
  }

  calculateOpenAreaScale() {
    // Borrowed from app.js
    var height = window.innerHeight ? window.innerHeight + 1 : $(window).height() + 1;
    //variables
    var min = { width: 1920, height: 1080 }, width = window.innerWidth;
   
    if(isMobile && window.innerWidth < window.innerHeight)
    {
      if(this._mounted) this.setState({portraitMode:true})
    }
    else
    {
      if(this._mounted) this.setState({portraitMode:false})
    }

    height = height - 1;

    //scale to fit height
    let scale = height / min.height;
    //if the new scaled width is wider than the width of screen
    if ((min.width * scale) > width) {
      scale = width / min.width;
    }
    return scale;
  }

  resize = () => {
    const scale = this.calculateOpenAreaScale();
    this.setState({ 
      scale: scale,
      zeroLeft: (window.innerWidth - (1920 * scale)) / 2,
      zeroTop: (window.innerHeight - (1080 * scale)) / 2,
      width: 1920 * scale,
      height: 1080 * scale,
    });
  }

  /* There a alot of spacer empty growing row divs here! */
  render() {
    if(this.state.portraitMode)
    {
      return (<>
        

        <div className='mob-login-container'>
          {/* <div className='mob-speed-lines'></div> */}

          <div className='grow'></div>

          <div className='tw-logo'>
            <img src={assets.TwitchLogo} />
            <div className='playthroughTitle'><p>{textLocaliser.getText('logo_tag_line')}</p></div>
          </div>

          <p>{textLocaliser.getText('play_as_individual_title')}</p>

          <TwitchLoginButton isStreamer={false} buttonText={textLocaliser.getText('play_as_individual_button')} />

          <div className='grow'></div>

        </div>  
      
      {/* <div className='mobile-login-content'>
        <div className='twitchLogo'>
          <img src={assets.TwitchLogo} />
          <div className='playthroughTitle'><p>{textLocaliser.getText('logo_tag_line')}</p></div>
        </div>

        <img className='speedLines' src={firstLoadAssets.SpeedLines} />

        <div className='grow'></div>
        <div className='grow'></div>

        <div className={'flex-columns'}>
          <div className='side-margin'></div>
          <div className={(isMobile ? 'mobile-login' : '')}>
            <p>{textLocaliser.getText('play_as_individual_title')}</p>
            <TwitchLoginButton isStreamer={false} buttonText={textLocaliser.getText('play_as_individual_button')} />
          </div>
          <div className='side-margin'></div>
        </div>


        <div className='grow'></div>

      </div> */}
      
      </>)

    }
    else{
    return (
      <div className='scaled-gm-holder' style={{transformOrigin: '0 0', transform:'scale(' + this.state.scale + ')', left: this.state.zeroLeft, top: this.state.zeroTop, width: this.state.width, height: this.state.height}}>
        

        {/* <div className="grow centered">
          <div className='grow'></div>
          <div className="game-mode-container centered">
            <div className=" row boxlayout">
              <div className=" col-md-6">
                <p>{textLocaliser.getText('play_as_individual_title')}</p>
                <TwitchLoginButton isStreamer={false} buttonText={textLocaliser.getText('play_as_individual_button')} />
              </div>
              <div className=" col-md-6">
                <p>{textLocaliser.getText('play_as_streamer_title')}</p>
                <TwitchLoginButton isStreamer={true} buttonText={textLocaliser.getText('play_as_streamer_button')} />
              </div>
            </div>
          </div>
          <div className='grow'></div>
        </div> */}
        {(this.state.authorised || process.env.REACT_APP_NODE_ENV == 'local') &&
        <div className='select-mode-holder'>
     
          <div className={"loginBackground"} style={this.state.portraitMode ? {width:window.innerWidth+'px', height:window.innerHeight+'px'} : {}}>
         
            {/* <img className='speedLines' src={firstLoadAssets.SpeedLines} /> */}
            {!isMobile && <img className='dino' src={firstLoadAssets.Dino} />}
            <img className='login-stars' src={firstLoadAssets.LoginStars} />
          </div>

          <div className='login-content'>
          <div className='twitchLogo'>
                        <img src={assets.TwitchLogo} />
                        <div className='playthroughTitle'><p>{textLocaliser.getText('logo_tag_line')}</p></div>
                    </div>
            <div className='top-margin'></div>
              <div className={'flex-columns'}>
                <div className='side-margin'></div>
                  <div className={(isMobile ? 'mobile-login' : '')}>
                    <p>{textLocaliser.getText('play_as_individual_title')}</p>
                    <TwitchLoginButton isStreamer={false} buttonText={textLocaliser.getText('play_as_individual_button')} />
                  </div>
               
               {!isMobile && 
               <>
               <div className='grow'></div>
                  <div>
                    <p>{textLocaliser.getText('play_as_streamer_title')}</p>
                    <TwitchLoginButton isStreamer={true} buttonText={textLocaliser.getText('play_as_streamer_button')} />
                  </div>
                  </>
                }
                <div className='side-margin'></div>
              </div>
            <div className='grow'></div>
          </div>
        </div>
      }
    
      {!this.state.authorised && process.env.REACT_APP_NODE_ENV != 'local' && 
      <div className='password'>
          <input type="password" value={this.state.pwd} onChange={((val) => { this.setState({pwd:val.target.value})})}/><br />
          <button onClick={() => {this.checkAuth()}}>
            Submit
          </button>
      
          </div>
      }
      </div>

    );}
  }
}